import React ,{useState,useEffect}from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend,ResponsiveContainer } from 'recharts';
import { getAllBarChart } from '../../services/barchart/barChartService';
import { Spinner } from 'react-bootstrap';

const BarChartComponent :React.FC= () => {
  const [barGraphData, setBarGraphData] =useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
 
  useEffect(() => {
    getGraphData()
  }, [])
  const getGraphData = async () => {
    try {
        let graphData = await getAllBarChart();
        const dataObj:any[]=[]
        graphData.map((item:any)=>{
        dataObj.push({month:item.sMonth, value: item.aCount})
        })
        setBarGraphData(dataObj);
      
    } catch (err) {
        console.error(err);
    } 
    finally {
        setIsLoading(false);
    }
};
  
  return (
    <>
     
    <div style={{height:'396px',width:'100%'}}>
    {isLoading ? (
      <div
          className='d-flex justify-content-center align-items-center'
          style={{ height: '20vh' }}
      >
          <Spinner animation='border' variant='primary' />
      </div>
  ) : null}
     <ResponsiveContainer width="100%" height="80%">
      
      <BarChart  data={barGraphData}>
     
        <CartesianGrid strokeDasharray="1 1"/>
        <XAxis dataKey="month" />
        <YAxis />
        <Tooltip />
        <Legend />
      
        <Bar barSize={15} dataKey="value" fill="#0e76bc" />
      </BarChart>
      </ResponsiveContainer>
    </div>
    </>
  );
};

export default BarChartComponent;
