import {
  customerApproveStatusApi,
  customerStatusApi,
  deleteCustomerApi,
  getAllCustomersApi,
  getAllSyncCustomersApi,
} from "../../api/customer/customerApi";

export const getAllCustomers = () => {
  return new Promise((resolve, reject) => {
    getAllCustomersApi()
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const deleteACustomer = (id: string) => {
  return new Promise((resolve, reject) => {
    deleteCustomerApi(id)
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const customerStatus = (id: string, isActive: boolean) => {
  let data = { bIsActive: !isActive };
  return customerStatusApi(id, data);
};
export const customerApproveStatus = (id: string, isApproved: boolean) => {
  return new Promise((resolve, reject) => {
    customerApproveStatusApi(id, isApproved)
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getAllSyncCustomers = () => {
  return new Promise((resolve, reject) => {
    getAllSyncCustomersApi()
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
