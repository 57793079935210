import { api, baseUrl } from '../../api';

export const getAllConsignmentsApi = async () => {
    const result = await api.get(`${baseUrl}/admin/warehouse/group-consignment`);
    return result.data;
};
export const getAllConsignmentsApiByParams = async () => {
    const result = await api.get(`${baseUrl}/admin/warehouse/consignment?limit=100&page=0`);
    return result.data;
};
export const deleteConsignmentApi = async (id: string) => {
    const result = await api.delete(`${baseUrl}/admin/warehouse/consignment/${id}`);
    return result.data;
};
export const deleteGroupConsignmentApi = async (grpConsignmentID: string) => {
    const result = await api.delete(
        `${baseUrl}/admin/warehouse/group-consignment?grpConsignmentID=${grpConsignmentID}`
    );
    return result.data;
};
export const addConsignmentApi = async (reqbody: any) => {
    const result = await api.post(`${baseUrl}/admin/warehouse/consignment`, reqbody);
    return result.data;
};
export const uploadConsignmentPODApi = async (formData: any) => {
    const result = await api.post(`${baseUrl}/shared/upload?path=consignment`, formData);
    return result.data;
};
export const uploadConsignmentWithPODDataApi = async (consignmentID: string, reqBody: any) => {
    const result = await api.post(`${baseUrl}/admin/warehouse/consignment/${consignmentID}`, { data: reqBody });

    return result;
};
export const mergeConsignmentApi = async (reqBody: any) => {
    const result = await api.post(`${baseUrl}/admin/warehouse/group-consignment/mergeConsignment`, { data: reqBody });
    return result;
};
export const getTripHistoryDataApi = async (id: string) => {
    const result = await api.get(`${baseUrl}/admin/warehouse/group-consignment/location-history/${id}`);
    return result.data;
};
