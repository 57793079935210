import React, { useEffect, useState } from "react";
import "./Warehouse.css";
import { DeleteModal } from "../../components/modal/deletemodal/DeleteModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-regular-svg-icons";
import { Button, Col, Container, Row, Spinner, Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { DetailsModal } from "../../components/modal/showDetails/DetailsModal";
import "react-toastify/dist/ReactToastify.css";
// import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { getAllWarehouses } from "../../services/warehouse/warehouseService";
import { IWareHouse } from "logistic-packages";
import { DataTable } from "../../components/table/Table";

const columnDefinitions = {
  Warehouse: [
    { label: "ID", key: "_id" },
    { label: "Warehouse Name", key: "sName" },
    { label: " Location Code", key: "sLocationCode" },
    { label: "Capacity", key: "sCapacity" },
    { label: "Warehouse Manager", key: "sWareHouseManager" },
    { label: "Pin Code", key: "aPinCode" },
    { label: "Description", key: "sDescription" }
  ]
};

export const Bill: React.FC = () => {
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [warehouseData, setWarehouseData] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [recordSize, setRecordSize] = useState(10);
  const navigate = useNavigate();
  // const apiUrl = process.env.REACT_APP_LOCAL_API_URL!;
  const startIndex = currentPage * recordSize;
  const endIndex = startIndex + recordSize;

  const [showModal, setShowModal] = useState<string | false>(false);
  const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false);
  const [billDetails, setBillDetails] = useState<{ [keys: string]: any }>({});

  async function loadData() {
    try {
      let warehouse = await getAllWarehouses();

      if (warehouse) {
        warehouse = (warehouse as IWareHouse[]).map((item: any) =>
          Object({
            ...item,
            sWareHouseManager:
              item.tManager && item.tManager.sName
                ? item.tManager.sName
                : "----"
          })
        );
        setWarehouseData(warehouse as IWareHouse[]);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);

  const editWarehouse = async (id: string) => {
    navigate(`/warehouse/edit/${id}`);
  };

  const handleShowDetails = (orderInfo: any) => {
    if (orderInfo && orderInfo.sName) {
      setBillDetails({
        "Warehouse Name": orderInfo.sName,
        Address: orderInfo.sAddress,
        Capacity: orderInfo.sCapacity,
        "Warehouse Manager": orderInfo.tManager?.sName || "N/A", // Use optional chaining to handle potential null
        PinCode: orderInfo.aPinCode,
        Description: orderInfo.sDescription,
        "Location Code": orderInfo.sLocationCode
      });
      setShowDetailsModal(true);
    }
  };

  const paginateArray = (pageNumber: number) => {
    if (pageNumber >= 0 && pageNumber < totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    setCurrentPage(0); // Reset to the first page when searching
  };

  const handleRecordSizeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setRecordSize(Number(e.target.value));
    setCurrentPage(0); // Reset to the first page when changing record size
  };

  const filteredItems = warehouseData.filter((item) => {
    const searchTerm = search.trim().toLowerCase(); // Convert search term to lowercase

    // Convert item name to lowercase (or uppercase) before comparison
    const itemName = item.sName ? item.sName.toLowerCase() : "";

    return searchTerm === "" ? item : itemName.includes(searchTerm);
  });

  const totalFilteredItems = filteredItems.length;
  const totalPages = Math.ceil(filteredItems.length / recordSize);

  const paginatedItems = filteredItems.slice(
    currentPage * recordSize,
    (currentPage + 1) * recordSize
  );

  useEffect(() => {
    if (currentPage > 0 && paginatedItems.length === 0) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  }, [paginatedItems, currentPage]);

  return (
    <>
      {showModal && (
        <DeleteModal
          id={showModal}
          setShowModal={setShowModal}
          action={function (id: string): void {
            throw new Error("Function not implemented.");
          }} // action={deleteStatus}
        />
      )}
      {showDetailsModal && (
        <DetailsModal
          title={"Warehouse Details"}
          orderInfo={billDetails}
          setShowDetailsModal={setShowDetailsModal}
          isConsignment={false}
        />
      )}
      <Container fluid style={{ opacity: showModal ? "0.4" : "1" }}>
        <div className="user-content-header py-4">
          <ToastContainer />
          <Row className="mb-2">
            <Col sm="6">
              <h1 className="m-0 text-dark">Warehouse</h1>
            </Col>
            <Col sm="6">
              <ol className="breadcrumb float-sm-right justify-content-end">
                <li className="breadcrumb-item">
                  <a href="/dashboard">Home</a>
                </li>
                <li className="breadcrumb-item active">Warehouse</li>
              </ol>
            </Col>
          </Row>
        </div>
        <div className="content">
          <Row>
            <Col lg="12">
              <div className="card" bg-color="white">
                <div className="card-header bg-white">
                <h3 className="card-title">&nbsp;</h3>
                  <Link to="/warehouse/create">
                    <Button
                      variant="info"
                      className="user float-right add_role text-white"
                    >
                      Add Warehouse
                    </Button>
                  </Link>
                </div>
                <div className="card-body">
                  <div
                    id="DataTables_Table_0_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <div className="card-head">
                      <div
                        className="dataTables_length"
                        id="DataTables_Table_0_length"
                      >
                        <label>
                          Show{" "}
                          <select
                            name="DataTables_Table_0_length"
                            aria-controls="DataTables_Table_0"
                            className="table-input"
                            value={recordSize}
                            onChange={handleRecordSizeChange}
                          >
                            <option value="10">10</option>
                            <option value="15">15</option>
                            <option value="20">20</option>
                            <option value="100">100</option>
                          </select>{" "}
                          entries
                        </label>
                      </div>
                      <div
                        id="DataTables_Table_0_filter"
                        className="dataTables_filter"
                      >
                        <label
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                          }}
                        >
                          Search:
                          <input
                            type="search"
                            className="table-input"
                            placeholder="Search by Name..."
                            aria-controls="DataTables_Table_0"
                            value={search}
                            onChange={handleSearch}
                            style={{ height: "2rem" }}
                          />
                        </label>
                      </div>
                    </div>
                    <div style={{ overflow: "auto" }}>
                      <DataTable
                        columnDefinitions={columnDefinitions.Warehouse}
                        paginatedItems={paginatedItems}
                        handleShowDetails={handleShowDetails}
                        editFunction={editWarehouse}
                        // deleteFunction={deleteVehicle}
                        // setShowModal={setShowModal}
                        // setShowDetailsModal={setShowDetailsModal} // Pass the showDetailsModal state
                        isLoading={isLoading}
                        tableFor="Warehouse"
                      />
                    </div>
                    {/* <Table
                                                bordered
                                                id='DataTables_Table_0'
                                                className=' datatable table-striped-blue dataTable no-footer mt-3'
                                                role='grid'
                                                aria-describedby='DataTables_Table_0_info'
                                            >
                                                <thead className='bg-thead'>
                                                    <tr role='row'>
                                                        <th
                                                            className='not-exported sorting_asc'
                                                            tabIndex={0}
                                                            aria-controls='DataTables_Table_0'
                                                            rowSpan={1}
                                                            colSpan={1}
                                                            aria-sort='ascending'
                                                            aria-label=': activate to sort column descending'
                                                        >
                                                            Warehouse Name
                                                        </th>
                                                        <th
                                                            className='sorting'
                                                            tabIndex={0}
                                                            aria-controls='DataTables_Table_0'
                                                            rowSpan={1}
                                                            colSpan={1}
                                                            aria-label='Page Title: activate to sort column ascending'
                                                        >
                                                            Location Code
                                                        </th>
                                                        <th
                                                            className='sorting'
                                                            tabIndex={0}
                                                            aria-controls='DataTables_Table_0'
                                                            rowSpan={1}
                                                            colSpan={1}
                                                            aria-label='Position: activate to sort column ascending'
                                                        >
                                                            Capacity
                                                        </th>

                                                        <th
                                                            className='sorting'
                                                            tabIndex={0}
                                                            aria-controls='DataTables_Table_0'
                                                            rowSpan={1}
                                                            colSpan={1}
                                                            aria-label='Position: activate to sort column ascending'
                                                        >
                                                            Warehouse Manager
                                                        </th>

                                                        <th
                                                            className='sorting'
                                                            tabIndex={0}
                                                            aria-controls='DataTables_Table_0'
                                                            rowSpan={1}
                                                            colSpan={1}
                                                            aria-label='Position: activate to sort column ascending'
                                                        >
                                                            Pin Code
                                                        </th>
                                                        <th
                                                            className='sorting'
                                                            tabIndex={0}
                                                            aria-controls='DataTables_Table_0'
                                                            rowSpan={1}
                                                            colSpan={1}
                                                            aria-label='Position: activate to sort column ascending'
                                                        >
                                                            Description
                                                        </th>
                                                        <th
                                                            className='sorting'
                                                            tabIndex={0}
                                                            aria-controls='DataTables_Table_0'
                                                            rowSpan={1}
                                                            colSpan={1}
                                                            aria-label='Position: activate to sort column ascending'
                                                        >
                                                            Action
                                                        </th>
                                                    </tr>
                                                </thead>
                                                {warehouseData.length > 0 ? (
                                                    <tbody className='bg-tbody'>
                                                        {paginatedItems.length > 0 ? (
                                                            paginatedItems.map((item, index) => {
                                                                return (
                                                                    <tr role='row' className='odd' key={index}>
                                                                        {/* <td className="sorting_1">{index + 1}</td> */}
                    {/* <td
                                                                            key={index}
                                                                            onClick={() => {
                                                                                handleShowDetails(item);
                                                                            }}
                                                                        >
                                                                            {item.sName ? (
                                                                                <span
                                                                                    style={{
                                                                                        cursor: 'pointer',
                                                                                        color: 'blue',
                                                                                    }}
                                                                                >
                                                                                    {item.sName}
                                                                                </span>
                                                                            ) : (
                                                                                '----'
                                                                            )}
                                                                        </td>
                                                                        <td>
                                                                            {item.sLocationCode
                                                                                ? item.sLocationCode
                                                                                : '----'}
                                                                        </td>
                                                                        <td>
                                                                            {item.sCapacity ? item.sCapacity : '----'}
                                                                        </td>
                                                                        <td>
                                                                            {item.tManager && item.tManager.sName
                                                                                ? item.tManager.sName
                                                                                : '----'}
                                                                        </td>
                                                                        <td>{item.aPinCode ? item.aPinCode : '----'}</td>
                                                                        <td>
                                                                            {item.sDescription
                                                                                ? item.sDescription
                                                                                : '----'}
                                                                        </td>

                                                                        <td>
                                                                            <Button
                                                                                variant='success'
                                                                                size='sm'
                                                                                className='activeData'
                                                                                onClick={() => editWarehouse(item._id)}
                                                                            >
                                                                                <FontAwesomeIcon
                                                                                    icon={faEdit}
                                                                                    color='white'
                                                                                />{' '}
                                                                                <span>Edit</span>
                                                                            </Button> */}
                    {/* <Button
                                                                                variant='danger'
                                                                                size='sm'
                                                                                className=' activeData ms-1'
                                                                                onClick={() => setShowModal(item._id)}
                                                                                // onClick={() =>setShowModal(true)}
                                                                                // onClick={()=>deleteEmployee(item._id)}
                                                                            >
                                                                                <FontAwesomeIcon
                                                                                    icon={faTrash}
                                                                                    color='white'
                                                                                />{' '}
                                                                                <span>Delete</span>
                                                                            </Button> */}
                    {/* </td>
                                                                    </tr>
                                                                );
                                                            })
                                                        ) : (
                                                            <tr style={{ height: '10rem' }} className='notfound'>
                                                                <td colSpan={8}>No Records found</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                ) : isLoading ? null : (
                                                    <tbody className='bg-tbody'>
                                                        <tr style={{ height: '10rem' }} className='notfound'>
                                                            <th colSpan={7}>No Records found</th>
                                                        </tr>
                                                    </tbody>
                                                )}
                                            </Table>  */}
                    {isLoading ? (
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ height: "20vh" }}
                      >
                        <Spinner animation="border" variant="primary" />
                      </div>
                    ) : null}
                    <div className="d-flex justify-content-between align-items-center">
                      <div
                        className="dataTables_info"
                        id="DataTables_Table_0_info "
                        role="status"
                        aria-live="polite"
                      >
                        {totalFilteredItems > 0
                          ? `Showing ${
                              endIndex < totalFilteredItems
                                ? endIndex
                                : totalFilteredItems
                            } of ${totalFilteredItems} entries`
                          : ""}
                      </div>
                      <div
                        className="dataTables_paginate paging_simple_numbers"
                        id="DataTables_Table_0_paginate"
                      >
                        <Button
                          className="paginate_button previous me-2"
                          aria-controls="DataTables_Table_0"
                          data-dt-idx={0}
                          tabIndex={-1}
                          id="DataTables_Table_0_previous"
                          onClick={() => {
                            paginateArray(currentPage - 1);
                          }}
                          disabled={currentPage === 0}
                        >
                          Previous
                        </Button>
                        <span>
                          <Button
                            className="paginate_button-current"
                            aria-controls="DataTables_Table_0"
                            data-dt-idx={1}
                            tabIndex={0}
                          >
                            {currentPage + 1}
                          </Button>
                        </span>
                        <Button
                          className="paginate_button next ms-2"
                          aria-controls="DataTables_Table_0"
                          data-dt-idx="2"
                          tabIndex={-1}
                          id="DataTables_Table_0_next"
                          onClick={() => {
                            paginateArray(currentPage + 1);
                          }}
                          disabled={
                            currentPage === totalPages - 1 ||
                            filteredItems.length < 10
                          }
                        >
                          Next
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};
