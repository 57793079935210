import { addWarehouseApi, getAllWarehousesApi, getManagerApi, totalWarehouseItemsListApi, updateWarehouseApi } from '../../api/warehouse/warehouseApi';

export const totalWarehouseItemsList = () => {
    return new Promise((resolve, reject) => {
        totalWarehouseItemsListApi()
            .then((data) => {
                resolve(data.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
export const getAllWarehouses = () => {
    return new Promise((resolve, reject) => {
        getAllWarehousesApi()
            .then((data) => {
                resolve(data.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
export const addWarehouse = (reqbody: any) => {
    return new Promise((resolve, reject) => {
        addWarehouseApi(reqbody)
            .then((data) => {
                resolve(data.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
export const updateWarehouse = (id:string, warehouseData:any) =>{
    return new Promise((resolve, reject)=>{
        updateWarehouseApi(id,warehouseData)
        .then((data)=>{
            resolve(data);
        })
        .catch((error)=>{
            reject(error);
        })
    })
}

export const getAllManagers = () => {
    return new Promise((resolve, reject) => {
        getManagerApi()
            .then((data) => {
                resolve(data.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};