
import { addThresoldApi, deleteThresoldApi, getAllThresoldApi } from "../../api/thresold/thresoldApi"

export const getAllThresold = () =>{
    return new Promise((resolve, reject)=>{
        getAllThresoldApi()
        .then((data)=>{
            resolve(data.data);
        })
        .catch((error)=>{
            reject(error)
        })
    })
}

export const addThresold = (reqbody: any) => {
    return new Promise((resolve, reject) => {
        addThresoldApi(reqbody)
            .then((data) => {
                resolve(data.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const deleteThresold = (id: string) => {
    return new Promise((resolve, reject) => {
        deleteThresoldApi(id)
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};