import { getAllinventoryApi } from "../../api/inventory/inventoryApi";



export const getAllinventory = (locationCode: any) => {
    
    return new Promise((resolve, reject) => {
        getAllinventoryApi(locationCode)
            .then((res) => {
                resolve(res.data.data.value);
            })
            .catch((error) => {
                reject(error);
            });
    });
};