import React, { useState } from 'react';
import { Modal, Button, Form, Row, Col, Spinner } from 'react-bootstrap';
import { createUserBranch } from '../../../services/generic/organizationService';

interface AddBranchProps {
    show: boolean;
    onClose: () => void;
    width?: string
}

const AddBranchModal: React.FC<AddBranchProps> = ({ show, onClose, width }) => {
    const [formData, setFormData] = useState({
        sName: '',
        sTag: '',
    });
    const [isLoading, setIsLoading] = useState(true);
    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };
    async function createBranch() {
        try {
            await createUserBranch(formData)
        } catch (error) {
            console.error(error)
        }
        finally {
            setIsLoading(false);
        }
    }
    const handleSubmit = (e: any) => {
        createBranch()
        e.preventDefault();
        onClose();
    };

    return (
        <Modal show={show} onHide={onClose} contentClassName={width === "60%" ? 'modal-w-xl' : 'modal-w-sm'}>
            <Modal.Header>
                <Modal.Title>Add Branch</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {/* <Form onSubmit={handleSubmit}> */}
                <Row>
                    <Col md={12}>
                        <Form.Group className="mb-6">
                            {/* <Form.Label>Name</Form.Label> */}
                            <Form.Control type="text" name="sName" value={formData.sName} onChange={handleChange} required placeholder='Branch Name' />
                        </Form.Group>
                    </Col>
                </Row>

                <Col md={12}>
                    <Form.Group className="mb-6">
                        {/* <Form.Label>Email</Form.Label> */}
                        <Form.Control type="text" name="sTag" value={formData.sTag} onChange={handleChange} required placeholder='Branch Tag' className='mt-2' />
                    </Form.Group>
                </Col>


                <Row className='mt-4' style={{ justifyContent: 'center' }}>
                    <Button variant="btn btn-primary" style={{ width: 'auto' }} onClick={(e) => handleSubmit(e)}>
                        Add Branch
                    </Button>

                </Row>

                {/* </Form> */}
            </Modal.Body>
        </Modal>
    );
};

export default AddBranchModal;
export { };