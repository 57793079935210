import React from 'react';
import './CollapsableTable.css';
import { Button } from 'react-bootstrap';
const ChildTable = ({
    children = [],
    handleUploadPOD = (child: any, type: string) => {
        return child;
    },
    handleShowDetails = (child: any) => {
        return child;
    },
}) => {
    return (
        <table>
            <thead>
                <tr>
                    <th>Consignment No.</th>

                    <th>Warehouse</th>
                    <th>Document No.</th>
                    <th>Item Count</th>
                    <th>Address</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
                {children.map((child: any, index) => (
                    <>
                        <tr key={index}>
                            <td
                                onClick={() => {
                                    handleShowDetails && handleShowDetails(child);
                                }}
                                style={{ color: 'blue' }}
                            >
                                {child.sConsignmentNo}
                            </td>

                            <td>{child.tWarehouse.sName}</td>
                            <td>{child.tItems[0].sDocumentNo}</td>
                            <td>{child.tItems.length}</td>
                            <td>{child?.tItems[0]?.sBilltoAddress + ', ' + child?.tItems[0]?.sBilltocity}</td>
                            <td
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-around',
                                    alignItems: 'center',
                                }}
                            >
                                {Status[child?.eStatus].replace(/_/g, ' ')}
                                {child.eStatus == 2 || child.eStatus == 4 ? (
                                    <>
                                        {' '}
                                        <Button
                                            onClick={() => {
                                                handleUploadPOD && handleUploadPOD(child, 'upload');
                                            }}
                                            variant='success'
                                            size='sm'
                                            style={{ height: '30px' }}
                                        >
                                            Upload POD
                                        </Button>
                                        <Button
                                            onClick={() => {
                                                handleUploadPOD && handleUploadPOD(child, 'view');
                                            }}
                                            variant='danger'
                                            size='sm'
                                            style={{ height: '30px' }}
                                        >
                                            View POD
                                        </Button>
                                    </>
                                ) : (
                                    ''
                                )}
                            </td>
                        </tr>
                    </>
                ))}
            </tbody>
        </table>
    );
};

export default ChildTable;

enum Status {
    Ordered = 0,
    Inprogress = 1,
    Delivered = 2,
    Out_For_Delivery = 3,
    Forced_Delivery = 4,
}
