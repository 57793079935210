import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useMatch } from 'react-router-dom';
import './App.css';
import { Header } from './components/header/Header';
import { Main } from './pages/dashboard/Dashboard';
import { Sidebar } from './components/sidebar/Sidebar';
import './index.css';

import { Bill } from './pages/warehouse/Warehouse';
import { Consignment } from './pages/consignment/Consignment';
import { Login } from './pages/login/Login';
import Report from './pages/report/Report';
import { AddNewPage } from './pages/template/addpage/AddPage';
import { Trip } from './pages/order/Order';
import { Vehicle } from './pages/vehicle/Vehicle';
import { Inventory } from './pages/inventory/Inventory';
import { useDispatch, useSelector } from 'react-redux';
import { PageList } from './pages/template/pagelist/PageList';
import { AddEmployee } from './pages/user/employee/addemployee/AddEmployee';
import { Customer } from './pages/user/customer/Customer';
import { Driver } from './pages/user/driver/Driver';
import { Employee } from './pages/user/employee/Employee';
import { AddVehicle } from './pages/vehicle/addvehicle/AddVehicle';
import { signinToken, signinUser } from './redux/auth/authSlice';
import { AddConsignment } from './pages/consignment/addconsignment/AddConsignment';
import { ProtectedRoute } from './services/ProtectedRoute';
import './icon.library';
import { RootState } from './redux/store';
import { AddTrip } from './pages/order/addtrip/AddOrder';
import { AddWarehouse } from './pages/warehouse/addWarehouse/AddWarehouse';
import { EditDriver } from './pages/user/driver/editdriver/EditDriver';
import { EditCustomer } from './pages/user/customer/editcustomer/EditCustomer';

import { AddGroup } from './pages/settings/group/addgroup/AddGroup';
import { Group } from './pages/settings/group/Group';
import { Role } from './pages/settings/role/Role';
import { AddRole } from './pages/settings/role/addrole/AddRole';
import Settings from './pages/settings/Settings';

import Notifications from './pages/notifications/Notifications';
import Tracking from './pages/tracking/Tracking';
import { EditReport } from './pages/report/editreport/EditReport';
import { Thresold } from './pages/settings/thresold/Thresold';
import { AddThresold } from './pages/settings/thresold/addthresold/AddThresold';
import { AddDriver } from './pages/user/driver/addDriver/AddDriver';
import { ResetPassword } from './pages/reset-password/ResetPassword';
import { ForgotPassword } from './pages/forget-password/ForgetPassword';
import { WareHouseTransfer } from './pages/warehouse-transfer/WarehouseTransfer';

function App(): JSX.Element {
    const dispatch = useDispatch();
    const isUserRoute = useMatch('/login');
    const isForgetRoute = useMatch('/forgot-password');
    const isResetRoute = useMatch('reset-password/:token');
    const auth = useSelector((state: RootState) => state.auth);
    const [sidebarOpen, setSideBarOpen] = useState<{
        isBtnOpen: boolean;
        currentValue: boolean;
    }>({
        isBtnOpen: true,
        currentValue: true,
    });

    useEffect(() => {
        onInit();
        // requestPermission();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // const requestPermission=async(userId?:string)=>{
    //   const permission=await Notification.requestPermission();
    //   if(permission==="granted" && userId){
    //     const token=await getToken(messaging,{vapidKey:"BGUb-KgjfwI5r9Bo5GkKZNuI6XKK3Rts4lG25F6HnEeFQnNgSYPJzHE8uEEwQbu8F_2CKZ8zsHH_gHtPKcEEUwk"})
    //    await addDeviceToken({"data":{sToken:token, eGroupType:"Admin",tUserId:`${userId}`}})
    //   }
    // }
    const onInit = () => {
        if (!auth.user || !auth.token) {
            let localData = JSON.parse(localStorage.getItem('user') || 'null');
            let localDataToken = JSON.parse(localStorage.getItem('sToken') || 'null');
            if (localData && localDataToken) {
                dispatch(signinUser(localData));
                dispatch(signinToken(localDataToken));
            }
        }
    };

    const handleViewSidebar = () => {
        setSideBarOpen({
            isBtnOpen: !sidebarOpen.isBtnOpen,
            currentValue: !sidebarOpen.currentValue,
        });
    };

    return (
        <div className='app-wrap'>
            <div className='bg-global'></div>

            {!(isUserRoute || isForgetRoute || isResetRoute) && (
                <Header toggleSidebar={handleViewSidebar} isOpen={sidebarOpen} notificationCount={0} />
            )}
            <div
                className={
                    !(isUserRoute || isForgetRoute || isResetRoute)
                        ? sidebarOpen.currentValue && sidebarOpen.isBtnOpen
                            ? 'main-container-withsidebar'
                            : 'main-container-withoutsidebar'
                        : ''
                }
            >
                {!(isUserRoute || isForgetRoute || isResetRoute) && (
                    <Sidebar isOpen={sidebarOpen} setSideBarOpen={setSideBarOpen} toggleSidebar={handleViewSidebar} />
                )}

                <Routes>
                    <Route path='login' element={<Login />}></Route>
                    <Route path='reset-password/:token' element={<ResetPassword />}></Route>
                    <Route path='forgot-password' element={<ForgotPassword />}></Route>
                    <Route path='' element={<Navigate to='/dashboard' replace />}></Route>
                    <Route path='dashboard' element={<ProtectedRoute component={Main}></ProtectedRoute>}></Route>
                    <Route
                        path='/notifications'
                        element={<ProtectedRoute component={Notifications}></ProtectedRoute>}
                    />
                    <Route path='/tracking' element={<ProtectedRoute component={Tracking}></ProtectedRoute>} />
                    {/* <Route path='settings' element={<ProtectedRoute component={Account}></ProtectedRoute>}></Route> */}
                    <Route path='settings' element={<ProtectedRoute component={Settings}></ProtectedRoute>}></Route>

                    <Route
                        path='consignments'
                        element={<ProtectedRoute component={Consignment}></ProtectedRoute>}
                    ></Route>
                    <Route
                        path='/consignments/create'
                        element={<ProtectedRoute component={AddConsignment}></ProtectedRoute>}
                    ></Route>
                    <Route
                        path='/consignments/edit/:id'
                        element={<ProtectedRoute component={AddConsignment}></ProtectedRoute>}
                    ></Route>
                    <Route
                        path='/account-settings/pagelist'
                        element={<ProtectedRoute component={PageList}></ProtectedRoute>}
                    />

                    <Route path='users'>
                        <Route path='drivers' element={<ProtectedRoute component={Driver}></ProtectedRoute>} />

                        <Route path='customers' element={<ProtectedRoute component={Customer}></ProtectedRoute>} />

                        <Route path='employees' element={<ProtectedRoute component={Employee}></ProtectedRoute>} />
                    </Route>
                    <Route path='group' element={<ProtectedRoute component={Group}></ProtectedRoute>}></Route>
                    <Route
                        path='/group/create'
                        element={<ProtectedRoute component={AddGroup}></ProtectedRoute>}
                    ></Route>
                    <Route path='/group/edit/:id' element={<ProtectedRoute component={AddGroup} />} />
                    <Route path='vehicles' element={<ProtectedRoute component={Vehicle}></ProtectedRoute>}></Route>
                    <Route path='order' element={<ProtectedRoute component={Trip}></ProtectedRoute>}></Route>
                    <Route path='/order/create' element={<ProtectedRoute component={AddTrip}></ProtectedRoute>}></Route>
                    <Route
                        path='/order/edit/:id'
                        element={<ProtectedRoute component={AddTrip}></ProtectedRoute>}
                    ></Route>
                    <Route path='warehouse' element={<ProtectedRoute component={Bill}></ProtectedRoute>}></Route>
                    <Route
                        path='/warehouse/create'
                        element={<ProtectedRoute component={AddWarehouse}></ProtectedRoute>}
                    ></Route>
                    <Route path='/warehouse/edit/:id' element={<ProtectedRoute component={AddWarehouse} />} />

                    <Route path='reports' element={<ProtectedRoute component={Report}></ProtectedRoute>}></Route>
                    <Route path='inventory' element={<ProtectedRoute component={Inventory}></ProtectedRoute>}></Route>
                    <Route
                        path='/account-settings/add-page'
                        element={<ProtectedRoute component={AddNewPage}></ProtectedRoute>}
                    ></Route>
                    <Route
                        path='/users/employees/create'
                        element={<ProtectedRoute component={AddEmployee}></ProtectedRoute>}
                    ></Route>
                    <Route path='users/employees/edit/:id' element={<ProtectedRoute component={AddEmployee} />} />
                    <Route path='users/drivers/create' element={<ProtectedRoute component={AddDriver} />} />
                    <Route path='users/drivers/edit/:id' element={<ProtectedRoute component={EditDriver} />} />
                    <Route path='users/customers/edit/:id' element={<ProtectedRoute component={EditCustomer} />} />
                    <Route
                        path='/vehicles/create'
                        element={<ProtectedRoute component={AddVehicle}></ProtectedRoute>}
                    ></Route>
                    <Route path='/vehicles/edit/:id' element={<ProtectedRoute component={AddVehicle} />} />
                    <Route path='role' element={<ProtectedRoute component={Role}></ProtectedRoute>}></Route>
                    <Route path='/role/create' element={<ProtectedRoute component={AddRole}></ProtectedRoute>}></Route>
                    <Route path='/role/edit/:id' element={<ProtectedRoute component={AddRole} />} />
                    <Route path='role' element={<ProtectedRoute component={Role}></ProtectedRoute>}></Route>
                    <Route path='/role/create' element={<ProtectedRoute component={AddRole}></ProtectedRoute>}></Route>
                    <Route path='/role/edit/:id' element={<ProtectedRoute component={AddRole} />} />
                    <Route path='thresold' element={<ProtectedRoute component={Thresold}></ProtectedRoute>}></Route>
                    <Route
                        path='/thresold/create'
                        element={<ProtectedRoute component={AddThresold}></ProtectedRoute>}
                    ></Route>
                    <Route path='/thresold/edit/:id' element={<ProtectedRoute component={AddThresold} />} />
                    <Route path='/reports/edit/:id' element={<ProtectedRoute component={EditReport} />} />

                    <Route path='/transfer-warehouse' element={<ProtectedRoute component={WareHouseTransfer} />} />
                </Routes>
            </div>
        </div>
    );
}

export default App;
