import { IBranch } from 'logistic-packages';
import { api, baseUrl } from '../../api';

export const getUserRoleApi = async () => {
    const result = await api.get(`${baseUrl}/admin/organization/user-role`);
    return result.data;
};
export const getUserGroupApi = async () => {
    const result = await api.get(`${baseUrl}/admin/organization/user-group`);
    return result.data;
};
export const getUserManageAccessApi = async () => {
    const result = await api.get(`${baseUrl}/admin/organization/manage-access`);
    return result.data;
};
export const getUserBranchApi = async () => {
    const result = await api.get(`${baseUrl}/admin/organization/branch`);
    return result.data;
};
export const createUserBranchApi = async (rawdoc:IBranch) => {
    const result = await api.post(`${baseUrl}/admin/organization/branch`,{data:rawdoc});
    return result.data;
};
export const getUserDepartmentApi = async () => {
    const result = await api.get(`${baseUrl}/admin/organization/department`);
    return result.data;
};
export const getUserDesignationApi = async () => {
    const result = await api.get(`${baseUrl}/admin/organization/designation`);
    return result.data;
};
