import { configureStore, combineReducers } from '@reduxjs/toolkit';
import authSlice from './auth/authSlice';
import orderSlice from './order/orderSlice';

const rootReducer = combineReducers({
    auth: authSlice,
    order: orderSlice,
});

const store = configureStore({
    reducer: rootReducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
