/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, ChangeEvent } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./AddEmployee.css";
import {
  getUserBranch,
  getUserGroup,
  getUserRole,
} from "../../../../services/generic/organizationService";
import { IUserRole } from "logistic-packages";
import { addEmployees } from "../../../../services/employee/employeeService";
import axios from "axios";
import AddBranchModal from "../../../../components/modal/addBranchModal/AddBranchModal";

interface EmployeeDetails {
  sName: string;
  sEmail: string;
  aPhoneNo: string;
  sPassword: string;
  eGender: string;
  dDob: string;
  sPresentAddress: string;
  tUserRole: string;
  tUserGroup: string;
  tBranch: string;
}

interface ErrorDetails {
  email: string;
  password: string;
  required: string;
  phoneNo: string;
}

export const AddEmployee: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<ErrorDetails>({
    email: "",
    password: "",
    required: "",
    phoneNo: "",
  });
  const [details, setDetails] = useState<EmployeeDetails>({
    sName: "",
    sEmail: "",
    aPhoneNo: "",
    sPassword: "",
    eGender: "",
    dDob: "",
    sPresentAddress: "",
    tUserRole: "",
    tUserGroup: "",
    tBranch: "",
  });
  const [userRoleList, setUserRoleList] = useState<IUserRole[]>([]);
  const [userGroupList, setUserGroupList] = useState<IUserRole[]>([]);
  // const [manageAccessList, setManageAccessList] = useState<IUserRole[]>([]);
  const [branchList, setBranchList] = useState<IUserRole[]>([]);
  // const [deptNameList, setDeptNameList] = useState<IUserRole[]>([]);
  // const [designationList, setDesignationList] = useState<IUserRole[]>([]);
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;
  const [isFormEdited, setIsFormEdited] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showAddBranchModal, setshowAddBranchModal] = useState<boolean>(false);
  const { id: empId } = useParams<{ id: string }>();
  const currentDate = new Date().toISOString().split("T")[0];
  useEffect(() => {
    // Load initial data (same as before)
    loadInitData();
    if (empId) {
      // Fetch employee data based on the id and populate the input fields.
      fetchEmployeeData(empId);
    } else {
      // If there is no id in the URL, set the loading state to false.
      setIsLoading(false);
    }
  }, [empId]);

  function checkEmail() {
    if (!/^[\w.-]+@([\w-]+\.)+[\w-]{2,4}$/.test(details.sEmail)) {
      setError({ ...error, email: "Invalid email" });
    } else {
      setError({ ...error, email: "" });
    }
  }
  function checkPassword() {
    if (
      !/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/.test(
        details.sPassword
      )
    ) {
      setError({
        ...error,
        password:
          "Password must have 1 capital letter, 1 small letter, 1 special character and 1 number. Also it's must have 8 character.",
      });
    } else {
      setError({ ...error, password: "" });
    }
  }
  function checkPhoneNumber() {
    if (!/^[1-9]\d{2}-\d{3}-\d{4}/.test(details.aPhoneNo)) {
      setError({
        ...error,
        phoneNo: "Please Provide Valid Phone Number",
      });
    } else {
      setError({ ...error, phoneNo: "" });
    }
  }

  function checkRequired() {
    if (!details.sName) {
      setError({ ...error, required: "This field is required." });
    } else {
      setError({ ...error, required: "" });
    }
  }

  async function loadInitData() {
    try {
      await getUserRole().then((userRoles) =>
        setUserRoleList(userRoles as IUserRole[])
      );
      await getUserGroup().then((userGroup) =>
        setUserGroupList(userGroup as IUserRole[])
      );
      // await getUserManageAccess().then((userAccess) =>
      //   setManageAccessList(userAccess as IUserRole[])
      // );
      await getUserBranch().then((userBranch) =>
        setBranchList(userBranch as IUserRole[])
      );
      // await getUserDepartment().then((userDept) =>
      //   setDeptNameList(userDept as IUserRole[])
      // );
      // await getUserDesignation().then((userDesignation) =>
      //   setDesignationList(userDesignation as IUserRole[])
      // );
    } catch (err: any) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  }

  const inputhandler = async (
    e:
      | ChangeEvent<HTMLInputElement>
      | ChangeEvent<HTMLSelectElement>
      | ChangeEvent<HTMLTextAreaElement>
  ) => {
    if (e.target.name === "aPhoneNo") {
      if (e.target.value.length > 10) {
        return; // Exit the function without updating state or performing actions
      }
    }
    setDetails((details) => ({ ...details, [e.target.name]: e.target.value }));
    setIsFormEdited(true);
  };

  function isDataNotFilled() {
    return (
      !isFormEdited ||
      !(
        details.sName &&
        details.sEmail &&
        details.aPhoneNo &&
        details.sPassword &&
        details.dDob &&
        details.eGender &&
        details.sPresentAddress &&
        details.tUserRole &&
        details.tUserGroup &&
        details.tBranch &&
        error.email === "" &&
        error.password === "" &&
        error.phoneNo === "" &&
        error.required === ""
      )
    );
  }

  function fetchEmployeeData(id: string) {
    axios
      .get(apiUrl + `/admin/organization/employee/${id}`)
      .then((res) => {
        let employee = {
          ...res.data.data,
          tBranch: res.data.data.tBranch && res.data.data.tBranch._id ? res.data.data.tBranch._id : '',
          // tDesignation: res.data.data.tDesignation._id,
          tUserGroup: res.data.data.tUserGroup && res.data.data.tUserGroup._id ? res.data.data.tUserGroup._id : '',
          tUserRole: res.data.data.tUserRole && res.data.data.tUserRole[0] && res.data.data.tUserRole[0]._id ? res.data.data.tUserRole[0]._id : ''

        };
        setDetails(employee);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setIsSubmitting(true);
    const userdata = {
      data: {
        sName: details.sName,
        sEmail: details.sEmail,
        aPhoneNo: details.aPhoneNo,
        sPassword: details.sPassword,
        eGender: details.eGender,
        dDob: details.dDob,
        sPresentAddress: details.sPresentAddress,
        tUserRole: details.tUserRole,
        tUserGroup: details.tUserGroup,
        tBranch: details.tBranch,
      },
    };

    if (empId) {
      axios
        .patch(apiUrl + `/admin/organization/employee/${empId}`, userdata)
        .then((res) => {
          navigate("/users/employees");
        })
        .catch((err) => {
          toast.error(err.response.data.message, {
            position: "top-center",
            theme: "colored",
          });
        });
    } else {
      await addEmployees(userdata)
        .then((res) => {
          toast.success("Employee Added Successfully", {
            position: "top-center",
            theme: "colored",
          });
          setTimeout(() => {
            navigate("/users/employees");
          }, 3000);
        })
        .catch((err) => {
          console.error(err);
          toast.error(err.response.data.message, {
            position: "top-center",
            theme: "colored",
          });
          setIsSubmitting(false);
        });
    }
  };
  function arraytoId(arr: any[]) {
    return arr[0]._id;
  }
  const toggleBranchModal = () => {
    setshowAddBranchModal((prevShow) => !prevShow);
    loadInitData();
  };

  return (
    <>
      {isLoading ? (
        <div className="bg-loader">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : null}
      <AddBranchModal show={showAddBranchModal} onClose={toggleBranchModal} />

      <div className="mx-2">
        <div className="user-content-header py-4">
          <ToastContainer />
          <Row className="mb-2">
            <Col sm="6">
              <h1 className="m-0 text-dark">Employee</h1>
            </Col>

            <Col sm="6">
              <ol className="breadcrumb float-sm-right justify-content-end">
                <li className="breadcrumb-item">
                  <Link to="dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/users/employees">Employee</Link>
                </li>
                <li className="breadcrumb-item active">
                  {empId ? "Edit" : "Create"}
                </li>
              </ol>
            </Col>
          </Row>
        </div>
        <Container fluid className="main-container bg-white pb-4">
          <Row className="title-row">
            {empId ? <p>Edit Employee</p> : <p>Add Employee</p>}
          </Row>

          <Row className="form-group">
            <Col sm={12} md={6} className="form-fields op">
              <p>Name</p>
              <input
                value={details.sName ? details.sName : ""}
                name="sName"
                required
                onChange={(e) => inputhandler(e)}
                placeholder="Enter Name"
                className="employee-input-field"
                type="text"
              />
              <span className="danger-warning">{error.required}</span>
            </Col>

            <Col sm={12} md={6} className="form-fields op">
              <p>Email</p>

              <input
                name="sEmail"
                value={details.sEmail ? details.sEmail : ""}
                required
                onBlur={() => checkEmail()}
                onChange={(e) => inputhandler(e)}
                placeholder="Enter Email"
                className="employee-input-field"
                type="email"
              />
              <span className="danger-warning">{error.email}</span>
            </Col>
          </Row>
          <Row className="form-group">
            <Col sm={12} md={6} className="form-fields op">
              <p>Contact No</p>

              <input
                name="aPhoneNo"
                value={details.aPhoneNo ? details.aPhoneNo : ""}
                required
                onChange={(e) => inputhandler(e)}
                placeholder="Enter Phone"
                className="employee-input-field"
                type="number"
                onKeyDown={(e) => {
                  const allowedChars = "0123456789";
                  if (!allowedChars.includes(e.key) && e.key !== "Backspace") {
                    e.preventDefault();
                  }
                }}
              />
              <span className="danger-warning">{error.phoneNo}</span>
            </Col>

            <Col sm={12} md={6} className="form-fields op">
              <p>Password</p>

              <input
                onBlur={() => checkPassword()}
                value={details.sPassword ? details.sPassword : ""}
                name="sPassword"
                onChange={(e) => inputhandler(e)}
                placeholder="Enter Password"
                className="employee-input-field"
                type="password"
                disabled={!!empId}
              />
              <span className="danger-warning">{error.password}</span>
            </Col>
          </Row>
          <Row className="form-group">
            <Col sm={12} md={6} className="form-fields op">
              <p>Date of Birth</p>

              <input
                name="dDob"
                value={
                  details.dDob ? details.dDob.toString().substr(0, 10) : ""
                }
                required
                onChange={(e) => inputhandler(e)}
                placeholder="Enter Date of Birth"
                className="employee-input-field"
                type="date"
                max={currentDate}
              />
            </Col>

            <Col sm={12} md={6} className="form-fields op">
              <p>Gender</p>

              <select
                name="eGender"
                required
                value={details.eGender ? details.eGender : ""}
                onChange={(e) => inputhandler(e)}
                placeholder="Enter Gender"
                className="employee-input-field"
              >
                <option value={""}>Select any option</option>
                <option value={"MALE"}>MALE</option>
                <option value={"FEMALE"}>FEMALE</option>
                <option value={"OTHERS"}>OTHERS</option>
              </select>
            </Col>
          </Row>

          <Row className="form-group">
            <Col sm={12} className="form-fields op">
              <p>Present Address</p>

              <textarea
                name="sPresentAddress"
                value={details.sPresentAddress ? details.sPresentAddress : ""}
                required
                onChange={(e) => inputhandler(e)}
                className="employee-page-textarea"
                // name='page_meta_desc'
                cols={50}
                rows={5}
              ></textarea>
            </Col>
          </Row>

          <Row className="form-group">
            <Col sm={12} md={6} className="form-fields op">
              <p>Employee Role</p>

              <select
                name="tUserRole"
                required
                value={details.tUserRole ? details.tUserRole : ""}
                onChange={(e) => inputhandler(e)}
                className="employee-input-field"
              >
                <option value={""} disabled>
                  Select any option
                </option>
                {userRoleList.map((value, index) => (
                  <option key={index} value={value._id}>
                    {value.sName}
                  </option>
                ))}
              </select>
            </Col>
            <Col className="form-fields op">
              <p>Employee Group</p>
              <select
                name="tUserGroup"
                value={details.tUserGroup ? details.tUserGroup : ""}
                onChange={(e) => inputhandler(e)}
                className="employee-input-field"
              >
                <option value={""} disabled>
                  Select any option
                </option>

                {userGroupList.map((value, index) => (
                  <option key={index} value={value._id}>
                    {value.sName}
                  </option>
                ))}
              </select>
            </Col>
          </Row>
          <Row className="form-group" style={{ justifyContent: "center" }}>
            {/* <Col sm={12} md={6} className="form-fields op">
              <p>Manage Access</p>
              <select
                name="tManageAccess"
                required
                value={details.tManageAccess ? arraytoId(details.tManageAccess as any) : ""}
                onChange={(e) => inputhandler(e)}
                className="employee-input-field"
              >
                <option value={""} disabled>
                  Select any option
                </option>

                {manageAccessList.map((value, index) => (
                  <option key={index} value={value._id}>
                    {value.sName}
                  </option>
                ))}
              </select>
            </Col> */}

            <Col sm={12} md={6} className="form-fields op">
              <p>Branch</p>

              <select
                name="tBranch"
                required
                value={details.tBranch ? details.tBranch : ""}
                onChange={(e) => inputhandler(e)}
                placeholder="Enter Branch"
                className="employee-input-field"
              >
                <option value={""} disabled>
                  Select any option
                </option>

                {branchList.map((value, index) => (
                  <option key={index} value={value._id}>
                    {value.sName}
                  </option>
                ))}
              </select>
              <span
                className=""
                style={{ marginTop: '10px', float: "right", width: 'auto', fontSize: '12px', color: 'blue' }}
                onClick={() => toggleBranchModal()}
              >Add Branch</span>
            </Col>

          </Row>
          {/* <Row className="form-group">
            <Col className="form-fields">
              <p>Department</p>
              <select
                name="tDepartment"
                required
                value={details.tDepartment ? details.tDepartment._id : ""}
                onChange={(e) => inputhandler(e)}
                className="employee-input-field"
              >
                <option value={""} disabled>
                  Select any option
                </option>

                {deptNameList.map((value, index) => (
                  <option key={index} value={value._id}>
                    {value.sName}
                  </option>
                ))}
              </select>
            </Col>

            <Col sm={12} md={6} className="form-fields op">
              <p>Designation</p>

              <select
                name="tDesignation"
                required
                value={details.tDesignation ? details.tDesignation : ""}
                onChange={(e) => inputhandler(e)}
                placeholder="Enter Designation"
                className="employee-input-field"
              >
                <option value={""} disabled>
                  Select any option
                </option>

                {designationList.map((value, index) => (
                  <option key={index} value={value._id}>
                    {value.sName}
                  </option>
                ))}
              </select>
            </Col>
          </Row> */}
        </Container>
        <div className="card-footer text-center mb-3">
          <button
            className="btn ripple btn-primary btnDataSave"
            name="btnDataSave"
            disabled={isDataNotFilled() || isSubmitting}
            onClick={handleSubmit}
          >
            {" "}
            {empId ? "Update Employee" : "Add Employee"}{" "}
          </button>
        </div>
      </div>
    </>
  );
};
