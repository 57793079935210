import { api, baseUrl } from "../../api";

export const getAllEmployeesApi = async () => {
  const result = await api.get(`${baseUrl}/admin/organization/employee`);
  return result.data;
};
export const addEmployeesApi = async (reqbody: any) => {
  const result = await api.post(
    `${baseUrl}/admin/organization/employee`,
    reqbody
  );
  return result.data;
};
export const deleteEmployeesApi = async (id: string) => {
  const result = await api.delete(
    `${baseUrl}/admin/organization/employee/${id}`
  );
  return result.data;
};
export const employeeStatusApi = async (id: string, isActive: boolean) => {
  const result = await api.patch(
    `${baseUrl}/admin/organization/employee/${id}`,
    {
      data: { bIsActive: !isActive },
    }
  );
  return result.data;
};

export const getAllEmployeesByRoleApi = async (id: string) => {
  const result = await api.get(
    `${baseUrl}/admin/organization/employee?tUserRole=${id}`
  );
  return result.data;
};


export const getSetEmployeePasswordApi = async (id: string,data:any) => {
  const result = await api.patch(
    `${baseUrl}/admin/organization/employee/updatepassword/${id}`,
    data,
    {
      headers:{
        Authorization: 'Bearer ' + localStorage.getItem('sToken')?.replaceAll('"','')
      }
    }
  );
  return result.data;
};
