/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, ChangeEvent, useEffect } from 'react';
import './AddRole.css';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { addRole, updateRole } from '../../../../services/role/roleService';

interface RoleDetails {
    sName: string;
    
    sTag: string;
   
   
}

export const AddRole: React.FC = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [details, setDetails] = useState<RoleDetails>({
        sName: '',
        
        sTag: '',
      
    });
    // const apiUrl = process.env.REACT_APP_LOCAL_API_URL as string;

    const apiUrl = process.env.REACT_APP_API_URL as string;
    const [isFormEdited, setIsFormEdited] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        if (roleId) {
            fetchRoleData(roleId);
        } else {
            setIsLoading(false);
        }
    }, []);

    const navigate = useNavigate();
    const { search } = useLocation();
    const { id: roleId } = useParams<{ id: string }>();

    const inputhandler = async (
        e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement> | ChangeEvent<HTMLTextAreaElement>
    ) => {
        setDetails({ ...details, [e.target.name]: e.target.value });
        setIsFormEdited(true);
    };

    function isDataNotFilled() {
        return (
            !isFormEdited ||
            !(
                details.sName &&
                details.sTag 
            )
        );
    }

    const fetchRoleData = (id: string) => {
        axios
            .get(apiUrl + `/admin/organization/user-role/${id}`)
            .then((res) => {
                setDetails(res.data.data);
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
            });
    };

    const handleSubmit = async (e: { preventDefault: () => void }) => {
        e.preventDefault();
        setIsSubmitting(true);
        const roleData = {
            data: {
               
                sName: details.sName,
          
                sTag: details.sTag,
               
            },
        };

        if (roleId) {
            updateRole(roleId,roleData)
                .then((res) => {
                    navigate('/role');
                })
                .catch((err) => {
                    toast.error(err.response.data.message, {
                        position: 'top-center',
                        theme: 'colored',
                    });
                });
        } else {
            await addRole(roleData)
                .then((res) => {
                    toast.success('Role Added Successfully', {
                        position: 'top-center',
                        theme: 'colored',
                    });
                    setTimeout(() => {
                        navigate(`/settings?activeTab=role${search}`);
                    }, 1000);
                })
                .catch((err) => {
                    console.error(err);
                    toast.error(err.response.data.message, {
                        position: 'top-center',
                        theme: 'colored',
                    });
                    setIsSubmitting(false);
                });
        }
    };

    return (
        <>
            {isLoading ? (
                <div className='bg-loader'>
                    <Spinner animation='border' variant='primary' />
                </div>
            ) : null}
            <div className='mx-2 '>
                <div className='user-content-header py-4'>
                    <ToastContainer />
                    <Row className='mb-2'>
                        <Col sm='6'>
                            <h1 className='m-0 text-dark'>Roles</h1>
                        </Col>

                        <Col sm='6'>
                            <ol className='breadcrumb float-sm-right justify-content-end'>
                                <li className='breadcrumb-item'>
                                    <Link to='/dashboard'>Home</Link>
                                </li>
                                <li className='breadcrumb-item'>
                                    <Link to={{ pathname: '/settings', search: '?activeTab=role' }}>Roles</Link>
                                </li>
                                <li className='breadcrumb-item active'>{roleId ? 'Edit' : 'Create'}</li>
                            </ol>
                        </Col>
                    </Row>
                </div>
                <Container fluid className='main-container bg-white'>
                    <Row className='title-row'>{roleId ? <p>Edit Role</p> : <p>Add Role</p>}</Row>

                    <Row className='form-group'>
                       

                        <Col sm={12} md={12} className='form-fields op'>
                        <p>Name</p>
                            <input
                                name='sName'
                                onChange={(e) => inputhandler(e)}
                                placeholder='Enter Name'
                                className='vehical-input-field'
                                type='text'
                                value={details.sName}
                                disabled={!!roleId}
                                
                            />
                        </Col>
                    </Row>
                    <Row className='form-group'>
                        <Col sm={12} md={12} className='form-fields op mb-4'>
                      
                            <p>Tag</p>
                            <input
                                name='sTag'
                                onChange={(e) => inputhandler(e)}
                                placeholder='Enter Tag'
                                className='vehical-input-field'
                                type='text'
                                value={details.sTag}
                            />
                        </Col>
                    </Row>
                    
                   
                </Container>
                <div className='card-footer text-center mb-3'>
                    <button
                        className='btn ripple btn-primary btnDataSave'
                        name='btnDataSave'
                        disabled={isDataNotFilled() || isSubmitting}
                        onClick={handleSubmit}
                    >
                        {' '}
                        {roleId ? 'Update Role' : 'Add  Role'}{' '}
                    </button>
                </div>
            </div>
        </>
    );
};
