import React, { useEffect, useState } from "react";
import "./Vehicle.css";
import { Button, Col, Container, Row } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { Link, useNavigate } from "react-router-dom";
import { DeleteModal } from "../../components/modal/deletemodal/DeleteModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DetailsModal } from "../../components/modal/showDetails/DetailsModal";
import {
  deleteAVehicle,
  getAllVehicles,
  syncVehicles,
} from "../../services/vehicle/vehicleService";
import { IVehicle } from "logistic-packages";
import { DataTable } from "../../components/table/Table";
import { faRotate } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const columnDefinitions = [
  { label: "ID", key: "_id" },
  { label: "Registration No", key: "sRegNo" },
  { label: "Vehicle Type", key: "sType" },
  { label: "Vehicle Model", key: "sModel" },
  { label: "Company", key: "sCompany" },
  { label: "Assigned Driver", key: "sDriverName" },
  { label: "Capacity(ton)", key: "aCapacity" },
];

export const Vehicle: React.FC = () => {
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [vehicleData, setVehicleData] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [recordSize, setRecordSize] = useState(10);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState<string | false>(false);
  const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false);
  const [isSync, setIsSync] = useState(false);
  const [vehicleDetails, setVehicleDetails] = useState<{ [keys: string]: any }>(
    {}
  );

  async function loadData() {
    try {
      const vehicles = await getAllVehicles();
      if (vehicles) {
        setVehicleData((vehicles as any).map((vcl: any)=>Object({...vcl,sDriverName:vcl.tDriver?.sName||''})));
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);

  const vehicleArray = (pageNumber: number) => {
    if (pageNumber >= 0 && pageNumber < totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const startIndex = currentPage * recordSize;
  const endIndex = startIndex + recordSize;

  const deleteVehicle = async (id: string) => {
    setShowModal(false);
    try {
      await deleteAVehicle(id).then(() => loadData());
    } catch (err) {
      toast.error("Cannot delete, please try again !", {
        position: "top-center",
        theme: "colored",
      });
    } finally {
      toast.success("Vehicle Deleted Successfully !", {
        position: "top-center",
        theme: "colored",
      });
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    setCurrentPage(0);
  };
  const handleSync = async () => {
    setIsSync(true);
    try {
      const vehicles = await syncVehicles();
      if(vehicles) setVehicleData(vehicles as IVehicle[])
      setIsSync(false);
      toast.success("Vehicle Data Synchronized!", {
        position: "top-center",
        theme: "colored",
      });
    } catch (error) {
      setIsSync(false);

      toast.error("Synchronization Failed!", {
        position: "top-center",
        theme: "colored",
      });
    }
  };

  const handleRecordSizeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setRecordSize(Number(e.target.value));
    setCurrentPage(0);
  };

  const editVehicle = async (id: string) => {
    navigate(`/vehicles/edit/${id}`);
  };

  const handleShowDetails = (orderInfo: any) => {
    setVehicleDetails({
      "Registration No": orderInfo.sRegNo,
      "Vehicle Type": orderInfo.sType,
      "Model Name": orderInfo.sModel,
      Company: orderInfo.sCompany,
      Capacity: orderInfo.aCapacity,
      Description: orderInfo.sDescription,
    });
    setShowDetailsModal(true);
  };

  const filteredItems = vehicleData.filter((item) => {
    const searchTerm = search.trim().toLowerCase();

    const itemName = item.sRegNo ? item.sRegNo.toLowerCase() : "";

    return searchTerm === "" ? item : itemName.includes(searchTerm);
  });

  const totalFilteredItems = filteredItems.length;
  const totalPages = Math.ceil(totalFilteredItems / recordSize);

  const paginatedItems = filteredItems.slice(
    currentPage * recordSize,
    (currentPage + 1) * recordSize
  );

  useEffect(() => {
    if (currentPage > 0 && paginatedItems.length === 0) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  }, [paginatedItems, currentPage]);

  return (
    <>
      {showModal && (
        <DeleteModal
          id={showModal}
          setShowModal={setShowModal}
          action={deleteVehicle}
        />
      )}

      {showDetailsModal && (
        <DetailsModal
          title={"Vehicle Details"}
          orderInfo={vehicleDetails}
          setShowDetailsModal={setShowDetailsModal}
          isConsignment={false}
        />
      )}

      <Container fluid style={{ opacity: showModal ? "0.4" : "1" }}>
        <div className="user-content-header py-4">
          <ToastContainer />
          <Row className="mb-2">
            <Col sm="6">
              <h1 className="m-0 text-dark">Vehicles</h1>
            </Col>
            <Col sm="6">
              <ol className="breadcrumb float-sm-right justify-content-end">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item active">Vehicles</li>
              </ol>
            </Col>
          </Row>
        </div>

        <div className="content">
          <Row>
            <Col lg="12">
              <div className="card" bg-color="white">
                <div className="card-header bg-white">
                  <h3 className="card-title">&nbsp;</h3>

                  <Button
                    variant="info"
                    className="user float-right add_role text-white ml-2"
                    onClick={handleSync}
                  >
                    <span className="sync-button">Sync Vehicle </span>
                    {isSync ? (
                      <Spinner animation="border" size="sm" className="me-1" />
                    ) : (
                      <>
                        <FontAwesomeIcon icon={faRotate} />
                      </>
                    )}
                  </Button>
                </div>

                <div className="card-body">
                  <div
                    id="DataTables_Table_0_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <div className="card-head">
                      <div
                        className="dataTables_length"
                        id="DataTables_Table_0_length"
                      >
                        <label>
                          Show{" "}
                          <select
                            name="DataTables_Table_0_length"
                            aria-controls="DataTables_Table_0"
                            className="table-input"
                            value={recordSize}
                            onChange={handleRecordSizeChange}
                          >
                            <option value="10">10</option>
                            <option value="15">15</option>
                            <option value="20">20</option>
                            <option value="100">100</option>
                          </select>{" "}
                          entries
                        </label>
                      </div>
                      <div
                        id="DataTables_Table_0_filter"
                        className="dataTables_filter"
                      >
                        <label
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          Search:
                          <input
                            type="search"
                            className="table-input"
                            placeholder="Search Registration No..."
                            aria-controls="DataTables_Table_0"
                            value={search}
                            onChange={handleSearch}
                            style={{ height: "2rem" }}
                          />
                        </label>
                      </div>
                    </div>

                    <div style={{ overflow: "auto" }}>
                      <DataTable
                        columnDefinitions={columnDefinitions}
                        paginatedItems={paginatedItems}
                        handleShowDetails={handleShowDetails}
                        editFunction={editVehicle}
                        deleteFunction={deleteVehicle}
                        setShowModal={setShowModal}
                        isLoading={isLoading}
                        tableFor="Vehicle"
                      />
                    </div>
                    {isLoading ? (
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ height: "20vh" }}
                      >
                        <Spinner animation="border" variant="primary" />
                      </div>
                    ) : null}
                    <div className="d-flex justify-content-between align-items-center">
                      <div
                        className="dataTables_info"
                        id="DataTables_Table_0_info"
                        role="status"
                        aria-live="polite"
                      >
                        {totalFilteredItems > 0
                          ? `Showing ${
                              endIndex < totalFilteredItems
                                ? endIndex
                                : totalFilteredItems
                            } of ${totalFilteredItems} entries`
                          : ""}
                      </div>
                      <div
                        className="dataTables_paginate paging_simple_numbers"
                        id="DataTables_Table_0_paginate"
                      >
                        <Button
                          className="paginate_button previous me-2"
                          aria-controls="DataTables_Table_0"
                          data-dt-idx={0}
                          tabIndex={-1}
                          id="DataTables_Table_0_previous"
                          onClick={() => {
                            vehicleArray(currentPage - 1);
                          }}
                          disabled={currentPage === 0}
                        >
                          Previous
                        </Button>
                        <span>
                          <Button
                            className="paginate_button-current"
                            aria-controls="DataTables_Table_0"
                            data-dt-idx={1}
                            tabIndex={0}
                          >
                            {currentPage + 1}
                          </Button>
                        </span>
                        <Button
                          className="paginate_button next ms-2"
                          aria-controls="DataTables_Table_0"
                          data-dt-idx="2"
                          tabIndex={-1}
                          id="DataTables_Table_0_next"
                          onClick={() => {
                            vehicleArray(currentPage + 1);
                          }}
                          disabled={
                            currentPage === totalPages - 1 ||
                            filteredItems.length < 10
                          }
                        >
                          Next
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};
