/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, ChangeEvent } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./AddDriver.css";
import { Gender, IDriver, IUserRole } from "logistic-packages";
import { addEmployees } from "../../../../services/employee/employeeService";
import axios from "axios";
import AddBranchModal from "../../../../components/modal/addBranchModal/AddBranchModal";
import { createDriver } from "../../../../services/driver/driverService";

interface EmployeeDetails {
  sName: string;
  sEmail: string;
  aPhoneNo: string;
  eGender: string;
  dDob: string;
  sPresentAddress: string;
}

interface ErrorDetails {
  email: string;
  password: string;
  required: string;
  phoneNo: string;
}

export const AddDriver = (props: any) => {
  const [error, setError] = useState<ErrorDetails>({
    email: "",
    password: "",
    required: "",
    phoneNo: "",
  });
  const [details, setDetails] = useState<IDriver>({
    sName: "",
    sEmail: "",
    aPhoneNo: 0,
    eGender: Gender.MALE,
    dDob: undefined,
    sPresentAddress: "",
  } as IDriver);

  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;
  const [isFormEdited, setIsFormEdited] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showAddBranchModal, setshowAddBranchModal] = useState<boolean>(false);
  const { id: empId } = useParams<{ id: string }>();
  const currentDate = new Date().toISOString().split("T")[0];

  function checkEmail() {
    if (!/^[\w-]+@([\w-]+\.)+[\w-]{2,4}$/.test(details.sEmail || "")) {
      setError({ ...error, email: "Invalid email" });
    } else {
      setError({ ...error, email: "" });
    }
  }

  const inputhandler = async (
    e:
      | ChangeEvent<HTMLInputElement>
      | ChangeEvent<HTMLSelectElement>
      | ChangeEvent<HTMLTextAreaElement>
  ) => {
    if (e.target.name === "aPhoneNo") {
      if (e.target.value.length > 10) {
        return; // Exit the function without updating state or performing actions
      }
    }
    setDetails(
      (details) => ({ ...details, [e.target.name]: e.target.value } as IDriver)
    );
    setIsFormEdited(true);
  };

  function isDataNotFilled() {
    return !isFormEdited || !(details.sName && details.aPhoneNo);
  }

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setIsSubmitting(true);
    const userData = {
      sName: details.sName,
      sEmail: details.sEmail,
      aPhoneNo: details.aPhoneNo,
      eGender: details.eGender,
      dDob: details.dDob,
      sPresentAddress: details.sPresentAddress,
    };
    await createDriver(userData)
      .then((res) => {
        toast.success("Driver Added Successfully", {
          position: "top-center",
          theme: "colored",
        });
        setTimeout(() => {
          navigate("/users/drivers");
        }, 3000);
      })
      .catch((err) => {
        console.error(err);
        toast.error(err.response.data.message, {
          position: "top-center",
          theme: "colored",
        });
        setIsSubmitting(false);
      });
  };

  return (
    <>
      <div className="mx-2">
        <div className="user-content-header py-4">
          <ToastContainer />
          <Row className="mb-2">
            <Col sm="6">
              <h1 className="m-0 text-dark">Driver</h1>
            </Col>

            <Col sm="6">
              <ol className="breadcrumb float-sm-right justify-content-end">
                <li className="breadcrumb-item">
                  <Link to="dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/users/drivers">Drivers</Link>
                </li>
                <li className="breadcrumb-item active">
                  {empId ? "Edit" : "Create"}
                </li>
              </ol>
            </Col>
          </Row>
        </div>
        <Container fluid className="main-container bg-white pb-4">
          <Row className="title-row">
            <p>Add Driver</p>
          </Row>

          <Row className="form-group">
            <Col sm={12} md={6} className="form-fields op">
              <p>Name</p>
              <input
                value={details.sName ? details.sName : ""}
                name="sName"
                required
                onChange={(e) => inputhandler(e)}
                placeholder="Enter Name"
                className="employee-input-field"
                type="text"
              />
              <span className="danger-warning">{error.required}</span>
            </Col>
            <Col sm={12} md={6} className="form-fields op">
              <p>Contact No</p>

              <input
                name="aPhoneNo"
                value={details.aPhoneNo ? details.aPhoneNo : ""}
                required
                onChange={(e) => inputhandler(e)}
                placeholder="Enter Phone"
                className="employee-input-field"
                type="number"
                onKeyDown={(e) => {
                  const allowedChars = "0123456789";
                  if (!allowedChars.includes(e.key) && e.key !== "Backspace") {
                    e.preventDefault();
                  }
                }}
              />
              <span className="danger-warning">{error.phoneNo}</span>
            </Col>
          </Row>
          <Row className="form-group">
            <Col sm={12} md={6} className="form-fields op">
              <p>Email</p>

              <input
                name="sEmail"
                value={details.sEmail ? details.sEmail : ""}
                required
                onBlur={() => checkEmail()}
                onChange={(e) => inputhandler(e)}
                placeholder="Enter Email"
                className="employee-input-field"
                type="email"
              />
              <span className="danger-warning">{error.email}</span>
            </Col>
            <Col sm={12} md={6} className="form-fields op">
              <p>Gender</p>

              <select
                name="eGender"
                required
                value={details.eGender ? details.eGender : ""}
                onChange={(e) => inputhandler(e)}
                placeholder="Enter Gender"
                className="employee-input-field"
              >
                <option value={""}>Select any option</option>
                <option value={"MALE"}>MALE</option>
                <option value={"FEMALE"}>FEMALE</option>
                <option value={"OTHERS"}>OTHERS</option>
              </select>
            </Col>
          </Row>
          <Row className="form-group justify-content-center">
            <Col sm={12} md={6} className="form-fields op">
              <p>Date of Birth</p>

              <input
                name="dDob"
                // value={
                //   d.dDob ? details.dDob.toString().substr(0, 10) : ""
                // }
                required
                onChange={(e) => inputhandler(e)}
                placeholder="Enter Date of Birth"
                className="employee-input-field"
                type="date"
                max={currentDate}
              />
            </Col>
          </Row>

          <Row className="form-group">
            <Col sm={12} className="form-fields op">
              <p>Present Address</p>

              <textarea
                name="sPresentAddress"
                value={details.sPresentAddress ? details.sPresentAddress : ""}
                required
                onChange={(e) => inputhandler(e)}
                className="employee-page-textarea"
                // name='page_meta_desc'
                cols={50}
                rows={5}
              ></textarea>
            </Col>
          </Row>
        </Container>
        <div className="card-footer text-center mb-3">
          <button
            className="btn ripple btn-primary btnDataSave"
            name="btnDataSave"
            disabled={isDataNotFilled() || isSubmitting}
            onClick={handleSubmit}
          >
            {" "}
            {empId ? "Update Driver" : "Add Driver"}{" "}
          </button>
        </div>
      </div>
    </>
  );
};
