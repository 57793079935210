/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, ChangeEvent } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./AddWarehouse.css";
import {
  addWarehouse,
  getAllManagers,
  updateWarehouse,
} from "../../../services/warehouse/warehouseService";
import { IEmployee, IWareHouse } from "logistic-packages";
import { getAllEmployeesByRole } from "../../../services/employee/employeeService";

interface BillDetails {
  bStatus: boolean;
  sName: string;
  sAddress: string;
  sCapacity: string;
  tManager: {
    sName?: string;
    _id: string|null;
  };
  aPinCode: string;
  sDescription: string;
  sLocationCode: string;
}

export const AddWarehouse: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isFormEdited, setIsFormEdited] = useState(false);
  const [assignedManagerList, setAssignedManagerList] = useState<IEmployee[]>(
    []
  );

  const [details, setDetails] = useState<BillDetails>({
    sName: "",
    sAddress: "",
    sCapacity: "",
    tManager: {
      sName: "",
      _id: "",
    },
    aPinCode: "",
    sDescription: "",
    sLocationCode: "",
    bStatus: true,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    loadInitData();
    if (warehouseId) {
      fetchWarehouseData(warehouseId);
    }
  }, []);

  const loadInitData = async () => {
    getAllManagers()
      .then(async (res: any) => {
        if (res.length > 0) {
          const employee: IEmployee[] = (await getAllEmployeesByRole(
            res[0]._id
          )) as IEmployee[];
          setAssignedManagerList((prev)=>([...prev,...employee]))
        }
        setIsLoading(false);
      })
      .catch((err: any) => {
        setIsLoading(false);
      });
  };
  const { id: warehouseId } = useParams<{ id: string }>();
  const inputhandler = (
    e:
      | ChangeEvent<HTMLInputElement>
      | ChangeEvent<HTMLSelectElement>
      | ChangeEvent<HTMLTextAreaElement>,
    checkbox?: boolean
  ) => {
    const { name, value } = e.target;

    if (name === "tManager._id") {
      setDetails({
        ...details,
        tManager: {
          _id: value,
        },
      });
      setIsFormEdited(true);
    } else if (checkbox) {
      setDetails({
        ...details,
        [name]: (e.target as HTMLInputElement).checked,
      });
      setIsFormEdited(true);
    } else {
      setDetails({ ...details, [name]: value });
      setIsFormEdited(true);
    }
  };

  function isDataNotFilled() {
    return (
      !isFormEdited ||
      !(
        details.sName &&
        details.sAddress &&
        details.sCapacity &&
        details.aPinCode &&
        details.sLocationCode &&
        details.sDescription
      )
    );
  }

  const fetchWarehouseData = (id: string) => {
    axios
      .get(apiUrl + `/admin/warehouse/${id}`)
      .then((res) => {
        debugger
        if (res?.data?.data?.tManager) setAssignedManagerList((prev)=>([...prev,res.data.data.tManager]))
        setDetails(res.data.data);
        
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const handlesubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setIsSubmitting(true);
    const warehouseData = {
      data: {
        sName: details.sName,
        sAddress: details.sAddress,
        sCapacity: details.sCapacity,
        tManager: details.tManager?._id ,
        aPinCode: details.aPinCode,
        sDescription: details.sDescription,
        sLocationCode: details.sLocationCode,
        bStatus: details.bStatus,
        bIsOpen: details.bStatus,
      },
    };
    if (warehouseId) {
      updateWarehouse(warehouseId, warehouseData)
        .then((res) => {
          navigate("/warehouse");
        })
        .catch((err) => {
          toast.error(err.response.data.message, {
            position: "top-center",
            theme: "colored",
          });
        });
    } else {
      await addWarehouse(warehouseData)
        .then((res) => {
          toast.success("Warehouse Created Successfully", {
            position: "top-center",
            theme: "colored",
          });

          setTimeout(() => {
            navigate("/warehouse");
          }, 1000);
        })
        .catch((err) => {
          console.error(err);
          toast.error(err.response.data.message, {
            position: "top-center",
            theme: "colored",
          });
          setIsSubmitting(false);
        });
    }
  };

  return (
    <>
      {isLoading ? (
        <div className="bg-loader">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : null}
      <div className="mx-2 ">
        <div className="user-content-header py-4">
          <ToastContainer />
          <Row className="mb-2">
            <Col sm="6">
              <h1 className="m-0 text-dark">Warehouse</h1>
            </Col>

            <Col sm="6">
              <ol className="breadcrumb float-sm-right justify-content-end">
                <li className="breadcrumb-item">
                  <Link to="dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/warehouse">Warehouse</Link>
                </li>
                <li className="breadcrumb-item active">
                  {warehouseId ? "Edit" : "Create"}
                </li>
              </ol>
            </Col>
          </Row>
        </div>
        <Container fluid className="main-container bg-white pb-4">
          {/* <Row className="title-row">
            {warehouseId ? <p>Edit Warehouse</p> : <p>Add Warehouse</p>}
          </Row> */}

          <Row className="form-group">
            <Col sm={12} md={6} className="form-fields op">
              <p>Warehouse Name</p>
              <input
                name="sName"
                required
                onChange={(e) => inputhandler(e)}
                placeholder="Enter Warehouse Name"
                className="warehouse-input-field"
                type="text"
                value={details.sName}
              />
            </Col>

            <Col sm={12} md={6} className="form-fields op">
              <p> Address</p>

              <input
                name="sAddress"
                required
                onChange={(e) => inputhandler(e)}
                placeholder="Enter Address"
                className="warehouse-input-field"
                type="text"
                value={details.sAddress}
              />
            </Col>
          </Row>
          <Row className="form-group">
            <Col sm={12} md={6} className="form-fields op">
              <p>Capacity</p>

              <input
                name="sCapacity"
                required
                onChange={(e) => inputhandler(e)}
                placeholder="Enter Capacity"
                className="warehouse-input-field"
                type="text"
                value={details.sCapacity}
              />
            </Col>

            <Col sm={12} md={6} className="form-fields op">
              <p>Warehouse Manager</p>
              <select
                name="tManager._id"
                required
                value={
                  details.tManager && details.tManager._id
                    ? details.tManager._id
                    : ""
                }
                onChange={(e) => inputhandler(e)}
                className="warehouse-input-field"
              >
                <option value={""} disabled>
                  Select any option
                </option>
                {assignedManagerList.map((value, index) => (
                  <option key={index} value={value._id}>
                    {value.sName}
                  </option>
                ))}
              </select>

              &nbsp; <a href="#" style={{color:'red',fontSize:'.75rem'}} onClick={()=>{
                setDetails({ ...details,tManager:{
                  _id:null
                } });
                setIsFormEdited(true);
              }}>Remove Manager</a>
            </Col>
          </Row>
          <Row className="form-group">
            <Col sm={12} md={6} className="form-fields op">
              <p>PinCode</p>

              <input
                name="aPinCode"
                required
                onChange={(e) => inputhandler(e)}
                placeholder="Enter PinCode"
                className="warehouse-input-field"
                type="number"
                value={details.aPinCode}
              />
            </Col>

            <Col sm={12} md={6} className="form-fields op">
              <p> Description</p>

              <input
                name="sDescription"
                required
                onChange={(e) => inputhandler(e)}
                placeholder="Enter Description"
                className="warehouse-input-field"
                type="text"
                value={details.sDescription}
              />
            </Col>
          </Row>
          <Row className="form-group justify-content-center">
            <Col sm={12} md={4} className="form-fields op">
              <p>Location Code</p>

              <input
                name="sLocationCode"
                required
                onChange={(e) => inputhandler(e)}
                placeholder="Enter Location Code"
                className="new-page-input "
                type="text"
                value={details.sLocationCode}
              />
            </Col>
            <Col sm={12} md={4} className="form-fields op">
              <p>Warehouse Active</p>

              <input
                name="bStatus"
                required
                onChange={(e) => inputhandler(e, true)}
                className="new-page-input acitve-checkbox"
                type="checkbox"
                checked={details.bStatus}
              />
            </Col>
          </Row>
        </Container>
        <div className="card-footer text-center mb-3">
          <button
            className="btn ripple btn-primary btnDataSave"
            name="btnDataSave"
            disabled={isDataNotFilled() || isSubmitting}
            onClick={handlesubmit}
          >
            {" "}
            {warehouseId ? "Update Warehouse" : "Add  Warehouse"}{" "}
          </button>
        </div>
      </div>
    </>
  );
};
