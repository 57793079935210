 import { api, baseUrl } from "../../api";

 export const getAllThresoldApi = async () => {
    const result = await api.get(`${baseUrl}/admin/settings`);
    return result.data;
};
export const addThresoldApi = async (reqbody: any) =>{
    const result = await api.post(`${baseUrl}/admin/settings`,reqbody);
    return result.data;
}
export const deleteThresoldApi = async (id: string) => {
    const result = await api.delete(`${baseUrl}/admin/settings/${id}`);
    return result.data;
};