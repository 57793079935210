import { getAllNotificationApi, updateNotificationApi } from '../../api/notification/notificationApi';

export const getAllNotification = (pageNumber: number) => {
    return new Promise((resolve, reject) => {
        getAllNotificationApi(pageNumber)
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const updateNotification = (id: string, notification: any) => {
    return new Promise((resolve, reject) => {
        updateNotificationApi(id, notification)
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
