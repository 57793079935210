/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import { Button, Modal, Table } from 'react-bootstrap';
import ReactDOM from 'react-dom';
import './DetailsModal.css';
interface DetailsModalProps {
    title: string;
    orderInfo: { [x: string]: any };
    isConsignment?: boolean;

    setShowDetailsModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DetailsModal: React.FC<DetailsModalProps> = ({ title, orderInfo, setShowDetailsModal, isConsignment }) => {
    const [infoList, setInfoList] = useState<string[]>([]);

    useEffect(() => {
        setInfoList(Object.keys(orderInfo));
    }, []);

    // Add a null check before calling createPortal
    const targetElement = document.querySelector('.myPortalModalDiv');
    if (!targetElement) return null;

    let modalContent;

    return ReactDOM.createPortal(
        <Modal
            show={true}
            onHide={() => setShowDetailsModal(false)}
            size='lg'
            centered
            // Add the animation class conditionally
            className={`modal ${orderInfo ? '' : 'no-animation'}`}
        >
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {orderInfo &&
                    (isConsignment ? (
                        <div>
                            <div className='row'>
                                <div className='col-md-4'>
                                    <label> Consignment No.</label>
                                    <p>{orderInfo?.sConsignmentNo}</p>
                                </div>
                                <div className='col-md-4'>
                                    <label> Delivery Status</label>
                                    <p>{(Status[orderInfo?.eStatus]).replace(/_/g, " ")}</p>
                                </div>
                                <div className='col-md-4'>
                                    <label> Order Date</label>
                                    <p>{orderInfo?.tItems[0]?.dPostingDate.slice(0, 10)}</p>
                                </div>
                            </div>
                            <div className='row justify-content-center'>
                                {orderInfo?.dDeliveryDate && <div className='col-md-4'>
                                    <label> Delivery Date</label>
                                    <p>{orderInfo?.dDeliveryDate.slice(0, 10)}</p>
                                </div>}
                                <div className='col-md-4'>
                                    <label>Customer</label>
                                    <p>{orderInfo?.tItems[0]?.sBilltoName}</p>
                                </div>
                                <div className='col-md-4'>
                                    <label> Location</label>
                                    <p>{orderInfo?.tItems[0]?.sBilltocity}</p>
                                </div>
                            </div>
                            <div className='row'></div>
                            <div className='row justify-content-center'>
                                <div className='col-md-5'>
                                    <p>
                                        <b> Driver: </b>
                                        {orderInfo.tDriver?.sName}
                                    </p>
                                    <p>
                                        <b> Driver No: </b>
                                        {orderInfo.tDriver?.aPhoneNo}
                                    </p>
                                </div>

                                <div className='col-md-5'>
                                    <p>
                                        <b> Document No: </b>
                                        {orderInfo?.tItems[0]?.sDocumentNo}
                                    </p>
                                    <p>
                                        <b> Order Quantity: </b>
                                        {orderInfo?.aQuantity}
                                    </p>
                                </div>
                            </div>
                            <div className='row justify-content-center'>
                                <div className='col-md-5'>
                                    <p>
                                        <b> Price: </b>
                                        {orderInfo.tItems[0]?.aTotalInvAmount}
                                    </p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='item-table'>
                                    <table className='invoice-table'>
                                        <thead>
                                            <tr>
                                                <th>Item No</th>
                                                <th>Item Name</th>
                                                <th>Quantity</th>
                                                <th>Price</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {orderInfo.tItems
                                                ? orderInfo.tItems.map((item: any, index: number) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{item.sItemNo ?? ''}</td>
                                                            <td>{item.sDescription ?? ''}</td>
                                                            <td>{item.aQuantity ?? ''}</td>
                                                            <td>{item.aAmount ?? ''}</td>
                                                        </tr>
                                                    );
                                                })
                                                : null}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <Table striped bordered responsive>
                            <tbody>
                                {infoList.map((info, index) => (
                                    <tr key={index}>
                                        <td>{info.replace('_', ' ')}</td>
                                        <td>{orderInfo[info]}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    ))}
            </Modal.Body>
            <Modal.Footer>
                <Button variant='danger' onClick={() => setShowDetailsModal(false)}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>,
        targetElement // Use the targetElement here
    );
};

enum Status {
    Ordered = 0,
    Inprogress = 1,
    Delivered = 2,
    Out_For_Delivery = 3,
    Forced_Delivery = 4
}