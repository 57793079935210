import React, { useEffect, useState } from "react";
import "./Thresold.css";
import { Button, Col, Container, Row } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { DeleteModal } from "../../../components/modal/deletemodal/DeleteModal";
import { DataTable } from "../../../components/table/Table";
import {
  deleteThresold,
  getAllThresold,
} from "../../../services/thresold/thresoldService";

const columnDefinitions = {
  Thresold: [
    { label: "ID", key: "_id" },
    { label: "Parameter", key: "sKey" },
    { label: "Value", key: "sValue" },
  ],
};

export const Thresold: React.FC = () => {
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [thresoldData, setThresoldData] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [recordSize, setRecordSize] = useState(10);
  const [showModal, setShowModal] = useState<string | false>(false);

  async function loadData() {
    try {
      const thresold = await getAllThresold();
      if (thresold) {
        setThresoldData(thresold as any[]);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);

  const thresoldArray = (pageNumber: number) => {
    if (pageNumber >= 0 && pageNumber < totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const startIndex = currentPage * recordSize;
  const endIndex = startIndex + recordSize;

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    setCurrentPage(0);
  };

  const handleRecordSizeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setRecordSize(Number(e.target.value));
    setCurrentPage(0);
  };

  // const editVehicle = async (id: string) => {
  //   navigate(`/group/edit/${id}`);
  // };

  const deletethresoldData = async (id: string) => {
    try {
      await deleteThresold(id).then(() => loadData());
    } catch (err) {
      toast.error("Cannot delete, please try again !", {
        position: "top-center",
        theme: "colored",
      });
    } finally {
      toast.success("Threshold Deleted Successfully !", {
        position: "top-center",
        theme: "colored",
      });
    }
  };

  const filteredItems = thresoldData.filter((item) => {
    const searchTerm = search.trim().toLowerCase();

    const itemName = item.settingsKey ? item.settingsKey.toLowerCase() : "";

    return searchTerm === "" ? item : itemName.includes(searchTerm);
  });

  const totalFilteredItems = filteredItems.length;
  const totalPages = Math.ceil(totalFilteredItems / recordSize);

  const paginatedItems = filteredItems.slice(
    currentPage * recordSize,
    (currentPage + 1) * recordSize
  );

  useEffect(() => {
    if (currentPage > 0 && paginatedItems.length === 0) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  }, [paginatedItems, currentPage]);

  return (
    <>
      {showModal && (
        <DeleteModal
          id={showModal}
          setShowModal={setShowModal}
          action={deletethresoldData}
        />
      )}

      <Container fluid style={{ opacity: showModal ? "0.4" : "1" }}>
        <div className="user-content-header">
          <ToastContainer />
        </div>

        <div className="content">
          <Row>
            <Col lg="12">
              <div className="card" bg-color="white">
                <div className="card-header bg-white">
                  <h3 className="card-title">&nbsp;</h3>
                  <Link to="/thresold/create">
                    <Button
                      variant="info"
                      className="user float-right add_role text-white"
                    >
                      Add Threshold
                    </Button>
                  </Link>
                </div>

                <div className="card-body">
                  <div
                    id="DataTables_Table_0_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <div className="card-head">
                      <div
                        className="dataTables_length"
                        id="DataTables_Table_0_length"
                      >
                        <label>
                          Show{" "}
                          <select
                            name="DataTables_Table_0_length"
                            aria-controls="DataTables_Table_0"
                            className="table-input"
                            value={recordSize}
                            onChange={handleRecordSizeChange}
                          >
                            <option value="10">10</option>
                            <option value="15">15</option>
                            <option value="20">20</option>
                            <option value="100">100</option>
                          </select>{" "}
                          entries
                        </label>
                      </div>
                      <div
                        id="DataTables_Table_0_filter"
                        className="dataTables_filter"
                      ></div>
                    </div>

                    <div style={{ overflow: "auto" }}>
                      <DataTable
                        columnDefinitions={columnDefinitions.Thresold}
                        paginatedItems={paginatedItems}
                        deleteFunction={deletethresoldData}
                        setShowModal={setShowModal}
                        isLoading={isLoading}
                        tableFor="Thresold"
                      />
                    </div>
                    {isLoading ? (
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ height: "20vh" }}
                      >
                        <Spinner animation="border" variant="primary" />
                      </div>
                    ) : null}
                    <div className="d-flex justify-content-between align-items-center">
                      <div
                        className="dataTables_info"
                        id="DataTables_Table_0_info"
                        role="status"
                        aria-live="polite"
                      >
                        {totalFilteredItems > 0
                          ? `Showing ${
                              endIndex < totalFilteredItems
                                ? endIndex
                                : totalFilteredItems
                            } of ${totalFilteredItems} entries`
                          : ""}
                      </div>
                      <div
                        className="dataTables_paginate paging_simple_numbers"
                        id="DataTables_Table_0_paginate"
                      >
                        <Button
                          className="paginate_button previous me-2"
                          aria-controls="DataTables_Table_0"
                          data-dt-idx={0}
                          tabIndex={-1}
                          id="DataTables_Table_0_previous"
                          onClick={() => {
                            thresoldArray(currentPage - 1);
                          }}
                          disabled={currentPage === 0}
                        >
                          Previous
                        </Button>
                        <span>
                          <Button
                            className="paginate_button-current"
                            aria-controls="DataTables_Table_0"
                            data-dt-idx={1}
                            tabIndex={0}
                          >
                            {currentPage + 1}
                          </Button>
                        </span>
                        <Button
                          className="paginate_button next ms-2"
                          aria-controls="DataTables_Table_0"
                          data-dt-idx="2"
                          tabIndex={-1}
                          id="DataTables_Table_0_next"
                          onClick={() => {
                            thresoldArray(currentPage + 1);
                          }}
                          disabled={
                            currentPage === totalPages - 1 ||
                            filteredItems.length < 10
                          }
                        >
                          Next
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};
