import React, { useEffect, useState } from "react";
import "./Report.css";

import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DetailsModal } from "../../components/modal/showDetails/DetailsModal";
import SolveModal from "../../components/modal/solveReports/SolveModels";
import { DataTable } from "../../components/table/Table";
import { getAllReportApi } from "../../api/report/reportApi";
import { useNavigate } from "react-router-dom";

const columnDefinitions = [
  { label: "ID", key: "_id" },
  { label: "Title", key: "sTitle" },
  { label: "Message", key: "sMessage" },
  { label: "User Name", key: "sUserName" },
  { label: "Status", key: "sStatus" },
];

const Report: React.FC = () => {
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [reportData, setReportData] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [recordSize, setRecordSize] = useState(10);
  const navigate = useNavigate();

  const startIndex = currentPage * recordSize;
  const endIndex = startIndex + recordSize;

  const [showModal, setShowModal] = useState<string | false>(false);
  const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false);
  const [reportDetails, setReportDetails] = useState<{ [keys: string]: any }>(
    {}
  );
  const [showSolveModal, setShowSolveModal] = useState(false);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);

  async function loadData() {
    try {
      await getAllReportApi().then((res) => {
        let reports = res.data ?? [];
        if (reports) {
          reports = (reports as any[]).map((item: any) =>
            Object({
              ...item,
              sUserName:
                item.tIdUser && item.tIdUser.sName ? item.tIdUser.sName : "",
            })
          );
          reports = (reports as any[]).map((item: any) =>
            Object({
              ...item,
              sStatus: (
                <span className={item.bIsSolved ? "solved" : "pending"}>
                  {item.bIsSolved ? "Solved" : "Pending"}
                </span>
              ),
            })
          );
          setReportData(reports as any[]);
        }
      });
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  }

  const toggleSolveModal = () => {
    setShowSolveModal((prevShow) => !prevShow);
  };

  const paginateArray = (pageNumber: number) => {
    if (pageNumber >= 0 && pageNumber < totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    setCurrentPage(0);
  };

  const handleRecordSizeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setRecordSize(Number(e.target.value));
    setCurrentPage(0);
  };

  const filteredItems = reportData.filter((item) => {
    const searchTerm = search.trim().toLowerCase();

    const itemName = item.sUserName ? item.sUserName.toLowerCase() : "";

    return searchTerm === "" ? item : itemName.includes(searchTerm);
  });

  const totalFilteredItems = filteredItems.length;
  const totalPages = Math.ceil(filteredItems.length / recordSize);

  const paginatedItems = filteredItems.slice(
    currentPage * recordSize,
    (currentPage + 1) * recordSize
  );

  useEffect(() => {
    if (currentPage > 0 && paginatedItems.length === 0) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  }, [paginatedItems, currentPage]);

  const editReport = async (id: string) => {
    navigate(`/reports/edit/${id}`);
  };

  return (
    <>
      {showDetailsModal && (
        <DetailsModal
          title={"Report Details"}
          orderInfo={reportDetails}
          setShowDetailsModal={setShowDetailsModal}
          isConsignment={false}
        />
      )}
      <SolveModal show={showSolveModal} onClose={toggleSolveModal} />

      <Container fluid style={{ opacity: showModal ? "0.4" : "1" }}>
        <div className="user-content-header py-4">
          <ToastContainer />
          <Row className="mb-2">
            <Col sm="6">
              <h1 className="m-0 text-dark">Reports</h1>
            </Col>
            <Col sm="6">
              <ol className="breadcrumb float-sm-right justify-content-end">
                <li className="breadcrumb-item">
                  <a href="/dashboard">Home</a>
                </li>
                <li className="breadcrumb-item active">Reports</li>
              </ol>
            </Col>
          </Row>
        </div>
        <div className="content">
          <Row>
            <Col lg="12">
              <div className="card" bg-color="white">
                <div className="card-body">
                  <div
                    id="DataTables_Table_0_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <div className="card-head">
                      <div
                        className="dataTables_length"
                        id="DataTables_Table_0_length"
                      >
                        <label>
                          Show{" "}
                          <select
                            name="DataTables_Table_0_length"
                            aria-controls="DataTables_Table_0"
                            className="table-input"
                            value={recordSize}
                            onChange={handleRecordSizeChange}
                          >
                            <option value="10">10</option>
                            <option value="15">15</option>
                            <option value="20">20</option>
                            <option value="100">100</option>
                          </select>{" "}
                          entries
                        </label>
                      </div>
                      <div
                        id="DataTables_Table_0_filter"
                        className="dataTables_filter"
                      >
                        <label
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          Search:
                          <input
                            type="search"
                            className="table-input"
                            placeholder="Search by User Name..."
                            aria-controls="DataTables_Table_0"
                            value={search}
                            onChange={handleSearch}
                            style={{ height: "2rem" }}
                          />
                        </label>
                      </div>
                    </div>
                    <div style={{ overflow: "auto" }}>
                      <DataTable
                        columnDefinitions={columnDefinitions}
                        paginatedItems={paginatedItems}
                        setShowModal={setShowModal}
                        solveFunction={editReport}
                        isLoading={isLoading}
                        tableFor="Report"
                      />
                    </div>
                    {isLoading ? (
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ height: "20vh" }}
                      >
                        <Spinner animation="border" variant="primary" />
                      </div>
                    ) : null}
                    <div className="d-flex justify-content-between align-items-center">
                      <div
                        className="dataTables_info"
                        id="DataTables_Table_0_info "
                        role="status"
                        aria-live="polite"
                      >
                        {totalFilteredItems > 0
                          ? `Showing ${
                              endIndex < totalFilteredItems
                                ? endIndex
                                : totalFilteredItems
                            } of ${totalFilteredItems} entries`
                          : ""}
                      </div>
                      <div
                        className="dataTables_paginate paging_simple_numbers"
                        id="DataTables_Table_0_paginate"
                      >
                        <Button
                          className="paginate_button previous me-2"
                          aria-controls="DataTables_Table_0"
                          data-dt-idx={0}
                          tabIndex={-1}
                          id="DataTables_Table_0_previous"
                          onClick={() => {
                            paginateArray(currentPage - 1);
                          }}
                          disabled={currentPage === 0}
                        >
                          Previous
                        </Button>
                        <span>
                          <Button
                            className="paginate_button-current"
                            aria-controls="DataTables_Table_0"
                            data-dt-idx={1}
                            tabIndex={0}
                          >
                            {currentPage + 1}
                          </Button>
                        </span>
                        <Button
                          className="paginate_button next ms-2"
                          aria-controls="DataTables_Table_0"
                          data-dt-idx="2"
                          tabIndex={-1}
                          id="DataTables_Table_0_next"
                          onClick={() => {
                            paginateArray(currentPage + 1);
                          }}
                          disabled={
                            currentPage === totalPages - 1 ||
                            filteredItems.length < 10
                          }
                        >
                          Next
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};

export default Report;
