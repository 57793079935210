
import { addSettingApi, getSettingApi } from "../../api/account/accountApi";
import { AccountDetails } from "../../interface/AccountInterface";

export const addSetting = (reqbody: any)=>{
    return new Promise ((resolve, reject)=>{
        addSettingApi(reqbody)
        .then((data)=>{
            resolve(data.data);
        })
        .catch((error)=>{
          reject(error)
        });
    });
};

export const getAllSetting = (reqbody: any): Promise<AccountDetails> => {
    return new Promise((resolve, reject) => {
        getSettingApi(reqbody)
            .then((data) => {
                resolve(data.data );
            })
            .catch((error) => {
                reject(error);
            });
    });
}