/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, ChangeEvent } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./AddOrder.css";
import { updateOrderItem } from "../../../services/orderTracker/orderTrackerService";

interface OrderDetails {
  sItemNo: string;
  sBilltoName: string;
  sBilltocity:string;
  sBilltoAddress: string;
  sGSTNo: string;
  aUnitPrice: number;
  aAmount: string;
  aTotalTax: number;
  aTCSAmount: number;
  aTotalInvAmount: number;
  aContactNo: number;
  sDocumentNo: string;
  aLineNo: number;
  sLocationCode: string;
  aQuantity: string;
  dPostingDate: Date;

}

export const AddTrip: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [details, setDetails] = useState<OrderDetails>({
    sItemNo: "",
    sBilltoName: "",
    sBilltocity:"",
    sBilltoAddress: "",
    sGSTNo: "",
    aUnitPrice: 0,
    aAmount: "",
    aTotalTax: 0,
    aTCSAmount: 0,
    aTotalInvAmount: 0,
    aContactNo: 0,
    sDocumentNo: "",
    aLineNo: 0,
    sLocationCode: "",
    aQuantity: "",
    dPostingDate: new Date()
  });
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;
  const { id: orderId } = useParams<{ id: string }>();
  const [isFormEdited, setIsFormEdited] = useState(false); // Initialize isFormEdited to false

  useEffect(() => {
    if (orderId) {
      fetchOrderData(orderId);
    } else {
      setIsLoading(false);
    }
  }, []);

  const inputhandler = async (
    e:
      | ChangeEvent<HTMLInputElement>
      | ChangeEvent<HTMLSelectElement>
      | ChangeEvent<HTMLTextAreaElement>
  ) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
    setIsFormEdited(true);
  };

  function isDataNotFilled() {
    return !(
      !isFormEdited ||
      !(
        details.sItemNo &&
        details.sBilltoAddress &&
        details.aQuantity &&
        details.aAmount
      )
    );
  }

  const fetchOrderData = (id: string) => {
    axios
      .get(apiUrl + `/admin/warehouse/order-item/${id}`)
      .then((res) => {
     
        
        const orderData = res.data.data;

        setDetails(orderData);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const handlesubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();

    const tripdata = {
      data: {
        sItemNo: details.sItemNo,
        sBilltoName:details.sBilltoName,
        sBilltoAddress: details.sBilltoAddress,
        sBilltocity:details.sBilltocity,
        sGSTNo:details.sGSTNo,
        aUnitPrice:details.aUnitPrice,
        aTotalTax:details.aTotalTax,
        aTCSAmount:details.aTCSAmount,
        aTotalInvAmount:details.aTotalInvAmount,
        aContactNo:details.aContactNo,
        sDocumentNo:details.sDocumentNo,
        aLineNo:details.aLineNo,
        sLocationCode:details.sLocationCode,
        dPostingDate:details.dPostingDate,
        aQuantity: details.aQuantity,
        aAmount: details.aAmount,

      }
    };

    if (orderId) {
      updateOrderItem(orderId, tripdata)
        .then((res) => {
          navigate("/order");
        })
        .catch((err) => {
          console.error(err);
          toast.error(err.response.data.message, {
            position: "top-center",
            theme: "colored"
          });
        });
    }
  };

  return (
    <>
      {isLoading ? (
        <div className="bg-loader">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : null}
      <div className="mx-2 ">
        <div className="user-content-header py-4">
          <ToastContainer />
          <Row className="mb-2">
            <Col sm="6">
              <h1 className="m-0 text-dark">Order</h1>
            </Col>

            <Col sm="6">
              <ol className="breadcrumb float-sm-right justify-content-end">
                <li className="breadcrumb-item">
                  <Link to="dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/order">Order</Link>
                </li>
                <li className="breadcrumb-item active">
                  {orderId ? "Edit" : "Create"}
                </li>
              </ol>
            </Col>
          </Row>
        </div>
        <Container fluid className="main-container bg-white pb-4">
          <Row className="title-row">
            <p>{orderId ? "Update Order Tracker" : "Add Order Tracker"}</p>
          </Row>

          <Row className="form-group">
            <Col sm={12} md={6} className="form-fields op">
              <p>Item No</p>
              <input
                name="sItemNo"
                required
                onChange={(e) => inputhandler(e)}
                placeholder="Enter Item No"
                className="new-page-input"
                type="text"
                value={details.sItemNo}
                disabled={!!orderId}
              />
            </Col>

            <Col sm={12} md={6} className="form-fields op">
              <p>Billing Address</p>

              <input
                name="sBilltoAddress"
                required
                onChange={(e) => inputhandler(e)}
                placeholder="Enter Lot No"
                className="new-page-input"
                type="text"
                value={details.sBilltoAddress}
              />
            </Col>
          </Row>
          <Row className="form-group">
            <Col sm={12} md={6} className="form-fields op">
              <p>Quantity</p>

              <input
                name="aQuantity"
                required
                onChange={(e) => inputhandler(e)}
                placeholder="Enter Quantity"
                className="new-page-input"
                type="text"
                value={details.aQuantity || ""} 
              />
            </Col>

            <Col sm={12} md={6} className="form-fields op">
              <p>Amount</p>

              <input
                name="aAmount"
                onChange={(e) => inputhandler(e)}
                placeholder="Enter Amount"
                className="new-page-input"
                type="text"
                value={details.aAmount}
              />
            </Col>
          </Row>
        </Container>
        <div className="card-footer text-center mb-3">
          <button
            className="btn ripple btn-primary btnDataSave"
            name="btnDataSave"
            disabled={!isFormEdited || isDataNotFilled()} 
            onClick={handlesubmit}
          >
            {" "}
            {orderId ? "Update Order Tracker" : "Add Order Tracker"}{" "}
          </button>
        </div>
      </div>
    </>
  );
};
