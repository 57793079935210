import React, { useState, useEffect } from 'react';
import './Table.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faEdit,
    faTrash,
    faCircleCheck,
    faCircleXmark,
    faFileInvoice,
    faMapMarkerAlt,
    faDownload,
    faKey,
    faCodeMerge,
} from '@fortawesome/free-solid-svg-icons';
import { Button, Dropdown, Spinner, Table } from 'react-bootstrap';
import 'react-toastify/dist/ReactToastify.css';
import DataTableProps from '../../interface/DataTableInterface';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { setOrders } from '../../redux/order/orderSlice';
import ChildTable from './CollapsableTable';

export const DataTable: React.FC<DataTableProps> = (props) => {
    const navigate = useNavigate();
    const {
        columnDefinitions,
        paginatedItems,
        trackerColumn,
        isLoading = false,
        downloadActive = false,
        setSelectedDocument,
        selectedDocument,
    } = props;

    const [selectedRows, setSelectedRows] = useState<Record<string, boolean>>({});
    const [headerCheckBox, setHeaderCheckBox] = useState<boolean>(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const [consignmentId, setConsignmentId] = useState(null);
    const location = useLocation();
    const handleToggle = (id: any) => {
        if (consignmentId && isExpanded) {
            setConsignmentId(id);
        } else {
            setIsExpanded(!isExpanded);
        }

        if (consignmentId == id) setIsExpanded(!isExpanded);
        setConsignmentId(id);
    };
    useEffect(() => {
        const trueCount = Object.values(selectedRows).filter((value) => value).length;
        const itemCount = paginatedItems.filter((item) => !item.isOrdered).length;
        if (trueCount === itemCount && trueCount > 0) {
            setHeaderCheckBox(true);
        } else {
            setHeaderCheckBox(false);
        }
    }, [headerCheckBox, selectedDocument]);

    const handleRowCheckboxChange = (rowIndex: number) => {
        setSelectedRows((prevSelectedRows) => {
            const updatedRows = {
                ...prevSelectedRows,
                [rowIndex]: !prevSelectedRows[rowIndex],
            };
            updateSelectedDocument(updatedRows);
            return updatedRows;
        });
    };

    const handleHeaderCheckboxChange = (event: any) => {
        setHeaderCheckBox(true);
        const newSelectedRows: Record<string, boolean> = {};
        if (event.target.checked) {
            paginatedItems.forEach((item, index) => {
                if (!item['isOrdered']) {
                    newSelectedRows[item.Document_No] = true;
                }
            });
            setSelectedRows(newSelectedRows);
        } else {
            setSelectedRows(newSelectedRows);
        }
        updateSelectedDocument(newSelectedRows);
    };

    const updateSelectedDocument = (selectedRows: Record<string, boolean>) => {
        const selectedDocumentNo = Object.entries(selectedRows)
            .filter(([index, isSelected]) => isSelected)
            .map(([index]: any) => [...paginatedItems, ...selectedDocument].find((data) => data.Document_No == index))
            .filter(Boolean);

        setSelectedDocument(selectedDocumentNo);
    };

    return (
        <div className='table'>
            <Table bordered className='datatable table-striped-blue dataTable no-footer mt-3'>
                <thead className='bg-thead'>
                    <tr role='row'>
                        {columnDefinitions.map((column: any, index: any) => (
                            <th
                                key={index}
                                className='sorting_asc'
                                tabIndex={0}
                                aria-controls='DataTables_Table_0'
                                rowSpan={1}
                                colSpan={1}
                                aria-sort='ascending'
                                aria-label=': activate to sort column descending'
                                style={
                                    column.label === 'Checkbox' &&
                                    (props.tableFor === 'Order' || props.tableFor === 'Warehouse Transfer')
                                        ? { cursor: 'pointer', textAlign: 'center' }
                                        : {}
                                }
                            >
                                {column.label === 'Checkbox' &&
                                (props.tableFor === 'Order' || props.tableFor === 'Warehouse Transfer') ? (
                                    <input
                                        name='sDocumentNo'
                                        type='checkbox'
                                        onChange={handleHeaderCheckboxChange}
                                        checked={headerCheckBox}
                                        style={{ cursor: 'pointer' }}
                                    ></input>
                                ) : (
                                    column.label
                                )}
                            </th>
                        ))}
                        {(props.handleInvoiceDetails ||
                            props.deleteFunction ||
                            props.editFunction ||
                            props.solveFunction) && <th>Action</th>}
                        {downloadActive ? <th>Files</th> : null}
                    </tr>
                </thead>
                <tbody className='bg-tbody'>
                    {paginatedItems.length === 0 ? (
                        isLoading ? null : (
                            <tr>
                                <td colSpan={columnDefinitions.length + 1} style={{ textAlign: 'center' }}>
                                    No records found
                                </td>
                            </tr>
                        )
                    ) : (
                        paginatedItems.map((item: any, index: any) => {
                            const rowIndex = index + 1;
                            return (
                                <>
                                    <tr
                                        role='row'
                                        className='odd'
                                        key={rowIndex}
                                        onClick={() => handleToggle(item._id)}
                                        style={{
                                            border:
                                                consignmentId == item._id && props.hideActions
                                                    ? '2px solid #0e76bc'
                                                    : 'none',
                                        }}
                                    >
                                        <td
                                            style={
                                                props.tableFor === 'Order' || props.tableFor === 'Warehouse Transfer'
                                                    ? { cursor: 'pointer', textAlign: 'center' }
                                                    : {}
                                            }
                                        >
                                            {props.tableFor === 'Order' || props.tableFor === 'Warehouse Transfer' ? (
                                                <input
                                                    name='sDocumentNo'
                                                    type='checkbox'
                                                    checked={selectedRows[item.Document_No] ?? false}
                                                    value='sDocumentNo'
                                                    onChange={(e) => handleRowCheckboxChange(item.Document_No)}
                                                    style={{ cursor: 'pointer' }}
                                                    disabled={item['isOrdered']}
                                                ></input>
                                            ) : (
                                                rowIndex
                                            )}
                                        </td>
                                        {columnDefinitions.slice(1).map((column: any, columnIndex: any) => (
                                            <td
                                                key={columnIndex}
                                                style={
                                                    column.key === trackerColumn
                                                        ? {
                                                              display: 'flex',
                                                              justifyContent: 'space-between',
                                                          }
                                                        : {}
                                                }
                                            >
                                                {columnIndex === 0 ? (
                                                    <span
                                                        style={{
                                                            cursor: 'pointer',
                                                            color:
                                                                (props.tableFor === 'Group' ||
                                                                    props.tableFor === 'Role' ||
                                                                    props.tableFor === 'Thresold' ||
                                                                    props.tableFor === 'Report' ||
                                                                    props.tableFor === 'Order') &&
                                                                columnIndex === 0
                                                                    ? 'black'
                                                                    : 'black',
                                                        }}
                                                        // onClick={() =>
                                                        //     props.handleShowDetails && props.handleShowDetails(item)
                                                        // }
                                                    >
                                                        {column.key == 'Document_No' && item['isOrdered'] ? '✅' : ''}
                                                        {item[column.key]}
                                                    </span>
                                                ) : column.key in item ? (
                                                    column.key === trackerColumn &&
                                                    item?.tConsignments.find(
                                                        (item: any) => !(item.eStatus == '4' || item.eStatus == '2')
                                                    ) ? (
                                                        <>
                                                            <span>{item[column.key]}</span>
                                                            {!props.hideActions ? (
                                                                <Button
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        if (props.deleteGroupFunction)
                                                                            props.deleteGroupFunction(item);
                                                                    }}
                                                                    variant='danger'
                                                                    size='sm'
                                                                    className='deleteData ms-2'
                                                                >
                                                                    <FontAwesomeIcon icon={faTrash} color='white' />{' '}
                                                                    {props.isDelLoading == item.grpConsignmentID ? (
                                                                        <Spinner
                                                                            animation='border'
                                                                            size='sm'
                                                                            className='me-1'
                                                                        />
                                                                    ) : (
                                                                        'Delete'
                                                                    )}
                                                                </Button>
                                                            ) : (
                                                                ''
                                                            )}
                                                            <Button
                                                                variant='warning'
                                                                size='sm'
                                                                style={{ height: '30px', color: 'white' }}
                                                                className=' d-flex align-items-center activeData-tracker'
                                                                onClick={() =>
                                                                    props.handleMerging && props.handleMerging(item)
                                                                }
                                                            >
                                                                {props.mergeId !== item._id ? (
                                                                    <>
                                                                        <FontAwesomeIcon
                                                                            icon={faCodeMerge}
                                                                            color='white'
                                                                        />
                                                                        <span>&nbsp; Merge</span>
                                                                    </>
                                                                ) : (
                                                                    <Spinner />
                                                                )}
                                                            </Button>
                                                            {!props.hideActions ? (
                                                                <Button
                                                                    variant='success'
                                                                    size='sm'
                                                                    style={{ height: '30px' }}
                                                                    className=' d-flex align-items-center activeData-tracker'
                                                                    onClick={() =>
                                                                        props.handleTracking &&
                                                                        props.handleTracking(item)
                                                                    }
                                                                >
                                                                    <FontAwesomeIcon
                                                                        icon={faMapMarkerAlt}
                                                                        color='white'
                                                                    />
                                                                    <span>&nbsp; Track</span>
                                                                </Button>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </>
                                                    ) : column.key === trackerColumn &&
                                                      item?.tConsignments.find(
                                                          (item: any) => item.eStatus == '4' || item.eStatus == '2'
                                                      ) ? (
                                                        <>
                                                            {' '}
                                                            Delivered
                                                            <Button
                                                                variant='warning'
                                                                size='sm'
                                                                style={{ height: '30px' }}
                                                                onClick={(e) =>{
                                                                    e.stopPropagation();
                                                                    props.handleTripHistory &&props.handleTripHistory(item)
                                                                }}
                                                            >
                                                                Trip History
                                                            </Button>
                                                        </>
                                                    ) : (
                                                        item[column.key]
                                                    )
                                                ) : (
                                                    ''
                                                )}
                                            </td>
                                        ))}
                                        {props.editFunction ||
                                        props.deleteFunction ||
                                        props.handleInvoiceDetails ||
                                        props.solveFunction ? (
                                            <td>
                                                {props.solveFunction && (
                                                    <Button
                                                        onClick={() =>
                                                            props.solveFunction ? props.solveFunction(item._id) : null
                                                        }
                                                        variant='success'
                                                        size='sm'
                                                        className='editData'
                                                        style={{ height: '2rem', width: '4rem' }}
                                                    >
                                                        Resolve
                                                    </Button>
                                                )}
                                                {props.editFunction && (
                                                    <Button
                                                        onClick={() =>
                                                            props.editFunction ? props.editFunction(item._id) : null
                                                        }
                                                        variant='success'
                                                        size='sm'
                                                        className='editData'
                                                        style={{ height: '2rem', width: '4rem' }}
                                                    >
                                                        <FontAwesomeIcon icon={faEdit} color='white' /> Edit
                                                    </Button>
                                                )}
                                                {props.deleteFunction && (
                                                    <Button
                                                        onClick={() =>
                                                            props.deleteFunction ? props.deleteFunction(item._id) : null
                                                        }
                                                        variant='danger'
                                                        size='sm'
                                                        className='deleteData ms-2'
                                                    >
                                                        <FontAwesomeIcon icon={faTrash} color='white' /> Delete
                                                    </Button>
                                                )}
                                                {props.deleteGroupFunction && (
                                                    <Button
                                                        onClick={() =>
                                                            props.deleteGroupFunction
                                                                ? props.deleteGroupFunction(item)
                                                                : null
                                                        }
                                                        variant='danger'
                                                        size='sm'
                                                        className='deleteData ms-2'
                                                    >
                                                        <FontAwesomeIcon icon={faTrash} color='white' /> Delete
                                                    </Button>
                                                )}
                                                {props.handleInvoiceDetails && (
                                                    <Button
                                                        variant='info'
                                                        size='sm'
                                                        className='activeData ms-1'
                                                        onClick={() =>
                                                            props.handleInvoiceDetails
                                                                ? props.handleInvoiceDetails(item)
                                                                : null
                                                        }
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={faFileInvoice}
                                                            color='white'
                                                            style={{ marginRight: '5px' }}
                                                        />
                                                        <span style={{ color: 'white' }}>Invoice</span>
                                                    </Button>
                                                )}
                                                {props.updateStatus && (
                                                    <Button
                                                        variant={item.bIsActive ? 'primary' : 'secondary'}
                                                        size='sm'
                                                        className='activeData ms-1'
                                                        onClick={() =>
                                                            props.updateStatus &&
                                                            props.updateStatus(item, item.bIsActive)
                                                        }
                                                        disabled={props.btnActiveId === item._id}
                                                    >
                                                        {props.btnActiveId === item._id ? (
                                                            <Spinner animation='border' size='sm' className='me-1' />
                                                        ) : item.bIsActive ? (
                                                            <FontAwesomeIcon
                                                                icon={faCircleCheck}
                                                                color='white'
                                                                className='icon'
                                                            />
                                                        ) : (
                                                            <FontAwesomeIcon
                                                                icon={faCircleXmark}
                                                                color='white'
                                                                className='icon'
                                                            />
                                                        )}

                                                        {!props.btnActiveId &&
                                                            (item.bIsActive ? (
                                                                <span>Active</span>
                                                            ) : (
                                                                <span>Inactive</span>
                                                            ))}
                                                    </Button>
                                                )}
                                                {props.changePassword && (
                                                    <Button
                                                        variant='info'
                                                        size='sm'
                                                        className='activeData ms-1'
                                                        onClick={() =>
                                                            props.changePassword && props.changePassword(item)
                                                        }
                                                    >
                                                        <FontAwesomeIcon icon={faKey} color='white' className='icon' />
                                                        <span className='txt-white'>Password</span>
                                                    </Button>
                                                )}
                                                {props.approvedStatus && (
                                                    <Button
                                                        variant={item.bIsApproved ? 'success' : 'secondary'}
                                                        size='sm'
                                                        className='activeData ms-1'
                                                        onClick={() =>
                                                            props.approvedStatus &&
                                                            props.approvedStatus(item, item.bIsApproved)
                                                        }
                                                        disabled={props.btnApprovedId === item._id}
                                                    >
                                                        {props.btnApprovedId === item._id ? (
                                                            <Spinner animation='border' size='sm' className='me-1' />
                                                        ) : item.bIsApproved ? (
                                                            <FontAwesomeIcon
                                                                icon={faCircleCheck}
                                                                color='white'
                                                                className='icon'
                                                            />
                                                        ) : (
                                                            <FontAwesomeIcon
                                                                icon={faCircleXmark}
                                                                color='white'
                                                                className='icon'
                                                            />
                                                        )}

                                                        {!props.btnApprovedId &&
                                                            (item.bIsApproved ? (
                                                                <span>Approved</span>
                                                            ) : (
                                                                <span>Not Approved</span>
                                                            ))}
                                                    </Button>
                                                )}
                                            </td>
                                        ) : null}
                                        {downloadActive ? (
                                            <td>
                                                {item.sIdProof || item.sDrivingLicense || item.sAddressProof ? (
                                                    <Dropdown>
                                                        <Dropdown.Toggle
                                                            variant='success'
                                                            id='dropdown-basic'
                                                            size='sm'
                                                        >
                                                            <FontAwesomeIcon icon={faDownload} />
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu>
                                                            {item.sIdProof ? (
                                                                <Dropdown.Item
                                                                    download
                                                                    target='_blank'
                                                                    rel='noopener noreferrer'
                                                                    href={item.sIdProof}
                                                                >
                                                                    Id Proof
                                                                </Dropdown.Item>
                                                            ) : null}
                                                            {item.sDrivingLicense ? (
                                                                <Dropdown.Item
                                                                    download
                                                                    target='_blank'
                                                                    rel='noopener noreferrer'
                                                                    href={item.sDrivingLicense}
                                                                >
                                                                    Driving License
                                                                </Dropdown.Item>
                                                            ) : null}
                                                            {item.sAddressProof ? (
                                                                <Dropdown.Item
                                                                    download
                                                                    target='_blank'
                                                                    rel='noopener noreferrer'
                                                                    href={item.sAddressProof}
                                                                >
                                                                    Address Proof
                                                                </Dropdown.Item>
                                                            ) : null}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                ) : null}
                                            </td>
                                        ) : null}
                                    </tr>

                                    {location.pathname.includes('consignments') ? (
                                        <>
                                            {' '}
                                            {consignmentId == item._id && (
                                                <>
                                                    {' '}
                                                    {isExpanded && (
                                                        <tr role='row'>
                                                            <td colSpan={12}>
                                                                {/* Additional content goes here */}
                                                                <ChildTable
                                                                    handleShowDetails={props.handleShowDetails}
                                                                    children={item.tConsignments}
                                                                    handleUploadPOD={props.handlePodUpload}
                                                                    // columnIndex={columnIndex}
                                                                />
                                                            </td>
                                                        </tr>
                                                    )}
                                                </>
                                            )}
                                        </>
                                    ) : (
                                        ''
                                    )}
                                </>
                            );
                        })
                    )}
                </tbody>
            </Table>
        </div>
    );
};
