import { useEffect, useState } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import carIcon from '../../assets/images/icons8-truck-top-view-30.png';
import locationIcon from '../../assets/images/icons8-location-100.png';
import { DirectionsRenderer } from '@react-google-maps/api'; // Import DirectionsService and DirectionsRenderer
import axios from 'axios';
import { getAllConsignments } from '../../services/consignment/consignmentService';
import { getUserLatestLocation } from '../../api/tracking-external/userLatestLatLong';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Socket, io } from 'socket.io-client';
import { baseUrl, socketUrl } from '../../api';
import { Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const Map = (props: any) => {
    const [markerPosition, setMarkerPosition] = useState({ lat: 0, lng: 0 });
    const [icon, setIcon] = useState<any>(undefined);
    const [destinationIcon, setDestinationIcon] = useState({});
    const [directions, setDirections] = useState(null); // State to store directions
    const [deliveryLatLong, setDeliveryLatLong] = useState({
        lat: 0,
        lng: 0,
    });
    const [deliveryLatLongList, setDeliveryLatLongList] = useState([]);
    const [rotationAngle, setRotationAngle] = useState(60);
    const [isVehicle, setIsVehicle] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [driverID, setDriverID] = useState('');
    const [socket, setSocket] = useState<Socket | null>(null);
    const [driverLoc, setDriverLoc] = useState({
        _id: '',
        sGeoLat: 0,
        sGeoLog: 0,
    });

    const navigate = useNavigate();

    const yourInitializationFunction = () => {
        setIcon({
            url: carIcon,
            scaledSize: new window.google.maps.Size(50, 40),
            anchor: new window.google.maps.Point(20, 20),
            rotation: rotationAngle, // Set the initial rotation here
            scale: 0.7,
        });
        setDestinationIcon({
            url: locationIcon,
            scaledSize: new window.google.maps.Size(50, 50),
            anchor: new window.google.maps.Point(20, 20),
            rotation: rotationAngle, // Set the initial rotation here
            scale: 0.7,
        });
    };
    useEffect(() => {
        // eslint-disable-next-line

        const newSocket = io(socketUrl + '');
        setSocket(newSocket);

        // Clean up the socket connection on component unmount
        return () => {
            newSocket.disconnect();
        };
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        if (socket) {
            // Listen for 'chat message' events from the server
            socket?.on('driver:UpdateLocation', (loc) => {
                setDriverLoc(loc);
            });
        }
        // eslint-disable-next-line
    }, [socket]);

    const getUserLocation = async () => {
        getLatLong();

        setInterval(async () => {
            getLatLong();
        }, 180000);
    };
    const getLatLong = async () => {
        try {
            if (window.location.pathname == '/tracking') {
                let regNo = window.location.search.replace(/_/g, ' ').replace(/^\?/, '');

                let jsonData = JSON.parse(sessionStorage.getItem('vahicleApi') || '{}');
                let vehicleData = {
                    ...jsonData,
                    sApi: jsonData.sUrl,
                    sRegNo: regNo,
                };
                let userLocations = await getUserLatestLocation(vehicleData);
                props.setVahicalInfo(userLocations);
                setMarkerPosition({
                    lat: +userLocations.aLatitude,
                    lng: +userLocations.aLongitude,
                });
                setRotationAngle(+userLocations.aAngle);
            }
        } catch (err) {
            console.error(err);
        }
    };

    function calculateDistance(
        latLng1: { latitude: number; longitude: number },
        latLng2: { latitude: number; longitude: number }
    ) {
        // Convert latitude and longitude from degrees to radians
        const lat1 = latLng1.latitude * (Math.PI / 180);
        const lon1 = latLng1.longitude * (Math.PI / 180);
        const lat2 = latLng2.latitude * (Math.PI / 180);
        const lon2 = latLng2.longitude * (Math.PI / 180);

        // Haversine formula
        const dLat = lat2 - lat1;
        const dLon = lon2 - lon1;
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(lat1) * Math.cos(lat2) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

        // Earth radius in kilometers
        const EARTH_RADIUS = 6371;

        // Calculate the distance
        const distance = EARTH_RADIUS * c;

        return distance;
    }

    function sortLocationArr(
        latLngs: { latitude: number; longitude: number }[],
        primaryLatlng: { latitude: number; longitude: number }
    ) {
        const newLatLangList = [];
        const predefineLatLangList = [...latLngs];
        const length = predefineLatLangList.length;

        for (let i = 0; i < length; i++) {
            const finalPrimaryLatlng = { ...primaryLatlng };

            predefineLatLangList.sort((o1, o2) => {
                return calculateDistance(o1, finalPrimaryLatlng) - calculateDistance(o2, finalPrimaryLatlng);
            });

            primaryLatlng = { ...predefineLatLangList[0] };
            newLatLangList.push(primaryLatlng);
            predefineLatLangList.shift();
        }

        return newLatLangList;
    }

    async function getCoordinates(address: string) {
        try {
            const response = await axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
                params: {
                    address,
                    key: 'AIzaSyA7NEUwZtj8wF1cv3PjOQJca_yKo4CQPew',
                },
            });

            const { results } = response.data;

            if (results.length > 0) {
                const { lat, lng } = results[0].geometry.location;
                return { latitude: lat, longitude: lng };
            } else {
                throw new Error('No results found for the given address.');
            }
        } catch (error: any) {
            console.error('Error getting coordinates:', error.message);
            throw error;
        }
    }

    async function getCoordinatesArray(addresses: string[]) {
        const coordinatesArray = [];

        for (const address of addresses) {
            try {
                const coordinates = await getCoordinates(address);
                coordinatesArray.push(coordinates);
            } catch (error) {
                // Handle error or continue with the next address
            }
        }

        return coordinatesArray;
    }

    const getLatLongFromAddress = async (marker: { lat: number; lng: number }) => {
        let groupConsignments = await getAllConsignments();

        let consignmentId = sessionStorage.getItem('id');
        let currentTrackableObject = (groupConsignments as any[]).find((item) => {
            if (item._id === consignmentId) return item;
        });
        let consignments = currentTrackableObject.tConsignments;
        props.bringDriverData(currentTrackableObject);
        setDriverID(currentTrackableObject?.tAssignDriver ? currentTrackableObject.tAssignDriver._id : '');

        let addressList: string[] = [];
        currentTrackableObject?.tConsignments.map(async (item: any, index: any) => {
            let address = item.tItems[0]?.sBilltoAddress + ',' + item?.tItems[0]?.sBilltocity;

            addressList.push(address);
        });
        let latLongList = await getCoordinatesArray(addressList);
        let latLongListSorted = sortLocationArr(latLongList, { latitude: marker.lat, longitude: marker.lng });

        setDeliveryLatLong({
            lat: latLongListSorted[latLongListSorted.length - 1].latitude,
            lng: latLongListSorted[latLongListSorted.length - 1].longitude,
        });

        try {
            let formatedData = latLongListSorted.splice(0, latLongListSorted.length - 1).map((data) =>
                Object({
                    location: data.latitude + ',' + data.longitude,
                    stopover: true,
                })
            );
            setDeliveryLatLongList(formatedData as any);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (markerPosition.lat !== 0 && markerPosition.lng !== 0 && deliveryLatLong.lat && deliveryLatLong.lng) {
            getDirections();
        }
    }, [markerPosition, deliveryLatLong]);

    useEffect(() => {
        getUserLocation();
    }, []);
    useEffect(() => {
        if (markerPosition.lat !== 0 && markerPosition.lng !== 0) {
            getLatLongFromAddress(markerPosition);
        }
    }, [markerPosition]);

    useEffect(() => {
        if (window.location.search == '?undefined') {
            setIsVehicle(true);
            getDriverLocation();
        }
    }, [driverID]);

    const toggleVehicleDriver = () => {
        setIsLoading(true);
        setIsVehicle(!isVehicle);
        if (!isVehicle) {
            getDriverLocation();
            // setMarkerPosition({
            //     lat: driverLoc.sGeoLat,
            //     lng: driverLoc.sGeoLog,
            // });
            setIsLoading(false);
        }
        if (isVehicle) {
            getLatLong();
            setIsLoading(false);
        }
    };
    const getDriverLocation = async () => {
        try {
            const response = await axios.get(`${baseUrl}/shared/ongoing-consignment/filter?tDriver=${driverID}`);
            if (response) {
                // setDriverLoc({
                //     _id: driverLoc._id,
                //     sGeoLat: response.data.data.sGeoLat,
                //     sGeoLog: response.data.data.sGeoLog,
                // });

                setMarkerPosition({
                    lat: response.data.data.sGeoLat,
                    lng: response.data.data.sGeoLog,
                });
                setIsLoading(false);
            }
        } catch (err) {
            console.error(err);
        }
    };

    const getDirections = () => {
        try {
            const directionsService = new window.google.maps.DirectionsService();

            directionsService.route(
                {
                    origin: new window.google.maps.LatLng(markerPosition.lat, markerPosition.lng),
                    destination: new window.google.maps.LatLng(+deliveryLatLong.lat, +deliveryLatLong.lng),
                    waypoints: deliveryLatLongList,
                    travelMode: google.maps.TravelMode.DRIVING,
                },
                (result, status) => {
                    if (status === 'OK') {
                        setDirections(result as any); // Set the directions in state
                    } else {
                        console.error(`Directions request failed due to ${status}`);
                    }
                }
            );
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <>
            <LoadScript
                googleMapsApiKey='AIzaSyA7NEUwZtj8wF1cv3PjOQJca_yKo4CQPew'
                onLoad={yourInitializationFunction}
                libraries={['geometry']}
            >
                <GoogleMap
                    mapContainerStyle={{ width: '70%', height: '92.6vh' }}
                    center={markerPosition}
                    options={{ streetViewControl: false, minZoom: 2, maxZoom: 17 }}
                >
                    {directions && (
                        <DirectionsRenderer
                            directions={directions}
                            options={{
                                polylineOptions: {
                                    strokeColor: 'blue',
                                    strokeOpacity: 1.0,
                                    strokeWeight: 4,
                                },
                            }}
                        />
                    )}

                    {/* {!isVehicle ? ( */}
                    <Marker icon={!isVehicle ? icon : undefined} position={markerPosition} />
                    {/* ) : ( */}
                    {/* // <Marker position={deliveryLatLong} icon={driverIcon} /> */}
                    {/* '' */}
                    {/* )} */}
                </GoogleMap>
            </LoadScript>
            {window.location.search !== '?undefined' ? (
                <div style={{ position: 'absolute', right: '25.8%', bottom: '18%' }}>
                    <button style={{ border: 'none', width: '2.6rem', height: '2.6rem' }} onClick={toggleVehicleDriver}>
                        {isLoading ? (
                            <Spinner animation='border' variant='primary' />
                        ) : isVehicle ? (
                            <FontAwesomeIcon className='icon' icon='car' />
                        ) : (
                            <FontAwesomeIcon className='icon' icon='user' />
                        )}
                    </button>
                </div>
            ) : (
                ''
            )}
        </>
    );
};

export default Map;
