/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, ChangeEvent, useEffect } from 'react';
import './AddVehicle.css';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { addVehicle, updateVehicle } from '../../../services/vehicle/vehicleService';
import axios from 'axios';
import { IWareHouse } from 'logistic-packages';
import { getAllWarehouses } from '../../../services/warehouse/warehouseService';
import { getAllAvailableVendor } from '../../../services/account/availableVendorService';

interface VehicleDetails {
    sType: string;
    sRegNo: string;
    sModel: string;
    sCompany: string;
    sDescription: string;
    aCapacity: number;
    tWareHouse: string;
    tVahicleApi: string;
}

export const AddVehicle: React.FC = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [warehouseList, setWarehouseList] = useState<IWareHouse[]>([]);
    const [vendorList, setVendorList] = useState([]);

    const [details, setDetails] = useState<VehicleDetails>({
        sRegNo: '',
        sModel: '',
        sCompany: '',
        sType: '',
        aCapacity: 0,
        sDescription: '',
        tWareHouse: '',
        tVahicleApi: '',
    });
    // const apiUrl = process.env.REACT_APP_LOCAL_API_URL as string;

    const apiUrl = process.env.REACT_APP_API_URL as string;
    const [isFormEdited, setIsFormEdited] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    useEffect(() => {
        loadInitData();
        if (vehicleId) {
            fetchVehicleData(vehicleId);
        } else {
            setIsLoading(false);
        }
    }, []);

    async function loadInitData() {
        try {
            await getAllWarehouses().then((warehouse) => {
                // let warehouse = res.data ??[];
                // if(warehouse){
                //     warehouse = (warehouse as IWareHouse[]).map((item: any)=>
                //     Object({
                //         ...item, sName: item.tWareHouse.sName && item.tWareHouse.sName ? item.tWareHouse.sName :""
                //     }))
                // }
                setWarehouseList(warehouse as IWareHouse[]);
            });
            const vendors = await getAllAvailableVendor();
            if (vendors) setVendorList(vendors);
        } catch (err: any) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }

    const navigate = useNavigate();

    const { id: vehicleId } = useParams<{ id: string }>();

    const inputhandler = async (
        e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement> | ChangeEvent<HTMLTextAreaElement>
    ) => {
        setDetails({ ...details, [e.target.name]: e.target.value });
        setIsFormEdited(true);
    };

    function isDataNotFilled() {
        return (
            !isFormEdited ||
            !(
                (
                    details.sRegNo &&
                    details.sModel &&
                    details.sCompany &&
                    details.sType &&
                    details.aCapacity &&
                    details.sDescription
                )
                // details.sName
            )
        );
    }

    const fetchVehicleData = (id: string) => {
        axios
            .get(apiUrl + `/admin/driver/vehicle/${id}`)
            .then((res) => {
                setDetails({
                    ...res.data.data,
                    tWareHouse: res.data.data.tWareHouse
                        ? res.data.data.tWareHouse._id
                            ? res.data.data.tWareHouse._id
                            : res.data.data.tWareHouse
                        : '',
                    tVahicleApi: res.data.data.tVahicleApi
                        ? res.data.data.tVahicleApi._id
                            ? res.data.data.tVahicleApi._id
                            : res.data.data.tVahicleApi
                        : '',
                });
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
            });
    };

    const handleSubmit = async (e: { preventDefault: () => void }) => {
        e.preventDefault();
        setIsSubmitting(true);
        const vehicleData = {
            data: {
                sRegNo: details.sRegNo,
                sModel: details.sModel,
                sCompany: details.sCompany,
                sType: details.sType,
                aCapacity: details.aCapacity,
                sDescription: details.sDescription,
                tWareHouse: details.tWareHouse,
                tVahicleApi: details.tVahicleApi,
            },
        };

        if (vehicleId) {
            updateVehicle(vehicleId, vehicleData)
                .then((res) => {
                    toast.success('Vehicle Updated Successfully', {
                        position: 'top-center',
                        theme: 'colored',
                    });
                    setTimeout(() => {
                        navigate('/vehicles');
                    }, 1000);
                })
                .catch((err) => {
                    toast.error(err.response.data.message, {
                        position: 'top-center',
                        theme: 'colored',
                    });
                });
        } else {
            await addVehicle(vehicleData)
                .then((res) => {
                    toast.success('Vehicle Added Successfully', {
                        position: 'top-center',
                        theme: 'colored',
                    });
                    setTimeout(() => {
                        navigate('/vehicles');
                    }, 1000);
                })
                .catch((err) => {
                    console.error(err);
                    toast.error(err.response.data.message, {
                        position: 'top-center',
                        theme: 'colored',
                    });
                    setIsSubmitting(false);
                });
        }
    };

    return (
        <>
            {isLoading ? (
                <div className='bg-loader'>
                    <Spinner animation='border' variant='primary' />
                </div>
            ) : null}
            <div className='mx-2 '>
                <div className='user-content-header py-4'>
                    <ToastContainer />
                    <Row className='mb-2'>
                        <Col sm='6'>
                            <h1 className='m-0 text-dark'>Vehicles</h1>
                        </Col>

                        <Col sm='6'>
                            <ol className='breadcrumb float-sm-right justify-content-end'>
                                <li className='breadcrumb-item'>
                                    <Link to='/dashboard'>Home</Link>
                                </li>
                                <li className='breadcrumb-item'>
                                    <Link to='/vehicles'>Vehicles</Link>
                                </li>
                                <li className='breadcrumb-item active'>{vehicleId ? 'Edit' : 'Create'}</li>
                            </ol>
                        </Col>
                    </Row>
                </div>
                <Container fluid className='main-container bg-white'>
                    <Row className='title-row form-group'>{vehicleId ? <p>Edit vehicle</p> : <p>Add Vehicle</p>}</Row>

                    <Row className='form-group'>
                        <Col sm={12} md={6} className='form-fields op'>
                            <p>Vehicle Registration Number</p>
                            <input
                                name='sRegNo'
                                onChange={(e) => inputhandler(e)}
                                placeholder='Enter Vehicle Registration Number'
                                className='vehical-input-field'
                                type='text'
                                value={details.sRegNo}
                                disabled={!!vehicleId}
                            />
                        </Col>
                        <Col sm={12} md={6} className='form-fields op'>
                            <p>Select Warehouse Name</p>

                            <select
                                name='tWareHouse'
                                onChange={(e) => inputhandler(e)}
                                value={details.tWareHouse ? details.tWareHouse : ''}
                                className='vehical-input-field'
                            >
                                <option value='' disabled>
                                    Select Warehouse
                                </option>
                                {warehouseList.map((warehouse, index) => (
                                    <option key={index} value={warehouse._id}>
                                        {warehouse.sName}
                                    </option>
                                ))}
                            </select>
                        </Col>
                    </Row>
                    <Row className='form-group'>
                        <Col sm={12} md={6} className='form-fields op'>
                            <p>Vehicle Model</p>
                            <input
                                name='sModel'
                                onChange={(e) => inputhandler(e)}
                                placeholder='Enter Model Name'
                                className='vehical-input-field'
                                type='text'
                                value={details.sModel}
                            />
                        </Col>
                        <Col sm={12} md={6} className='form-fields op'>
                            <p>Vehicle Type</p>
                            <input
                                name='sType'
                                onChange={(e) => inputhandler(e)}
                                placeholder='Enter Vehicle Type'
                                className='vehical-input-field'
                                type='text'
                                value={details.sType}
                            />
                        </Col>
                    </Row>
                    <Row className='form-group'>
                        <Col sm={12} md={6} className='form-fields op'>
                            <p>Company Name</p>
                            <input
                                name='sCompany'
                                onChange={(e) => inputhandler(e)}
                                placeholder='Enter Company Name'
                                className='vehical-input-field'
                                type='text'
                                value={details.sCompany}
                            />
                        </Col>
                        <Col sm={12} md={6} className='form-fields op'>
                            <p>Capacity (ton)</p>
                            <input
                                name='aCapacity'
                                onChange={(e) => inputhandler(e)}
                                placeholder='Enter Capacity(unit)'
                                className='vehical-input-field'
                                type='number'
                                value={details.aCapacity}
                                onKeyDown={(e) => {
                                    const allowedChars = '0123456789';
                                    if (!allowedChars.includes(e.key) && e.key !== 'Backspace') {
                                        e.preventDefault();
                                    }
                                }}
                            />
                        </Col>
                    </Row>
                    <Row className='form-group justify-content-center'>
                        <Col sm={12} md={6} className='form-fields op'>
                            <p>Select Vehicle Api</p>
                            <select
                                id='apiUrl'
                                name='tVahicleApi'
                                value={details.tVahicleApi ?? ''}
                                onChange={(e) => inputhandler(e)}
                                required
                                disabled
                                className='settings-input-field'
                            >
                                {vendorList.map((value: any, index: any) => (
                                    <option key={index} value={value.sName}>
                                        {value.sTag}
                                    </option>
                                ))}
                            </select>
                        </Col>
                    </Row>
                    <Row className='form-group justify-content-center'>
                        <Col sm={12} md={12} className='form-fields mb-2 op'>
                            <p>Description</p>
                            <textarea
                                name='sDescription'
                                onChange={(e) => inputhandler(e)}
                                className='new-page-textarea-vcl'
                                cols={60}
                                rows={5}
                                value={details.sDescription}
                            ></textarea>
                        </Col>
                    </Row>
                    <Row className='form-group'></Row>
                </Container>
                <div className='card-footer text-center mb-3'>
                    <button
                        className='btn ripple btn-primary btnDataSave'
                        name='btnDataSave'
                        disabled={isDataNotFilled() || isSubmitting}
                        onClick={handleSubmit}
                    >
                        {' '}
                        {vehicleId ? 'Update Vehicle' : 'Add  Vehicles'}{' '}
                    </button>
                </div>
            </div>
        </>
    );
};
