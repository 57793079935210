import {
    addConsignmentApi,
    deleteConsignmentApi,
    deleteGroupConsignmentApi,
    getAllConsignmentsApi,
    getAllConsignmentsApiByParams,
    uploadConsignmentPODApi,
    uploadConsignmentWithPODDataApi,
    mergeConsignmentApi,
    getTripHistoryDataApi,
} from '../../api/consignments/consignmentApi';

export const getAllConsignments = () => {
    return new Promise((resolve, reject) => {
        getAllConsignmentsApi()
            .then((data) => {
                resolve(data.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
export const getAllConsignmentsTop100 = () => {
    return new Promise((resolve, reject) => {
        getAllConsignmentsApiByParams()
            .then((data) => {
                resolve(data.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
export const deleteConsignment = (id: string) => {
    return new Promise((resolve, reject) => {
        deleteConsignmentApi(id)
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
export const deleteGroupConsignment = (grpConsignmentID: string) => {
    return new Promise((resolve, reject) => {
        deleteGroupConsignmentApi(grpConsignmentID)
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
export const addConsignment = (reqbody: any) => {
    return new Promise((resolve, reject) => {
        addConsignmentApi(reqbody)
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
export const uploadConsignmentPOD = (formData: any) => {
    return new Promise((resolve, reject) => {
        uploadConsignmentPODApi(formData)
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
export const uploadConsignmentWithPODData = (consignmentID: string, reqBody: any) => {
    return new Promise((resolve, reject) => {
        uploadConsignmentWithPODDataApi(consignmentID, reqBody)
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
export const mergeConsignment = (reqBody: any) => {
    return new Promise((resolve, reject) => {
        mergeConsignmentApi(reqBody)
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
export const getTripHistoryData = (id: string) => {
    return new Promise((resolve, reject) => {
        getTripHistoryDataApi(id)
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
