import React, { useEffect, useState } from "react";
import {
  PieChart,
  Pie,
  Legend,
  Tooltip,
  Cell,
  ResponsiveContainer
} from "recharts";
import { getAllPieChart } from "../../services/piechart/pieChartService";
import { Spinner } from "react-bootstrap";

const PieChartComponent: React.FC = () => {
  const [pieGraphData, setPieGraphData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getGraphData();
  }, []);
  const getGraphData = async () => {
    try {
      let graphData = await getAllPieChart();
      const data = [
        { name: "Delivered", value: graphData.aDelivered+graphData.aForceDelivery, color: "#00897B" },
        { name: "In Progress", value: graphData.aINProgress, color: "#FFB300" },
        { name: "Ordered", value: graphData.aOrdered, color: "#C0CA33" },
        {
          name: "Out Of Delivery",
          value: graphData.aOutOfDelivery,
          color: "#43A047"
        }
      ];
      setPieGraphData(data);
    } catch (err) {
      console.error(err);
    }finally {
      setIsLoading(false);
  }
  }
    return (
      <>
        <div
          className="pie-chart mt-5"
          style={{ height: "396px", width: "100%" }}
        >
          {isLoading ? (
        <div
            className='d-flex justify-content-center align-items-center'
            style={{ height: '20vh' }}
        >
            <Spinner animation='border' variant='primary' />
        </div>
    ) : null}
          <ResponsiveContainer width="100%" height="80%">
            <PieChart width={500} height={250}>
              <Pie
                data={pieGraphData}
                cx="50%"
                cy="50%"
                outerRadius={80}
                label
                dataKey="value"
              >
                {pieGraphData.map((entry: any, index: any) => (
                  <Cell key={`cell-${index}`} fill={entry.color} style={{outline: 'none'}}/>
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        </div>
      </>
    );
  ;
};

export default PieChartComponent;
