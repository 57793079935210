// import { IReport } from 'logistic-packages';
import { api, baseUrl } from "../../api";

export const getAllReportApi = async () => {
  const result = await api.get(`${baseUrl}/admin/report`);
  return result.data;
};

export const updateReportApi = async (id: string, reportData: any) => {
  const result = await api.patch(`${baseUrl}/admin/report/${id}`, reportData);
  return result.data;
};

export const deleteReportApi = async (id: string) => {
  const result = await api.delete(`${baseUrl}/admin/report/${id}`);
  return result.data;
};
