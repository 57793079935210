/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import "./Inventory.css";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import { DetailsModal } from "../../components/modal/showDetails/DetailsModal";
import { getAllinventory } from "../../services/inventory/inventoryService";
import { IInventory, IWareHouse } from "logistic-packages";
import { DataTable } from "../../components/table/Table";
// import { Link } from 'react-router-dom';
import axios from "axios";
import { getAllWarehouses } from "../../services/warehouse/warehouseService";
import { InventoryModal } from "../../components/modal/inventorymodal/InventoryModal";


const columnDefinitions = [
  { label: 'ID', key: '_id' },
  { label: 'Item No', key: 'No' },
  { label: 'Opening', key: 'Opening' },
  { label: 'Inward', key: 'Inward' },
  { label: 'Outward', key: 'Outward' },
  { label: 'Closing', key: 'closing' },
  { label: 'Description', key: 'Description' },
  
]

export const Inventory: React.FC = () => {
  const [search, setSearch] = useState<string>("");
  const [isLoadingModal, setIsLoadingModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [inventoryData, setInventoryData] = useState<IInventory[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [warehouseData, setWarehouseData] = useState<IWareHouse[]>([]);
  const [recordSize, setRecordSize] = useState<number>(10);
  const [selectedItem, setSelectedItem] = useState<{
    [x: string]: string;
  } | null>(null);
  const [warehouseDataBackup, setWarehouseDataBackup] = useState<any[]>([]);
  // const [reportDetails, setReportDetails] = useState<{ [keys: string]: any }>({});
  const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false);
  const [selectedLocation, setSelectedLocation] = useState<any>("");
  const [inventoryDataBackup, setInventoryDataBackup] = useState<any[]>([]);
  const [inventoryDataBackupLocation, setInventoryDataBackupLocation] =
    useState<any[]>([]);

  const apiUrl = process.env.REACT_APP_API_URL!;

  async function loadData(locationCode: string) {
    try {
      getAllinventory(locationCode)
        .then((inventory: any) => {
          if (inventory) {
            setInventoryData(inventory);
            setInventoryDataBackup(inventory as IInventory[]);
          }
        })
        .finally(() => setIsLoading(false));

      // ... (rest of the function)
    } catch (err) {
      console.error(err);
    }
  }


  

  const loadWarehouseDetails = async () => {
    try {
      getAllWarehouses().then((warehouse: any) => {
        if (warehouse) {
          setWarehouseData(warehouse);
          setWarehouseDataBackup(warehouse as IWareHouse[]);
          setSelectedLocation(
            warehouse.length > 0 && warehouse[0].sLocationCode
              ? warehouse[0].sLocationCode
              : ""
          );
          if (warehouse[0]) {
            loadData(warehouse[0].sLocationCode);
          }
        }
      });
    } catch (err) {
      console.error("Error fetching warehouse details:", err);
    }
  };

  useEffect(() => {
    loadWarehouseDetails();
  }, []);

  const paginateArray = (pageNumber: any) => {
    if (pageNumber >= 0 && pageNumber < totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const startIndex = currentPage * recordSize;
  const endIndex = startIndex + recordSize;

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    setCurrentPage(0); // Reset to the first page when searching
  };

  const handleRecordSizeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setRecordSize(Number(e.target.value));
    setCurrentPage(0); // Reset to the first page when changing record size
  };

  const handleShowDetails = (itemDetails: any) => {
   setIsLoadingModal(true);
    const itemNo = itemDetails.No;
    const locationCode = itemDetails.Location_Filter;
    axios
      .get(apiUrl + `/admin/external-api/lotWiseInventory?ItemNo_FilterOnly='${itemNo}'&Location_FilterOnly='${locationCode}'`)
      .then((response) => {
        setIsLoading(false);
        // const itemDetailsFromAPI = response.data.data.value[0];
        setSelectedItem(response.data.data.value);
          setShowDetailsModal(true);
          setIsLoadingModal(false);
      })

      .catch((error) => {
        console.error("Error fetching item details:", error);
        setSelectedItem(null);
        setIsLoading(false);
      });
  };

  const filteredItems = inventoryData.filter((item: any) => {
    const searchTerm = search.trim().toLowerCase(); // Convert search term to lowercase

    // Convert item name to lowercase (or uppercase) before comparison
    const itemName = item.No ? item.No.toLowerCase() : "";

    return searchTerm === "" ? item : itemName.includes(searchTerm);
  });

  const totalFilteredItems = filteredItems.length;
  const totalPages = Math.ceil(totalFilteredItems / recordSize);

  const paginatedItems = filteredItems.slice(
    currentPage * recordSize,
    (currentPage + 1) * recordSize
  );

  useEffect(() => {
    if (currentPage > 0 && paginatedItems.length === 0) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  }, [paginatedItems, currentPage]);

  
  const handleFilterInput = (e: any, isLocation: boolean) => {
   
    if (isLocation) {
      setSelectedLocation(e.target.value);
      if (e.target.value !== "") {
        setInventoryData(
          inventoryDataBackup.filter(
            (inventory) => inventory.sLocationCode == e.target.value
          )
        );
        setInventoryDataBackupLocation(
          inventoryDataBackup.filter(
            (inventory) => inventory.sLocationCode == e.target.value
          )
        );
        setIsLoading(true);

        loadData(e.target.value);
      }
    } else {
    }
  };

  return (
    <>
          {isLoadingModal ? (
        <div className="bg-loader">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : null}
      {showDetailsModal && selectedItem && (
        <InventoryModal
          title={"Inventory Details"}
          orderInfo={selectedItem}
          setInventoryModal={setShowDetailsModal}
          width="70%"
        />
      )}

      <Container fluid>
        <div className="user-content-header py-4">
          <Row className="mb-2">
            <Col sm="6">
              <h1 className="m-0 text-dark">Inventory</h1>
            </Col>
            <Col sm="6">
              <ol className="breadcrumb float-sm-right justify-content-end">
                <li className="breadcrumb-item">
                  <a href="/dashboard">Home</a>
                </li>
                <li className="breadcrumb-item active">Inventory</li>
              </ol>
            </Col>
          </Row>
        </div>
        <div className="content">
          <Row>
            <Col lg="12">
              <div className="card" bg-color="white">
                <div className="card-body">
                  <div
                    id="DataTables_Table_0_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <div className="card-head">
                      <div
                        className="dataTables_length"
                        id="DataTables_Table_0_length"
                      >
                        <label>
                          Show{""}
                          <select
                            name="DataTables_Table_0_length"
                            aria-controls="DataTables_Table_0"
                            className="table-input"
                            value={recordSize}
                            onChange={handleRecordSizeChange}
                          >
                            <option value="10">10</option>
                            <option value="15">15</option>
                            <option value="20">20</option>
                            <option value="100">100</option>
                          </select>{" "}
                          entries
                        </label>
                      </div>
                      <div
                        id="DataTables_Table_0_filter"
                        className="dataTables_filter"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center"
                        }}
                      >
                        <select
                          name="sLocationCode"
                          required
                          value={selectedLocation}
                          onChange={(e) => handleFilterInput(e, true)}
                          className="inventory-location-filter"
                        >
                          {warehouseDataBackup.map((warehouse: any) => (
                            <option
                              key={warehouse.sLocationCode}
                              value={warehouse.sLocationCode}
                            >
                              {warehouse.sLocationCode}
                            </option>
                          ))}
                        </select>
                        <label
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                          }}
                        >
                          <input
                            type="search"
                            className="table-input"
                            placeholder="Search Item No..."
                            aria-controls="DataTables_Table_0"
                            value={search}
                            onChange={handleSearch}
                            style={{ height: "2rem" }}
                          />
                        </label>
                      </div>
                    </div>

                    <div style={{ overflow: "auto" }}>
                      <DataTable
                        columnDefinitions={columnDefinitions}
                        paginatedItems={paginatedItems}
                        handleShowDetails={handleShowDetails}
                        isLoading={isLoading}
                        tableFor="Inventory"
                      />
                    </div>
                    {isLoading ? (
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ height: "20vh" }}
                      >
                        <Spinner animation="border" variant="primary" />
                      </div>
                    ) : null}
                    <div className="d-flex justify-content-between align-items-center">
                      <div
                        className="dataTables_info"
                        id="DataTables_Table_0_info "
                        role="status"
                        aria-live="polite"
                      >
                        {totalFilteredItems > 0
                          ? `Showing ${
                              endIndex < totalFilteredItems
                                ? endIndex
                                : totalFilteredItems
                            } of ${totalFilteredItems} entries`
                          : ""}
                      </div>
                      <div
                        className="dataTables_paginate paging_simple_numbers"
                        id="DataTables_Table_0_paginate"
                      >
                        <Button
                          className="paginate_button previous me-2"
                          aria-controls="DataTables_Table_0"
                          data-dt-idx={0}
                          tabIndex={-1}
                          id="DataTables_Table_0_previous"
                          onClick={() => {
                            paginateArray(currentPage - 1);
                          }}
                          disabled={currentPage === 0}
                        >
                          Previous
                        </Button>
                        <span>
                          <Button
                            className="paginate_button-current"
                            aria-controls="DataTables_Table_0"
                            data-dt-idx={1}
                            tabIndex={0}
                          >
                            {currentPage + 1}
                          </Button>
                        </span>
                        <Button
                          className="paginate_button next ms-2"
                          aria-controls="DataTables_Table_0"
                          data-dt-idx="2"
                          tabIndex={-1}
                          id="DataTables_Table_0_next"
                          onClick={() => {
                            paginateArray(currentPage + 1);
                          }}
                          disabled={
                            currentPage === totalPages - 1 ||
                            filteredItems.length < 10
                          }
                        >
                          Next
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};
