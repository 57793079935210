import { addVehicleVendorApi, editVehicleVendorApi, getAllVehicleVendorApi, getVehicleVendorByIdApi } from "../../api/account/vehicleVendorApi";
export const getAllVehicleVendor = () => {
    return new Promise((resolve, reject) => {
        getAllVehicleVendorApi()
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
export const addVehicleVendor = (reqBody: any) => {
    return new Promise((resolve, reject) => {
        addVehicleVendorApi(reqBody)
            .then((data) => {
                resolve(data.data);
            })
            .catch((error) => {
                reject(error)
            });
    });
};
export const getVehicleVendorById = (id: string) => {
    return new Promise((resolve, reject) => {
        getVehicleVendorByIdApi(id)
            .then((data) => {
                resolve(data.data);
            })
            .catch((error) => {
                reject(error)
            });
    });
};
export const editVehicleVendor = (id: string, update: any) => {
    return new Promise((resolve, reject) => {
        editVehicleVendorApi(id, update)
            .then((data) => {
                resolve(data.data);
            })
            .catch((error) => {
                reject(error)
            });
    });
};
