import React, { useState } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import { useEffect } from "react";
import "./addVehicleApi.css";
import EyeIcon from "@material-ui/icons/RemoveRedEye";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  addSetting
} from "../../../services/account/accountService";
import {
  AccountDetails,
  AuthType,
  AvailableVendor,
} from "../../../interface/AccountInterface";
import { addVehicleVendor } from "../../../services/account/vehicleVendorService";
import { getAllAvailableVendor } from "../../../services/account/availableVendorService";

interface AddBranchProps {
  show: boolean;
  onClose: () => void;
  width?: string;
}

const AddVehicleApi: React.FC<AddBranchProps> = ({ show, onClose, width }) => {
  const [isLoading, setIsLoading] = useState(true);
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  async function createBranch() {
    try {
      await addVehicleVendor(formData);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  const apiUrl = process.env.REACT_APP_API_URL as string;
  const [formData, setFormData] = useState<AccountDetails>({
    sName: "",
    sUrl: "",
    sAuthType: AuthType.BASIC,
    sUserName: "",
    sPassword: "",
    sTag: "",
    sToken: "",
  });
  const [settingData, setSettingData] = useState<AccountDetails[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [userData, setUserData] = useState();
  const [vendorList, setVendorList] = useState([]);
  const resetFormData = () => {
    setFormData({
      sName: "",
      sUrl: "",
      sAuthType: AuthType.BASIC,
      sUserName: "",
      sPassword: "",
      sTag: "",
      sToken: "",
    });
  };

  const handleSelectChange = (event: React.ChangeEvent<any>) => {
    const { name, value } = event.target;
    if (event.target.name === "sName") {
      const selectedApiData = settingData.find((api) => api.sName === value);
      if (selectedApiData) {
        setFormData(selectedApiData);
      } else {
        setFormData({
          sName: "",
          sUrl: "",
          sAuthType: 0,
          sUserName: "",
          sPassword: "",
          sTag: "",
          sToken: "",
        });
      }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  function handleSelectTagChange(event: React.ChangeEvent<any>) {
    let vender = (vendorList as any).find(
      (vender: any) => event.target.value == vender.sAPIUrl
    );

    setFormData((prevData) => ({
      ...prevData,
      sTag: vender.sTag ?? "",
      sSingleTracking: vender.sSingleTracking,
    }));
  }

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevData: AccountDetails) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleAddApi = async (newApiData: AccountDetails) => {
    await addSetting(newApiData)
      .then((res) => {
        toast.success("Api Added successfully!", {
          position: "top-center",
          theme: "colored",
        });
        resetFormData();
        toggleModal();
        setTimeout(() => {}, 1000);
      })
      .catch((err) => {
        console.error(err);
        toast.error(err.response, {
          position: "top-center",
          theme: "colored",
        });
      });
  };
  const loadData = async () => {
    const vendors = await getAllAvailableVendor();
    if (vendors) setVendorList(vendors);
  };
  useEffect(() => {
    loadData();
  }, []);

  // Function to handle form submission
  const handleSubmit = async () => {
 debugger
    addVehicleVendor(formData.sAuthType==AuthType.BEARER?{
      sName: formData.sName,
      sUrl: formData.sUrl,
      sAuthType: formData.sAuthType,
      sToken: formData.sToken,
      sTag: formData.sTag,
      sSingleTracking: formData.sSingleTracking,
    }:{
      sName: formData.sName,
      sUrl: formData.sUrl,
      sAuthType: formData.sAuthType,
      sUserName: formData.sUserName,
      sPassword: formData.sPassword,
      sTag: formData.sTag,
      sSingleTracking: formData.sSingleTracking,
    })
      .then((res) => {
        toast.success("Vehicle Api Added Successfully!", {
          position: "top-center",
          theme: "colored",
        });
        setFormData({
          sName: "",
          sUrl: "",
          sAuthType: 0,
          sUserName: "",
          sPassword: "",
          sTag: "",
          sSingleTracking: undefined,
        });
        onClose();
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          position: "top-center",
          theme: "colored",
        });
      });
  };

  return (
    <Modal
      show={show}
      onHide={onClose}
      contentClassName={width === "60%" ? "modal-w-xl" : "modal-w-sm"}
    >
      <Modal.Header>
        <Modal.Title>Add Vehicle Api</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* <Form onSubmit={handleSubmit}> */}
        <Row>
          <Col md={12}>
            <div className="form-container">
              <div className="form-group">
                <label htmlFor="apiName">Transporter Name:</label>
                <input
                  type="text"
                  id="apiName"
                  name="sName"
                  value={formData.sName}
                  onChange={handleInputChange}
                  placeholder="Enter API Name"
                  required
                  className="setting-input-field"
                  // Make the input read-only
                />
              </div>
              <div className="form-group">
                <label htmlFor="apiUrl">Vendor:</label>

                <select
                  id="apiUrl"
                  name="sUrl"
                  value={formData.sUrl}
                  onChange={(e) => {
                    handleSelectChange(e);
                    handleSelectTagChange(e);
                  }}
                  required
                  className="settings-input-field"
                >
                  <option value="" disabled>
                    Select an API URL
                  </option>
                  {vendorList.map((value: AvailableVendor, index: any) => (
                    <option key={index} value={value.sAPIUrl}>
                      {value.sName}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="authType">Authentication Type:</label>

                <select
                  id="authType"
                  name="sAuthType"
                  value={formData.sAuthType}
                  onChange={handleSelectChange}
                  required
                  className="settings-input-field"
                >
                  <option value="0">BASIC</option>
                  <option value="1">NTLM</option>
                  <option value="2">NO</option>
                  <option value="3">Bearer</option>
                </select>
              </div>
             { 
             formData.sAuthType!=AuthType.BEARER
             ?
             <>
             <div className="form-group">
                <label htmlFor="username">Username:</label>
                <input
                  type="text"
                  id="username"
                  name="sUserName"
                  value={formData.sUserName}
                  onChange={handleSelectChange}
                  placeholder="Enter Username"
                  required
                  className="setting-input-field"
                />
              </div>
              <div className="form-group ">
                <label htmlFor="password">Password:</label>
                <div className="eye-password">
                  <input
                    type={passwordVisibility ? "text" : "password"}
                    id="password"
                    name="sPassword"
                    value={formData.sPassword}
                    onChange={handleSelectChange}
                    placeholder="Enter Password"
                    required
                    className="setting-input-field"
                  />
                  <i
                    className="input-icon eye-position"
                    onClick={() => {
                      setPasswordVisibility(!passwordVisibility);
                    }}
                  >
                    {" "}
                    {passwordVisibility ? <VisibilityOff /> : <EyeIcon />}
                  </i>
                </div>
              </div>
              </>
              :
              <div className="form-group ">
                <label htmlFor="token">Token:</label>
                  <input
                    type="text"
                    id="token"
                    name="sToken"
                    value={formData.sToken}
                    onChange={handleSelectChange}
                    placeholder="Enter Token"
                    required
                    className="setting-input-field"
                  />
              </div>
              
              }
              <div className="form-group text-center">
                <button type="button" onClick={handleSubmit} className="save">
                  Save
                </button>
              </div>
            </div>
          </Col>
        </Row>

        {/* </Form> */}
      </Modal.Body>
    </Modal>
  );
};

export default AddVehicleApi;
export {};
