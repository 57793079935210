import { api, baseUrl } from '../../api';

export const getAllNotificationApi = async (pageNumber: number) => {
    const result = await api.get(`${baseUrl}/shared/notification/filter?page=${pageNumber}&limit=10`);
    return result.data;
};

export const updateNotificationApi = async (id: string, notification: any) => {
    const result = await api.patch(`${baseUrl}/shared/notification/${id}`, notification);
    return result.data;
};
