import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';


interface SettingModalProps {
    show: boolean;
    onClose: () => void;
    onAdd: (newApiData: any) => void;
    
  }

const SettingModal: React.FC<SettingModalProps> =({ show, onClose, onAdd,  }) => {
  const [newApiData, setNewApiData] = useState({
    sName: '',
    sUrl: '',
    sAuthType: '0',
    sUserName: '',
    sPassword: '',
  });

  const handleAdd = () => {
    onAdd(newApiData); // Pass the new data back to the parent component
    onClose();
     // Close the modal
  };

  const resetFormData = () => {
    setNewApiData({
      sName: '',
      sUrl: '',
      sAuthType: '0',
      sUserName: '',
      sPassword: '',
    });
  };

  return (
    <Modal show={show} onHide={() => {
        resetFormData(); 
        onClose();
        
      }}>
      <Modal.Header>
        <Modal.Title>Add New API</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group>
            <Form.Label>API Name</Form.Label>
            <Form.Control
              type="text"
              value={newApiData.sName}
              onChange={(e) => setNewApiData({ ...newApiData, sName: e.target.value })}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>API URL</Form.Label>
            <Form.Control
              type="text"
              value={newApiData.sUrl}
              onChange={(e) => setNewApiData({ ...newApiData, sUrl: e.target.value })}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Authentication Type</Form.Label>
            <Form.Control
              as="select"
              value={newApiData.sAuthType}
              onChange={(e) => setNewApiData({ ...newApiData, sAuthType: e.target.value })}
            >
              <option value="0">BASIC</option>
              <option value="1">NTLM</option>
            </Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.Label>Username</Form.Label>
            <Form.Control
              type="text"
              value={newApiData.sUserName}
              onChange={(e) => setNewApiData({ ...newApiData, sUserName: e.target.value })}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              value={newApiData.sPassword}
              onChange={(e) => setNewApiData({ ...newApiData, sPassword: e.target.value })}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleAdd}>
          Add
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SettingModal;
