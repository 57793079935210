import { addRoleApi, deleteRoleApi, getAllRoleApi, updateRoleApi } from "../../api/role/roleApi";




export const getAllroles = () => {
    return new Promise((resolve, reject) => {
        getAllRoleApi()
            .then((data) => {
                resolve(data.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};



export const addRole = (reqbody: any) => {
    return new Promise((resolve, reject) => {
        addRoleApi(reqbody)
            .then((data) => {
                resolve(data.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const updateRole = (id: string, roleData: any) => {
    return new Promise((resolve, reject) => {
        updateRoleApi(id, roleData)
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const deleteARole = (id: string) => {
    return new Promise((resolve, reject) => {
        deleteRoleApi(id)
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
