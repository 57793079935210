import React from "react";
import ReactDOM from "react-dom";
import "./InventoryModal.css";
import { Modal, Button } from "react-bootstrap";

interface InventoryModalProps {
  title: string;
  orderInfo: { [x: string]: any };
  setInventoryModal: React.Dispatch<React.SetStateAction<boolean>>;
  width?: string;
  height?: string;
}

export const InventoryModal: React.FC<InventoryModalProps> = ({
  title,
  orderInfo,
  setInventoryModal,
  width,
  height
}) => {
  const targetElement = document.querySelector(".myPortalModalDiv");
  if (!targetElement) return null;

  const hasData = Object.keys(orderInfo).length > 0;

  return ReactDOM.createPortal(
    <Modal
      show={true}
      onHide={() => setInventoryModal(false)}
      size="lg"
      centered
      className="modal"
      contentClassName={
        width === "70%"
          ? "modal-w-xl"
          : "modal-w-sm" && height === "70%"
          ? "modal-w-xl"
          : "modal-w-sm"
      }
      style={{ height: height || "auto" }}
    >
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="item-table">
            <table className="invoice-table">
              <thead>
                <tr>
                  <th>Item No</th>
                  <th>Lot No</th>
                  <th>Expiration Date</th>
                  <th>Quantity</th>
                </tr>
              </thead>
              <tbody>
                {hasData ? (
                  Object.values(orderInfo).map((item, index) => (
                    <tr key={index}>
                      <td>{item["Item_No_"] ?? ""}</td>
                      <td>{item["Lot_No_"] ?? ""}</td>
                      <td>{item["Expiration_Date"] ?? ""}</td>
                      <td>{item["Quantity"] ?? ""}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={4} className="no-data-found">
                      No data found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={() => setInventoryModal(false)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>,
    targetElement
  );
};
