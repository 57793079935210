import { createDeviceToken } from "../../api/login/LoginApi";

export const addDeviceToken = (reqbody: any) => {
    return new Promise((resolve, reject) => {
        createDeviceToken(reqbody)
            .then((data) => {
                resolve(data.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};