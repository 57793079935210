import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { DataTable } from '../../table/Table';
import './TripHistoryModal.css';
export const TripHistoryModal: React.FC<any> = ({
    setShowTripHistoryPopupHandler = (item: boolean) => {
        return item;
    },
    showModal,
    pagination,
    setShowModal
}) => {
    const [mergeId, setMergeId] = useState('');

    const columnDefinitions = {
        Consignment: [
            { label: 'ID', key: '_id' },
            { label: 'Consignment Group No', key: 'sConsignmentId' },
            { label: 'Vehicle Reg. No.', key: 'sRegNo' },
            { label: 'Location', key: 'sLocation' },
            { label: 'Temperature', key: 'sTemperature' },
            { label: 'Halt', key: 'sHalt' },
        ],
    };

    const onClose = () => {
        setShowModal(false);
    };
    return (
        <Modal show={showModal} onHide={onClose} contentClassName='modal-w-xxl'>
            <Modal.Header>
                <Modal.Title>Trip History</Modal.Title>
            </Modal.Header>
            <Modal.Body className='modal-body'>
                <div className='table-parent'>
                    <DataTable
                        columnDefinitions={columnDefinitions.Consignment}
                        paginatedItems={pagination}
                        isLoading={showModal}
                        tableFor='Consignment'
                        trackerColumn='sStatus'
                        hideActions={true}
                        mergeId={mergeId}
                    />
                    {pagination?.length ? '' : 'No Data'}
                </div>
            </Modal.Body>
        </Modal>
    );
};
