import { api, baseUrl } from "../../api";

export const getAllCustomersApi = async () => {
  const result = await api.get(`${baseUrl}/admin/customer`);
  return result.data;
};
export const deleteCustomerApi = async (id: string) => {
  const result = await api.delete(`${baseUrl}/admin/customer/${id}`);
  return result.data;
};
export const customerStatusApi = async (id: string, data: any) => {
  const result = await api.patch(`${baseUrl}/admin/customer/${id}`, {
    data,
  });
  return result.data;
};
export const customerApproveStatusApi = async (
  id: string,
  isApproved: boolean
) => {
  const result = await api.patch(`${baseUrl}/admin/customer/${id}`, {
    data: { bIsApproved: !isApproved },
  });
  return result.data;
};

export const getAllSyncCustomersApi = async () => {
  const result = await api.get(`${baseUrl}/admin/customer/sync`);
  return result.data;
};
