import React, { useState, useEffect } from "react";
import "./ResetPassword.css";
import MailIcon from "@material-ui/icons/Mail";
// import { messaging } from '../../firebase';
// import { getToken } from '@firebase/messaging';
import EyeIcon from "@material-ui/icons/RemoveRedEye";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import { useSelector, useDispatch } from "react-redux";
import { signinUser, signinToken } from "../../redux/auth/authSlice";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { addDeviceToken } from "../../services/login/LoginService";

interface User {
  sEmail: string;
  sPassword: string;
}
export const ResetPassword: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [password, setPassword] = useState<string>("");
  const auth = useSelector((state: any) => state.auth.value);
  const apiUrl = process.env.REACT_APP_API_URL;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useParams();

  useEffect(() => {
    let localData = JSON.parse(localStorage.getItem("user") || "null");
    let localDataToken = JSON.parse(localStorage.getItem("sToken") || "null");

    const isAuth =
      (auth && auth.token && auth.user) || (localData && localDataToken)
        ? true
        : false;
    if (isAuth) {
      navigate("/dashboard");
    }
    // eslint-disable-next-line
  }, []);

  const handleLogin = () => {
    setIsLoading(true);
    axios
      .post(apiUrl + "/auth/reset-password", {
        data: {
          sToken: token,
          sNewPassword: password,
        },
      })
      .then((res) => {
        navigate("/dashboard");
      })
      .catch((err) => {
        console.error(err);
        toast.error(err.response.data.message, {
          position: "top-center",
          theme: "colored",
        });
      })
      .finally(() => setIsLoading(false));
  };

  // const requestPermission=async(userId?:string)=>{
  //     const permission=await Notification.requestPermission();
  //     if(permission==="granted" && userId){
  //       const token=await getToken(messaging,{vapidKey:"BGUb-KgjfwI5r9Bo5GkKZNuI6XKK3Rts4lG25F6HnEeFQnNgSYPJzHE8uEEwQbu8F_2CKZ8zsHH_gHtPKcEEUwk"})
  //      await addDeviceToken({"data":{sToken:token, eGroupType:"Admin",tUserId:`${userId}`}})
  //     }
  //   }
  return (
    <>
      <ToastContainer />
      <div className="login-container">
        <div className="login-box">
          <div className="login-title">
            <h1>ShineTrack</h1>
          </div>
          <p className="login-msg">Reset Password</p>

          <div className="form-group login-form">
            <div className="login-input-group">
              <input
                type={passwordVisibility ? "text" : "password"}
                placeholder="Password"
                name="sPassword"
                required
                onChange={(e) => setPassword(e.target.value)}
              />
              <i
                className="input-icon"
                onClick={() => {
                  setPasswordVisibility(!passwordVisibility);
                }}
              >
                {" "}
                {passwordVisibility ? <VisibilityOff /> : <EyeIcon />}
              </i>
            </div>
          </div>

          <div className="login-button">
            <button
              type="submit"
              className="btn-submit"
              onClick={handleLogin}
              disabled={password !== "" ? false : true}
            >
              {isLoading ? (
                <Spinner animation="border" variant="light" />
              ) : (
                <span>Confirm</span>
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
