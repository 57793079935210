import { getAllPieChartApi } from "../../api/piechart/pieChartApi";

export const getAllPieChart =(): Promise<any> =>{
  return new Promise ((resolve, reject)=>
  {
    getAllPieChartApi()
    .then((data)=>{
       resolve(data.data)
    })
    .catch((error)=>{
        reject(error)
    });
  });
}