/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, ChangeEvent } from "react";
import "./AddThresold.css";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { addThresold } from "../../../../services/thresold/thresoldService";

export enum SettingsKey {
  TEMPERATURE = "Temperature Threshold",
  HALTED_SINCE = "Halted Since Threshold",
  TRIGGER_TIME = "Trigger Time",
}
interface ThresoldDetails {
  sKey: SettingsKey;
  sValue: number;
}

export const AddThresold: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [details, setDetails] = useState<ThresoldDetails>({
    sKey: SettingsKey.TEMPERATURE,
    sValue: 0,
  });

  const { search } = useLocation();
  const [isFormEdited, setIsFormEdited] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();

  const inputhandler = (
    e:
      | ChangeEvent<HTMLInputElement>
      | ChangeEvent<HTMLSelectElement>
      | ChangeEvent<HTMLTextAreaElement>
  ) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
    setIsFormEdited(true);
  };

  function isDataNotFilled() {
    return !isFormEdited || !(details.sKey && details.sValue);
  }

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setIsSubmitting(true);
    // setIsLoading(true);
    const thresoldData = {
      data: {
        sKey: details.sKey,
        sValue: details.sValue,
      },
    };

    try {
      await addThresold(thresoldData);
      toast.success("Threshold Added Successfully", {
        position: "top-center",
        theme: "colored",
      });
      setTimeout(() => {
        navigate(`/settings?activeTab=thresold${search}`);
      }, 1000);
    } catch (err: any) {
      console.error(err);
      toast.error(err.response.data.message, {
        position: "top-center",
        theme: "colored",
      });
      setIsSubmitting(false);
    }
    //  finally {

    //     setIsLoading(false);
    //   }
  };
  return (
    <>
      {/* {isLoading ? (
                <div className='bg-loader'>
                    <Spinner animation='border' variant='primary' />
                </div>
            ) : null} */}
      <div className="mx-2 ">
        <div className="user-content-header py-4">
          <ToastContainer />
          <Row className="mb-2">
            <Col sm="6">
              <h1 className="m-0 text-dark">Thresholds</h1>
            </Col>

            <Col sm="6">
              <ol className="breadcrumb float-sm-right justify-content-end">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link
                    to={{
                      pathname: "/settings",
                      search: "?activeTab=thresold",
                    }}
                  >
                    Thresolds
                  </Link>
                </li>
                <li className="breadcrumb-item active">Create</li>
              </ol>
            </Col>
          </Row>
        </div>
        <Container fluid className="main-container bg-white">
          <Row className="title-row">
            {" "}
            <p>Add Threshold</p>
          </Row>

          <Row className="form-group">
            <Col sm={12} md={12} className="form-fields op">
              <p>Threshold Temperature</p>
              <select
                name="sKey"
                onChange={(e) => inputhandler(e)}
                className="vehical-input-field"
                value={details.sKey}
              >
                {Object.values(SettingsKey).map((key) => (
                  <option key={key} value={key}>
                    {key}
                  </option>
                ))}
              </select>
            </Col>
          </Row>
          <Row className="form-group">
            <Col sm={12} md={12} className="form-fields op mb-4">
              {details.sKey === SettingsKey.TEMPERATURE ? (
                <p>Value (°C)</p>
              ) : (
                <p>Value (min)</p>
              )}

              <>
                <input
                  name="sValue"
                  onChange={(e) => inputhandler(e)}
                  placeholder="Enter Value"
                  className="vehical-input-field"
                  type="number"
                  value={details.sValue}
                />
              </>
            </Col>
          </Row>
        </Container>
        <div className="card-footer text-center mb-3">
          <button
            className="btn ripple btn-primary btnDataSave"
            name="btnDataSave"
            disabled={isDataNotFilled() || isSubmitting}
            onClick={handleSubmit}
          >
            {" "}
            Add Threshold{" "}
          </button>
        </div>
      </div>
    </>
  );
};
