import {
    createUserBranchApi,
    getUserBranchApi,
    getUserDepartmentApi,
    getUserDesignationApi,
    getUserGroupApi,
    getUserManageAccessApi,
    getUserRoleApi,
} from '../../api/generic/organizationApi';

export const getUserRole = () => {
    return new Promise((resolve, reject) => {
        getUserRoleApi()
            .then((data) => {
                resolve(data.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
export const getUserGroup = () => {
    return new Promise((resolve, reject) => {
        getUserGroupApi()
            .then((data) => {
                resolve(data.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
export const getUserManageAccess = () => {
    return new Promise((resolve, reject) => {
        getUserManageAccessApi()
            .then((data) => {
                resolve(data.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
export const getUserBranch = () => {
    return new Promise((resolve, reject) => {
        getUserBranchApi()
            .then((data) => {
                resolve(data.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
export const createUserBranch = (rawdoc:any) => {
    return new Promise((resolve, reject) => {
        createUserBranchApi(rawdoc)
            .then((data) => {
                resolve(data.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
export const getUserDepartment = () => {
    return new Promise((resolve, reject) => {
        getUserDepartmentApi()
            .then((data) => {
                resolve(data.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
export const getUserDesignation = () => {
    return new Promise((resolve, reject) => {
        getUserDesignationApi()
            .then((data) => {
                resolve(data.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
