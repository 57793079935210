/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, ChangeEvent, useEffect } from "react";
import "./EditDriver.css";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { ClickAwayListener } from "@material-ui/core";
import axios from "axios";

interface DriverDetails {
  sName: string;
  // sEmail: string;
  aPhoneNo: string;
  sPresentAddress: string;
}

export const EditDriver = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState(true);
  const [details, setDetails] = useState<DriverDetails>({
    sName: "",
    // sEmail: "",
    aPhoneNo: "",
    sPresentAddress: "",
  });
  const apiUrl = process.env.REACT_APP_API_URL as string;
  const [isFormEdited, setIsFormEdited] = useState(false);

  const { id: driverId } = useParams<{ id: string }>();

  useEffect(() => {
    if (driverId) {
      fetchDriverDetails(driverId);
    } else {
      setIsLoading(false);
    }
  }, []);

  const navigate = useNavigate();

  const fetchDriverDetails = (id: string) => {
    axios
      .get(apiUrl + `/admin/driver/${id}`)
      .then((res) => {
        setDetails(res.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const inputhandler = async (
    e:
      | ChangeEvent<HTMLInputElement>
      | ChangeEvent<HTMLSelectElement>
      | ChangeEvent<HTMLTextAreaElement>
  ) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
    setIsFormEdited(true);
  };



  const handlesubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const DriverData = {
      data: {
        sName: details.sName,
        // sEmail: details.sEmail,
        aPhoneNo: details.aPhoneNo,
        sPresentAddress: details.sPresentAddress,
      },
    };

    if (driverId) {
      axios
        .patch(apiUrl + `/admin/driver/${driverId}`, DriverData)
        .then((res) => {
          navigate("/users/drivers");
        })
        .catch((err) => {
          toast.error(err.response.data.message, {
            position: "top-center",
            theme: "colored",
          });
        });
    }

   
  };

  return (
    <>
      {isLoading ? (
        <div className="bg-loader">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : null}
      <div className="mx-2 ">
        <div className="user-content-header py-4">
          <ToastContainer />
          <Row className="mb-2">
            <Col sm="6">
              <h1 className="m-0 text-dark">Drivers</h1>
            </Col>

            <Col sm="6">
              <ol className="breadcrumb float-sm-right justify-content-end">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/users/drivers">Drivers</Link>
                </li>
                <li className="breadcrumb-item active">Edit</li>
              </ol>
            </Col>
          </Row>
        </div>
        <Container fluid className="main-container bg-white">
          <Row className="title-row">
            <p>Edit Driver</p></Row>

          <Row className="form-group">
            <Col sm={12} className="form-fields op">
              <p>Driver Name</p>
              <input
                name="sName"
                onChange={(e) => inputhandler(e)}
                placeholder="Enter Name"
                className="new-page-input"
                type="text"
                value={details.sName}
              />
            </Col>
          </Row>
          {/* <Row className="form-group">
            <Col sm={12} className="form-fields op">
              <p>Email</p>
              <input
                name="sEmail"
                onChange={(e) => inputhandler(e)}
                placeholder="Enter Email"
                className="new-page-input"
                type="email"
                value={details.sEmail ? details.sEmail : "---"}
                disabled={!!driverId}
              />
            </Col>
          </Row> */}
          <Row className="form-group">
            <Col sm={12} className="form-fields op">
              <p>Contact No</p>
              <input
                name="aPhoneNo"
                onChange={(e) => inputhandler(e)}
                placeholder="Enter Contact No"
                className="new-page-input"
                type="text"
                value={details.aPhoneNo}
              />
            </Col>
          </Row>
          <Row className="form-group">
            <Col sm={12} className="form-fields op mb-3">
              <p>Address</p>
              <input
                name="sPresentAddress"
                onChange={(e) => inputhandler(e)}
                placeholder="Enter Address"
                className="new-page-input"
                type="text"
                value={details.sPresentAddress}
              />
            </Col>
          </Row>
        </Container>
        <div className="card-footer text-center mb-3">
          <button
            className="btn ripple btn-primary btnDataSave"
            name="btnDataSave"
            onClick={handlesubmit}
            disabled={!isFormEdited}
          >
            {" "}
            Update Driver
          </button>
        </div>
      </div>
    </>
  );
};
