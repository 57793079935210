import { IUserGroup } from 'logistic-packages';
import { api, baseUrl } from '../../api';

export const getAllGroupApi = async () => {
    const result = await api.get(`${baseUrl}/admin/organization/user-group`);
    return result.data;
};
export const getAllAccessApi = async () => {
    const result = await api.get(`${baseUrl}/admin/organization/manage-access`);
    return result.data;
};
export const addGroupApi = async (reqbody: IUserGroup) =>{
    const result = await api.post(`${baseUrl}/admin/organization/user-group`,reqbody);
    return result.data;
}
export const updateGroupApi = async (id: string, groupData: any) => {
    const result = await api.patch(`${baseUrl}/admin/organization/user-group/${id}`, groupData);
    return result.data;
};

export const deleteGroupApi = async (id: string) => {
    const result = await api.delete(`${baseUrl}/admin/organization/user-group/${id}`);
    return result.data;
};