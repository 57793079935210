import React, { useState, useRef, useEffect } from 'react';
import './UploadPodModal.css';
import axios from 'axios';
import { toast } from 'react-toastify';
import { uploadConsignmentPOD, uploadConsignmentWithPODData } from '../../../services/consignment/consignmentService';
import { Spinner } from 'react-bootstrap';

const FileDropZone: React.FC<any> = ({ id, hideModal, reFetchConsignmentData }) => {
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const [filePreviews, setFilePreviews] = useState<string[]>([]);
    const [dragging, setDragging] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const fileInputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        return () => {
            filePreviews.forEach(URL.revokeObjectURL);
        };
    }, [filePreviews]);

    const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setDragging(true);
    };

    const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setDragging(false);
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setDragging(false);
        if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
            const files = Array.from(event.dataTransfer.files);
            setSelectedFiles(files);
            setFilePreviews(files.map((file) => URL.createObjectURL(file)));
            event.dataTransfer.clearData();
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log(event);
        if (event.target.files && event.target.files.length > 0) {
            const files = Array.from(event.target.files);
            setSelectedFiles(files);
            setFilePreviews(files.map((file) => URL.createObjectURL(file)));
        }
    };

    const handleClick = () => {
        fileInputRef.current?.click();
    };

    const handleSubmit = async (event: React.FormEvent<HTMLButtonElement>) => {
        setIsLoading(true);
        event.preventDefault();
        if (selectedFiles.length === 0) return;
        let imgArray = [];
        try {
            for (let index = 0; index < selectedFiles.length; index++) {
                const formData = new FormData();
                formData.append('file', selectedFiles[index]);
                const response = await uploadConsignmentPOD(formData);
                imgArray.push((response as any).url);
            }
            if (imgArray.length > 0) {
                try {
                    const response = await uploadConsignmentWithPODData(id, imgArray);
                    if ((response as any).status === 200) {
                        setIsLoading(false);

                        toast.success('POD Uploaded successfully!', {
                            position: 'top-center',
                            theme: 'colored',
                        });
                        reFetchConsignmentData();
                        setTimeout(() => {
                            hideModal();
                        }, 1000);
                    }
                } catch (error: any) {
                    setIsLoading(false);
                    toast.error('Something went wrong', {
                        position: 'top-center',
                        theme: 'colored',
                    });
                }
            }
        } catch (error: any) {
            console.error('Error uploading files:', error.message);
        }
    };

    return (
        <div>
            <div
                onClick={handleClick}
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                style={{
                    border: dragging ? '2px solid #000' : '2px dashed #ccc',
                }}
                className='select-file-parent'
            >
                {dragging ? 'Drop your files here' : 'Drag and drop files here or click to select files'}
                <input type='file' ref={fileInputRef} onChange={handleChange} style={{ display: 'none' }} multiple />
            </div>
            {filePreviews.length > 0 && (
                <div className='selected-files-show'>
                    <h3>Selected Files:</h3>
                    <div className='showing-files-cont'>
                        {filePreviews.map((preview, index) => (
                            <div key={index} style={{ position: 'relative' }}>
                                <img src={preview} alt={`Preview ${index}`} className='display-preview' />
                            </div>
                        ))}
                    </div>
                </div>
            )}
            <button disabled={isLoading} onClick={handleSubmit}>
                {isLoading ? <Spinner /> : 'Upload'}
            </button>
        </div>
    );
};

export default FileDropZone;
