import React, { useEffect, useState } from 'react';
import './Employee.css';
import { Button, Col, Container, Row, Spinner } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { DeleteModal } from '../../../components/modal/deletemodal/DeleteModal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DetailsModal } from '../../../components/modal/showDetails/DetailsModal';
import { EmployeeData } from '../../../interface/employee/employee.interface';
import { deleteEmployees, employeeStatus, getAllEmployees } from '../../../services/employee/employeeService';
import { DataTable } from '../../../components/table/Table';
import ChangePasswordModal from '../../../components/modal/changePasswordModal/changePassword';


const columnDefinitions = [
    { label: 'ID', key: '_id' },
    { label: 'Name', key: 'sName' },
    { label: 'Employee Roles', key: 'sEmployeeRole' },
    { label: 'Email', key: 'sEmail' },
    { label: 'Contact No', key: 'aPhoneNo' },
    { label: 'Address', key: 'sPresentAddress' },   
]

export const Employee: React.FC = () => {
    const [search, setSearch] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [empData, setEmpData] = useState<EmployeeData[]>([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [recordSize, setRecordSize] = useState(10);
    const navigate = useNavigate();
    const startIndex = currentPage * recordSize;
    const endIndex = startIndex + recordSize;
    const [showModal, setShowModal] = useState<string | false>(false);
    const [activeBtn, setActiveBtn] = useState<string | null>(null);
    const [selectedEmpDetails, setSelectedEmpDetails] = useState<{ [keys: string]: any }>({});
    const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false);
    const [passwordEditId, setPasswordEditId] = useState<string|undefined>()
    
    useEffect(() => {
        loadData();
        // eslint-disable-next-line
    }, []);

    async function loadData() {
        try {
            let employees = await getAllEmployees();
            if (employees) {
                employees=(employees as EmployeeData[]).map((item: any) => Object({...item,sEmployeeRole:item.tUserRole[0].sName}));
                setEmpData(employees as EmployeeData[]);
            }
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }

    const paginateArray = (pageNumber: number) => {
        if (pageNumber >= 0 && pageNumber < totalPages) {
            setCurrentPage(pageNumber);
        }
    };

    const getEmpType = (arr: any[]) => {
        return arr.map((roleInfo) => roleInfo.sName).join(' ,');
    };

    const updateStatus = async (item: any, bIsActive: boolean) => {
        setActiveBtn(item._id);
        try {
            await employeeStatus(item._id, bIsActive).then(async () => {
                await loadData();
            });
        } catch (err) {
            console.error(err);
        } finally {
            setActiveBtn(null);
        }
    };
    
    const changePassword = async (item: any) => {
        setPasswordEditId(item._id)
    };

    
      
      
      

    const deleteEmployee = async (id: string) => {
        try {
            debugger
            await deleteEmployees(id).then(() => loadData());
            toast.success('Item Deleted Successfully !', {
                position: 'top-center',
                theme: 'colored',
            });
        } catch (err) {
            toast.error('Cannot delete, please try again !', {
                position: 'top-center',
                theme: 'colored',
            });
        }
    };

    const editEmployee = async (id: string) => {
        navigate(`/users/employees/edit/${id}`);
        // await loadData();
    };

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value);
        setCurrentPage(0); // Reset to the first page when searching
    };

    const handleRecordSizeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setRecordSize(Number(e.target.value));
        setCurrentPage(0); // Reset to the first page when changing record size
    };

    const handleShowDetails = (orderInfo: EmployeeData) => {
        setSelectedEmpDetails({
            'Name': orderInfo.sName,
            'Email': orderInfo.sEmail,
            'Contact No': orderInfo.aPhoneNo,
            'Address': orderInfo.sPresentAddress,
            'Employee Role': orderInfo.tUserRole ? getEmpType(orderInfo.tUserRole) : '----',
        }); // Set the selected employee details
        setShowDetailsModal(true); // Open the modal
    };

    const filteredItems = empData.filter((item) => {
        const searchTerm = search.trim().toLowerCase(); // Convert search term to lowercase
        // Convert item name to lowercase (or uppercase) before comparison
        const itemName = item.sName ? item.sName.toLowerCase() : '';
        return searchTerm === '' ? item : itemName.includes(searchTerm);
    });

    const totalFilteredItems = filteredItems.length;
    const totalPages = Math.ceil(filteredItems.length / recordSize);
    const paginatedItems = filteredItems.slice(currentPage * recordSize, (currentPage + 1) * recordSize);
    useEffect(() => {
        if (currentPage > 0 && paginatedItems.length === 0) {
            setCurrentPage((prevPage) => prevPage - 1);
        }
    }, [paginatedItems, currentPage]);

    return (
        <>
        <ChangePasswordModal show={passwordEditId?true:false} setPasswordEditId={setPasswordEditId} id={passwordEditId} onClose={function (): void {} }/>
            {showModal && <DeleteModal id={showModal} setShowModal={setShowModal} action={deleteEmployee} />}
            {showDetailsModal && (
                <DetailsModal
                    title='Employee Details'
                    orderInfo={selectedEmpDetails}
                    setShowDetailsModal={setShowDetailsModal} // Close the modal callback
                    isConsignment={false}
                />
            )}

            <Container fluid style={{ opacity: showModal ? '0.4' : '1' }}>
                <div className='user-content-header py-4'>
                    <ToastContainer />
                    <Row className='mb-2'>
                        <Col sm='6'>
                            <h1 className='m-0 text-dark'>Employees</h1>
                        </Col>
                        <Col sm='6'>
                            <ol className='breadcrumb float-sm-right justify-content-end'>
                                <li className='breadcrumb-item'>
                                    <a href='/dashboard'>Home</a>
                                </li>
                                <li className='breadcrumb-item active'>Employees</li>
                            </ol>
                        </Col>
                    </Row>
                </div>

                <div className='content'>
                    <Row>
                        <Col lg='12'>
                            <div className='card' bg-color='white'>
                                <div className='card-header bg-white'>
                                    <h3 className='card-title'>&nbsp;</h3>
                                    <Link to='/users/employees/create'>
                                        <Button variant='info' className='user float-right add_role text-white'>
                                            Add Employee
                                        </Button>
                                    </Link>
                                </div>

                                <div className='card-body'>
                                    <div id='DataTables_Table_0_wrapper' className='dataTables_wrapper no-footer'>
                                        <div className='card-head'>
                                            <div className='dataTables_length' id='DataTables_Table_0_length'>
                                                <label>
                                                    Show{' '}
                                                    <select
                                                        name='DataTables_Table_0_length'
                                                        aria-controls='DataTables_Table_0'
                                                        className='table-input'
                                                        value={recordSize}
                                                        onChange={handleRecordSizeChange}
                                                    >
                                                        <option value='10'>10</option>
                                                        <option value='15'>15</option>
                                                        <option value='20'>20</option>
                                                        <option value='100'>100</option>
                                                    </select>{' '}
                                                    entries
                                                </label>
                                            </div>
                                            <div id='DataTables_Table_0_filter' className='dataTables_filter'>
                                                <label style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    Search:
                                                    <input
                                                        type='search'
                                                        className='table-input'
                                                        placeholder='Search by Name...'
                                                        aria-controls='DataTables_Table_0'
                                                        value={search}
                                                        onChange={handleSearch}
                                                        style={{ height: "2rem" }}
                                                    />
                                                </label>
                                            </div>
                                        </div>

                                        <div style={{ overflow: 'auto' }}>
                                            <DataTable
                                                columnDefinitions={columnDefinitions}
                                                paginatedItems={paginatedItems}
                                                handleShowDetails={handleShowDetails}
                                                deleteFunction={deleteEmployee}
                                                updateStatus={updateStatus}
                                                changePassword={changePassword}
                                                btnActiveId={activeBtn}
                                                editFunction={editEmployee}
                                                setShowModal={setShowModal}
                                                // getEmpType={getEmpType}
                                                isLoading={isLoading}
                                                tableFor='Employee'
                                            />
                                            {isLoading ? (
                                                <div
                                                    className='d-flex justify-content-center align-items-center'
                                                    style={{ height: '20vh' }}
                                                >
                                                    <Spinner animation='border' variant='primary' />
                                                </div>
                                            ) : null}
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <div
                                                    className='dataTables_info'
                                                    id='DataTables_Table_0_info '
                                                    role='status'
                                                    aria-live='polite'
                                                >
                                                    {totalFilteredItems > 0 ? (
                                                        `Showing ${endIndex < totalFilteredItems
                                                            ? endIndex
                                                            : totalFilteredItems
                                                        } of ${totalFilteredItems} entries`
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                                <div
                                                    className='dataTables_paginate paging_simple_numbers'
                                                    id='DataTables_Table_0_paginate'
                                                >
                                                    <Button
                                                        className='paginate_button previous me-2'
                                                        aria-controls='DataTables_Table_0'
                                                        data-dt-idx={0}
                                                        tabIndex={-1}
                                                        id='DataTables_Table_0_previous'
                                                        onClick={() => {
                                                            paginateArray(currentPage - 1);
                                                        }}
                                                        disabled={currentPage === 0}
                                                    >
                                                        Previous
                                                    </Button>
                                                    <span>
                                                        <Button
                                                            className='paginate_button-current'
                                                            aria-controls='DataTables_Table_0'
                                                            data-dt-idx={1}
                                                            tabIndex={0}
                                                        >
                                                            {currentPage + 1}
                                                        </Button>
                                                    </span>
                                                    <Button
                                                        className='paginate_button next ms-2'
                                                        aria-controls='DataTables_Table_0'
                                                        data-dt-idx='2'
                                                        tabIndex={-1}
                                                        id='DataTables_Table_0_next'
                                                        onClick={() => {
                                                            paginateArray(currentPage + 1);
                                                        }}
                                                        disabled={
                                                            currentPage === totalPages - 1 || filteredItems.length < 10
                                                        }
                                                    >
                                                        Next
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </>
    );
};
