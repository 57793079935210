import React, { useEffect, useState } from 'react';
import './Notifications.css';
import { faBell } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Spinner } from 'react-bootstrap';
import { getAllNotification, updateNotification } from '../../services/notification/notificationService';
import { Notification } from '../../interface/NotificationInterface';

const Notifications = () => {
    const [notifications, setNotifications] = useState<Notification[]>([]);
    const [totalItemsCount, setTotalItemsCount] = useState<number>(0);
    const [isLoading, setIsLoading] = useState(true);
    const [showNoNotification, setShowNoNotification] = useState(false);
    const [expandedNotificationIndex, setExpandedNotificationIndex] = useState(-1);
    const [currentPage, setCurrentPage] = useState(0);
    const [recordSize, setRecordSize] = useState(10);

    const totalFilteredItems = notifications.length;

    useEffect(() => {
        fetchNotificationDetails(currentPage);
    }, []);

    async function fetchNotificationDetails(currentPage: number) {
        try {
            const notification = await getAllNotification(currentPage);
            if (notification) {
                setTotalItemsCount((notification as any).page_count);
                setNotifications((notification as any).data as Notification[]);
            }
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
            setShowNoNotification(true);
        }
    }

    const paginateArray = async (pageNumber: number) => {
        try {
            await fetchNotificationDetails(pageNumber);
            setCurrentPage(pageNumber);
        } catch (error: any) {
            console.error(error);
        }
    };

    function formatDate(date: string) {
        return new Date(date).toLocaleDateString('en-us', {
            weekday: 'long',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
        });
    }

    const toggleExpandedNotification = async (index: number, id: string) => {
        setExpandedNotificationIndex((prevIndex) => (prevIndex === index ? -1 : index));
        await updateNotification(id, { data: { bIsRead: true } });
        try {
            fetchNotificationDetails(currentPage);
        } catch (error: any) {
            console.error(error);
        }
    };

    return (
        <div>
            <h1 className='notification-heading'>Notifications</h1>
            {isLoading ? (
                <div className='d-flex justify-content-center align-items-center' style={{ height: '20vh' }}>
                    <Spinner animation='border' variant='primary' />
                </div>
            ) : null}
            {showNoNotification && notifications.length === 0 ? (
                <div className='no-notification'>
                    <div className='no-notification-icon'>
                        <FontAwesomeIcon icon={faBell} />
                    </div>
                    <div className='no-notification-text'>No Notification Found</div>
                </div>
            ) : (
                <div className='accordion' id='notificationAccordion'>
                    {notifications.map((item, index) => (
                        <div className='accordion-item my-3' key={index}>
                            <h2 className='accordion-header' id={`heading${index}`}>
                                <button
                                    className={`accordion-button ${
                                        expandedNotificationIndex === index ? '' : 'collapsed'
                                    }`}
                                    type='button'
                                    onClick={() => toggleExpandedNotification(index, item._id)}
                                    data-bs-toggle='collapse'
                                    data-bs-target={`#collapse${index}`}
                                    aria-expanded={expandedNotificationIndex === index}
                                    aria-controls={`collapse${index}`}
                                >
                                    <div className='noti-icon'>
                                        <FontAwesomeIcon icon={faBell} className='mr-2' />
                                    </div>
                                    <div className={`noti-message  ${item.bIsRead === true ? '' : 'readed-msg'}`}>
                                        {formatDate(item.createdAt)}
                                    </div>
                                </button>
                            </h2>
                            <div
                                id={`collapse${index}`}
                                className={`accordion-collapse collapse ${
                                    expandedNotificationIndex === index ? 'show' : ''
                                }`}
                                aria-labelledby={`heading${index}`}
                                data-bs-parent='#notificationAccordion'
                            >
                                <div className={`accordion-body`}>{item.sMessage}</div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
            <div className='d-flex justify-content-between align-items-center pagination'>
                <div className='dataTables_info' id='DataTables_Table_0_info ' role='status' aria-live='polite'>
                    {totalFilteredItems > 0 ? `Showing ${currentPage + 1} of ${totalItemsCount + 1} pages` : ''}
                </div>
                <div className='dataTables_paginate paging_simple_numbers' id='DataTables_Table_0_paginate'>
                    <Button
                        className='paginate_button previous me-2'
                        aria-controls='DataTables_Table_0'
                        data-dt-idx={0}
                        tabIndex={-1}
                        id='DataTables_Table_0_previous'
                        onClick={() => {
                            paginateArray(currentPage - 1);
                        }}
                        disabled={currentPage === 0}
                    >
                        Previous
                    </Button>
                    <span>
                        <Button
                            className='paginate_button-current'
                            aria-controls='DataTables_Table_0'
                            data-dt-idx={1}
                            tabIndex={0}
                        >
                            {currentPage + 1}
                        </Button>
                    </span>
                    <Button
                        className='paginate_button next ms-2'
                        aria-controls='DataTables_Table_0'
                        data-dt-idx='2'
                        tabIndex={-1}
                        id='DataTables_Table_0_next'
                        onClick={() => {
                            paginateArray(currentPage + 1);
                        }}
                        disabled={currentPage == totalItemsCount - 1}
                    >
                        Next
                    </Button>
                </div>
            </div>
        </div>
    );
};
export default Notifications;
