import { IDriver } from 'logistic-packages';
import { api, baseUrl } from '../../api';

export const getAllDriverApi = async () => {
    const result = await api.get(`${baseUrl}/admin/driver`);
    return result.data;
};
export const addDriver = async (reqBody:IDriver) => {
    const result = await api.post(`${baseUrl}/admin/driver`,{data:reqBody});
    return result.data;
};
export const deleteDriverApi = async (id: string) => {
    const result = await api.delete(`${baseUrl}/admin/driver/${id}`);
    return result.data;
};
export const driverStatusApi = async (id: string, isActive: boolean) => {
    const result = await api.patch(`${baseUrl}/admin/driver/${id}`, {
        data: { bIsActive: !isActive },
    });
    return result.data;
};
export const driverApproveStatusApi = async (id: string, isApproved: boolean) => {
    const result = await api.patch(`${baseUrl}/admin/driver/${id}`, {
        data: { bIsApproved: !isApproved },
    });
    return result.data;
};
