/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, ChangeEvent, useEffect } from "react";
import "./EditCustomer.css";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { ClickAwayListener } from "@material-ui/core";
import axios from "axios";

interface CustomerDetails {
  sName: string;
  sEmail: string;
  aPhoneNo: string;
  tAddress: { sKey: number; sValue: string }[];
}

export const EditCustomer = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState(true);
  const [details, setDetails] = useState<CustomerDetails>({
    sName: "",
    sEmail: "",
    aPhoneNo: "",
    tAddress: [],
  });
  const apiUrl = process.env.REACT_APP_API_URL as string;
  const [isFormEdited, setIsFormEdited] = useState(false);

  const { id: customerId } = useParams<{ id: string }>();

  useEffect(() => {
    if (customerId) {
      fetchCustomerData(customerId);
    } else {
      setIsLoading(false);
    }
  }, []);

  const navigate = useNavigate();

  const fetchCustomerData = (id: string) => {
    axios
      .get(apiUrl + `/admin/customer/${id}`)
      .then((res) => {
        setDetails(res.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const inputhandler = async (
    e:
      | ChangeEvent<HTMLInputElement>
      | ChangeEvent<HTMLSelectElement>
      | ChangeEvent<HTMLTextAreaElement>
  ) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
    setIsFormEdited(true);
  };

  const handlesubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const CustomerData = {
      data: {
        sName: details.sName,
        sEmail: details.sEmail,
        aPhoneNo: details.aPhoneNo,
        tAddress: details.tAddress,
      },
    };

    if (customerId) {
      axios
        .patch(apiUrl + `/admin/customer/${customerId}`, CustomerData)
        .then((res) => {
          navigate("/users/customers");
        })
        .catch((err) => {
          toast.error(err.response.data.message, {
            position: "top-center",
            theme: "colored",
          });
        });
    }

    
  };

  return (
    <>
      {isLoading ? (
        <div className="bg-loader">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : null}
      <div className="mx-2 ">
        <div className="user-content-header py-4">
          <ToastContainer />
          <Row className="mb-2">
            <Col sm="6">
              <h1 className="m-0 text-dark">Customers</h1>
            </Col>

            <Col sm="6">
              <ol className="breadcrumb float-sm-right justify-content-end">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/users/customers">Customers</Link>
                </li>
                <li className="breadcrumb-item active">Edit</li>
              </ol>
            </Col>
          </Row>
        </div>
        <Container fluid className="main-container bg-white">
          <Row className="title-row">
            <p>Edit Customers</p>
          </Row>

          <Row className="form-group">
            <Col sm={12} className="form-fields op">
              <p>Customer Name</p>
              <input
                name="sName"
                onChange={(e) => inputhandler(e)}
                placeholder="Enter Name"
                className="new-page-input"
                type="text"
                value={details.sName}
              />
            </Col>
          </Row>
          <Row className="form-group">
            <Col sm={12} className="form-fields op">
              <p>Email</p>
              <input
                name="sEmail"
                onChange={(e) => inputhandler(e)}
                placeholder="Enter Email"
                className="new-page-input"
                type="email"
                value={details.sEmail ? details.sEmail : "---"}
                disabled={!!customerId}
              />
            </Col>
          </Row>
          <Row className="form-group">
            <Col sm={12} className="form-fields op">
              <p>Contact No</p>
              <input
                name="aPhoneNo"
                onChange={(e) => inputhandler(e)}
                placeholder="Enter Contact No"
                className="new-page-input"
                type="text"
                value={details.aPhoneNo}
              />
            </Col>
          </Row>
          <br></br>
          {/* <Row className="form-group">
            <Col sm={12} className="form-fields op mb-3">
              <p>Address</p>
              <input
                name="tAddress"
                onChange={(e) => inputhandler(e)}
                placeholder="Enter Address"
                className="new-page-input"
                type="text"
                value={
                  details.tAddress.length > 0
                    ? details.tAddress[details.tAddress.length - 1].sValue
                    : ""
                }
              />
            </Col>
          </Row> */}
        </Container>
        <div className="card-footer text-center mb-3">
          <button
            className="btn ripple btn-primary btnDataSave"
            name="btnDataSave"
            onClick={handlesubmit}
            disabled={!isFormEdited}
          >
            {" "}
            Update Customer
          </button>
        </div>
      </div>
    </>
  );
};
