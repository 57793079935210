import React, { useState } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';

interface SolveModalProps {
  show: boolean;
  onClose: () => void;
}

const SolveModal: React.FC<SolveModalProps> = ({ show, onClose }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    address: '',
    solve: '',
    reportProblem: '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onClose();
  };

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header>
        <Modal.Title>Solve Reports</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-6">
                {/* <Form.Label>Name</Form.Label> */}
                <Form.Control type="text" name="name" value={formData.name} onChange={handleChange} required placeholder='Name' />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-6">
                {/* <Form.Label>Phone</Form.Label> */}
                <Form.Control type="tel" name="phone" value={formData.phone} onChange={handleChange} required placeholder='Phone' />
              </Form.Group>
            </Col>
          </Row>

          <Col md={12}>
            <Form.Group className="mb-6">
              {/* <Form.Label>Email</Form.Label> */}
              <Form.Control type="email" name="email" value={formData.email} onChange={handleChange} required placeholder='Email' className='mt-2' />
            </Form.Group>
          </Col>
          <Col md={12}>
            <Form.Group className="mb-6">
              {/* <Form.Label>Address</Form.Label> */}
              <Form.Control as="textarea" name="address" value={formData.address} onChange={handleChange} required placeholder='Address' className='mt-2' />
            </Form.Group>
          </Col>
          <Form.Group className="mb-6">
            {/* <Form.Label className='mt-4'>What do you want to solve?</Form.Label> */}
            <Form.Control type="text" name="solve" value={formData.solve} onChange={handleChange} required placeholder='What do you want to solve?' className='mt-2' />
          </Form.Group>
          <Form.Group className="mb-6">
            {/* <Form.Label>Add Report Problem</Form.Label> */}
            <Form.Control as="textarea" name="reportProblem" value={formData.reportProblem} onChange={handleChange} required placeholder='Add Report Problem' className='mt-2' />
          </Form.Group>
          <Row className='mt-4'>
            <Col md={4}>
              <Button variant="success" type='submit'>
                Resolved
              </Button>
            </Col>
            <Col md={4}>
              <Button variant="danger">
                Unresolved
              </Button>
            </Col>
            <Col md={4}>
              <Button variant="warning">
                Pending
              </Button>
            </Col>
          </Row>

        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default SolveModal;
export { };
