import {
  addVehicleApi,
  deleteVehicleApi,
  getAllUnAssignedVehicleApi,
  getAllVehicleApi,
  syncVehicleApi,
  updateVehicleApi,
} from "../../api/vehicle/vehicleApi";

export const getAllVehicles = () => {
  return new Promise((resolve, reject) => {
    getAllVehicleApi()
      .then((data) => {
        resolve(data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const getAllUnAssignedVehicle = () => {
  return new Promise((resolve, reject) => {
    getAllUnAssignedVehicleApi()
      .then((data) => {
        resolve(data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const addVehicle = (reqbody: any) => {
  return new Promise((resolve, reject) => {
    addVehicleApi(reqbody)
      .then((data) => {
        resolve(data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const updateVehicle = (id: string, vehicleData: any) => {
  return new Promise((resolve, reject) => {
    updateVehicleApi(id, vehicleData)
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteAVehicle = (id: string) => {
  return new Promise((resolve, reject) => {
    deleteVehicleApi(id)
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const syncVehicles = () => {
  return new Promise((resolve, reject) => {
    syncVehicleApi()
      .then((data) => {
        resolve(data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
