import React, { useEffect, useState } from "react";
import "./Dashboard.css";
// import users from "../../assets/images/users.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowAltCircleRight,
  faCubes,
  faIdCard,
  faTruck,
  faTruckFast,
  faTruckRampBox,
  faUsers,
  faWarehouse,
} from "@fortawesome/free-solid-svg-icons";
// import { Container, Table } from "react-bootstrap";
import PieChartComponent from "../../components/pieChart/PieChartComponent";
import BarChartComponent from "../../components/barChart/barChartComponent";

import { getAllDashboardApi } from "../../api/dashboard/dasboardApi";
import { Link } from "react-router-dom";
import { faHourglassHalf } from "@fortawesome/free-regular-svg-icons";

export const Main: React.FC = () => {
  const [dashboardData, setDashboardData] = useState<any>();

  useEffect(() => {
    getDashboardData();
  }, []);

 

  const getDashboardData = async () => {
    try {
      let dashboardGetData = await getAllDashboardApi();
      setDashboardData(dashboardGetData.data);
      
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <>
      <div className="container-fluid">
        <div className="row mt-2">
          <div className="col-lg-3 col-6 my-2">
            <div className="small-box box">
              <div className="inner">
                <div className="icon">
                  <FontAwesomeIcon icon={faUsers} />
                </div>

                <div className="dash-text">
                  <p>Total Users</p>
                  <h3>{dashboardData?.aUser}</h3>
                </div>
              </div>
              <Link to="/users/customers" className="small-box-footer">
                More info <FontAwesomeIcon icon={faArrowAltCircleRight} />
              </Link>
            </div>
          </div>
          <div className="col-lg-3 col-6 my-2">
            <div className="small-box box">
              <div className="inner">
                <div className="icon">
                  <FontAwesomeIcon icon={faIdCard} />
                </div>

                <div className="dash-text">
                  <p>Active Drivers</p>
                  <h3>{dashboardData?.aActiveDriver}</h3>
                </div>
              </div>
              <Link to="/users/drivers" className="small-box-footer">
                More info <FontAwesomeIcon icon={faArrowAltCircleRight} />
              </Link>
            </div>
          </div>

          <div className="col-lg-3 col-6 my-2">
            <div className="small-box box">
              <div className="inner">
                <div className="icon">
                  <FontAwesomeIcon icon={faTruck} />
                </div>

                <div className="dash-text">
                  <p>Active Vehicles</p>
                  <h3>{dashboardData?.aActiveVehicle}</h3>
                </div>
              </div>
              <Link to="/vehicles" className="small-box-footer">
                More info <FontAwesomeIcon icon={faArrowAltCircleRight} />
              </Link>
            </div>
          </div>
          <div className="col-lg-3 col-6 my-2">
            <div className="small-box box">
              <div className="inner">
                <div className="icon">
                  <FontAwesomeIcon icon={faWarehouse} />
                </div>

                <div className="dash-text">
                  <p>Warehouses Count</p>
                  <h3>{dashboardData?.aWareHouse}</h3>
                </div>
              </div>
              <Link to="/warehouse" className="small-box-footer">
                More info <FontAwesomeIcon icon={faArrowAltCircleRight} />
              </Link>
            </div>
          </div>
          <div className="col-lg-3 col-6 my-2">
            <div className="small-box box">
              <div className="inner">
                <div className="icon">
                  <FontAwesomeIcon icon={faTruckFast} />
                </div>

                <div className="dash-text">
                  <p>Yearly Complete </p>
                  <h3>{dashboardData?.aYearDeliveredConsignmentCount?dashboardData?.aYearDeliveredConsignmentCount:0}</h3>
                </div>
              </div>
              <Link to="/consignments" className="small-box-footer">
                More info <FontAwesomeIcon icon={faArrowAltCircleRight} />
              </Link>
            </div>
          </div>
          <div className="col-lg-3 col-6 my-2">
            <div className="small-box box">
              <div className="inner">
                <div className="icon">
                  <FontAwesomeIcon icon={faTruckRampBox} />
                </div>
    
                <div className="dash-text">
                  <p>Monthly Delivered</p>
                  <h3>{dashboardData?.aTotalmonthlyDelivered?dashboardData?.aTotalmonthlyDelivered:0}</h3>
                </div>
              </div>
              <Link to="/consignments" className="small-box-footer">
                More info <FontAwesomeIcon icon={faArrowAltCircleRight} />
              </Link>
            </div>
          </div>
          <div className="col-lg-3 col-6 my-2">
            <div className="small-box box">
              <div className="inner">
                <div className="icon">
                  <FontAwesomeIcon icon={faHourglassHalf} />
                </div>

                <div className="dash-text">
                  <p>Monthly Pending </p>
                  <h3>{dashboardData?.aTotalmonthlyNonDelivered?dashboardData?.aTotalmonthlyNonDelivered:0}</h3>
                </div>
              </div>
              <Link to="/consignments" className="small-box-footer">
                More info <FontAwesomeIcon icon={faArrowAltCircleRight} />
              </Link>
            </div>
          </div>
          <div className="col-lg-3 col-6 my-2">
            <div className="small-box box">
              <div className="inner">
                <div className="icon">
                  <FontAwesomeIcon icon={faCubes} />
                </div>

                <div className="dash-text">
                  <p>Total Orders(Daily)</p>
                  
                  <h3>{dashboardData?.aTodayCount?dashboardData?.aTodayCount:0}</h3>
                </div>
              </div>
              <Link to="/consignments" className="small-box-footer">
                More info <FontAwesomeIcon icon={faArrowAltCircleRight} />
              </Link>
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-md-6">
            <div className="barchart-container">
              <h4 className="chart-title">Consignment Order Analysis</h4>
              <div className="bar-chart d-flex justify-content-center mt-2">
                <BarChartComponent />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="piechart-container">
              <h4 className="chart-title">Order Details Progress</h4>
              <div className="pie-chart d-flex justify-content-center">
                <PieChartComponent />
              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  );
};
