import React, { useState, useEffect } from "react";
import "./Login.css";
import MailIcon from "@material-ui/icons/Mail";
import { messaging } from '../../firebase';
import { getToken } from '@firebase/messaging';
import EyeIcon from "@material-ui/icons/RemoveRedEye";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import { useSelector, useDispatch } from "react-redux";
import { signinUser, signinToken } from "../../redux/auth/authSlice";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { addDeviceToken } from "../../services/login/LoginService";

interface User {
  sEmail: string;
  sPassword: string;
}
export const Login: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [user, setUser] = useState<User>({
    sEmail: "",
    sPassword: "",
  });
  const auth = useSelector((state: any) => state.auth.value);
  const apiUrl = process.env.REACT_APP_API_URL;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    let localData = JSON.parse(localStorage.getItem("user") || "null");
    let localDataToken = JSON.parse(localStorage.getItem("sToken") || "null");

    const isAuth =
      (auth && auth.token && auth.user) || (localData && localDataToken)
        ? true
        : false;
    if (isAuth) {
      navigate("/dashboard");
    }
    // eslint-disable-next-line
  }, []);

  const handleLogin = () => {
    setIsLoading(true);
    axios
      .post(apiUrl + "/auth/sign-in", { data: user })
      .then((res) => {
        dispatch(signinUser(res.data.data.data));
        dispatch(signinToken(res.data.data.sToken));
        requestPermission(res.data.data._id)

        if (res.data.data)
          localStorage.setItem("user", JSON.stringify(res.data.data));
        if (res.data.sToken)
          localStorage.setItem("sToken", JSON.stringify(res.data.sToken));

        navigate("/dashboard");
      })
      .catch((err) => {
        console.error(err);
        toast.error("Invalid Email Or Password !", {
          position: "top-center",
          theme: "colored",
        });
      })
      .finally(() => setIsLoading(false));
  };

  const requestPermission=async(userId?:string)=>{
      const permission=await Notification.requestPermission();
      if(permission==="granted" && userId){
        const token=await getToken(messaging,{vapidKey:"BKjNoV99ck9CpmcMz484-SVd0njtp9YhmWM1fEkwKybkwbozYXVbyGik5ozEox6YnVDOyoPkuU0L7BUIvfrUcb8"})        
       await addDeviceToken({"data":{sToken:token, eGroupType:"Admin",tUserId:`${userId}`}})
      }
    }
  const inputhandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };
  return (
    <>
      <ToastContainer />
      <div className="login-container">
        <div className="login-box">
          <div className="login-title">
            <h1>ShineTrack</h1>
          </div>
          <p className="login-msg">Sign in to start your session</p>

          <div className="form-group login-form">
            <div className="login-input-group">
              <input
                type="email"
                placeholder="Email"
                name="sEmail"
                required
                onChange={(e) => inputhandler(e)}
              />
              <i className="input-icon">
                <MailIcon />
              </i>
            </div>
          </div>

          <div className="form-group login-form">
            <div className="login-input-group">
              <input
                type={passwordVisibility ? "text" : "password"}
                placeholder="Password"
                name="sPassword"
                required
                onChange={(e) => inputhandler(e)}
              />
              <i
                className="input-icon"
                onClick={() => {
                  setPasswordVisibility(!passwordVisibility);
                }}
              >
                {" "}
                {passwordVisibility ? <VisibilityOff /> : <EyeIcon />}
              </i>
            </div>
          </div>

          <div className="login-button">
            <button
              type="submit"
              className="btn-submit"
              onClick={handleLogin}
              disabled={
                user.sEmail.length > 0 && user.sPassword.length > 0
                  ? false
                  : true
              }
            >
              {isLoading ? (
                <Spinner animation="border" variant="light" />
              ) : (
                <span>Sign In</span>
              )}
            </button>
          </div>
          <Link to="/forgot-password">Forgot Password ?</Link>
        </div>
      </div>
    </>
  );
};
