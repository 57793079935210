export interface AccountDetails {
  _id?: string;
  sName: string;
  sUrl: string;
  sAuthType: AuthType;
  sUserName?: string;
  sToken?: string;
  sPassword?: string;
  sTag?: string;
  sSingleTracking?: string;
}
export interface AvailableVendor {
  sName: string;
  sAPIUrl?: string;
  sTag?: string;
}

export enum AuthType {
  BASIC = 0,
  NTLM = 1,
  NO = 2,
  BEARER = 3
}
