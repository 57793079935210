import React, { useState } from 'react';
import { Modal, Button, Form, Row, Col, Spinner } from 'react-bootstrap';
import { createUserBranch } from '../../../services/generic/organizationService';
import EyeIcon from "@material-ui/icons/RemoveRedEye";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { getSetEmployeePassword } from '../../../services/employee/employeeService';
import { toast } from 'react-toastify';

interface ChangePasswordProps {
    show: boolean;
    id?: string;
    onClose: () => void;
    setPasswordEditId:any;
    width?: string

}

const ChangePasswordModal: React.FC<ChangePasswordProps> = ({ show, onClose, width,id,setPasswordEditId }) => {
    const [formData, setFormData] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [passwordVisibility, setPasswordVisibility] = useState(false);

    const handleSubmit = async(e: any) => {
        setIsLoading(true)
        try {
            
            await getSetEmployeePassword(id||'',formData)
            setPasswordEditId()
            toast.success('Password changed successfully', {
                position: "top-center",
                theme: "colored",
              });
              setFormData('')
        } catch (error:any) {
            toast.error(error.response.data.message, {
                position: "top-center",
                theme: "colored",
              });
        }
        setIsLoading(false)
        
    };

    return (
        <Modal show={show} onHide={onClose}  contentClassName={width === "60%" ? 'modal-w-xl' : 'modal-w-sm'}>
            <Modal.Header>
                <Modal.Title>Change Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {/* <Form onSubmit={handleSubmit}> */}
                <Row>
                    <Col md={12}>

                    <div className="form-group login-form">
            <div className="login-input-group">
              <input
                type={passwordVisibility ? "text" : "password"}
                placeholder="Password"
                name="sPassword"
                required
                value={formData} onChange={(e)=>setFormData(e.target.value)}
              />
              <i
                className="input-icon"
                onClick={() => {
                  setPasswordVisibility(!passwordVisibility);
                }}
              >
                {" "}
                {passwordVisibility ? <VisibilityOff /> : <EyeIcon />}
              </i>
            </div>
          </div>
                    </Col>
                </Row>


                <Row className='mt-4' style={{ justifyContent: 'center' }}>
                    <Button variant="btn btn-primary" style={{ width: 'auto' }} disabled={formData.length < 8||isLoading} onClick={(e) => handleSubmit(e)}>
                        {
                            isLoading?<Spinner animation='border' variant='light' size='sm' />:'Change'
                        }
                        
                        
                    </Button>
                    &nbsp;
                    <Button variant="btn btn-secondary" style={{ width: 'auto' }} onClick={(e) => {setPasswordEditId();setFormData('')}}>
                        Cancel
                    </Button>

                </Row>

                {/* </Form> */}
            </Modal.Body>
        </Modal>
    );
};

export default ChangePasswordModal;
export { };