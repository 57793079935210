import { getBarChartApi } from "../../api/barchart/barChartApi";




export const getAllBarChart = (): Promise<any> => {
    return new Promise((resolve, reject) => {
        getBarChartApi()
            .then((data) => {
                resolve(data.data );
            })
            .catch((error) => {
                reject(error);
            });
    });
}