import { IWareHouse } from "logistic-packages";
import { api, baseUrl } from "../../api";

export const totalWarehouseItemsListApi = async () => {
  const result = await api.get(`${baseUrl}/admin/warehouse/order-item`);
  return result.data;
};
export const getAllWarehousesApi = async () => {
  const result = await api.get(`${baseUrl}/admin/warehouse`);
  return result.data;
};
export const addWarehouseApi = async (reqbody: IWareHouse) => {
  const result = await api.post(`${baseUrl}/admin/warehouse`, reqbody);
  return result.data;
};
export const updateWarehouseApi = async (id: string, warehouseData: any) => {
  const result = await api.patch(
    `${baseUrl}/admin/warehouse/${id}`,
    warehouseData
  );
  return result.data;
};
// export const getManagerApi = async () =>{
//     const result = await api.get(`${baseUrl}/admin/organization/employee`);
//     return result.data;
// }
export const getManagerApi = async () => {
  const result = await api.get(
    `${baseUrl}/admin/organization/user-role?sTag=atTagManager`
  );
  return result.data;
};
