import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { DataTable } from '../../table/Table';
import './MergeConsignmentModal.css';
import { mergeConsignment } from '../../../services/consignment/consignmentService';
import { toast } from 'react-toastify';
export const MergeConsignmentModal: React.FC<any> = (props) => {
    const [showModal, setShowModal] = useState(true);
    const [mergeId, setMergeId] = useState('');

    const columnDefinitions = {
        Consignment: [
            { label: 'ID', key: '_id' },
            { label: 'Consignment Group No', key: 'grpConsignmentID' },
            { label: 'Vehicle Reg. No.', key: 'vRegNo' },
            { label: 'Assigned Drivers', key: 'assignedDriver' },
            { label: 'Action', key: 'sStatus' },
        ],
    };
    const onClose = () => {
        setShowModal(false);
    };
    const handleMergeConsignments = async (item: any) => {
        setMergeId(item._id);

        try {
            const payLoad = {
                sPrimaryConsignmentID: props.consignmentID,
                sMergeConsignmentIDs: [item._id],
            };
            const response = await mergeConsignment(payLoad);
            if ((response as any).status === 201||(response as any).status === 200) {
                props.reFetchConsignmentData();
                toast.success('Consignment Merged successfully!', {
                    position: 'top-center',
                    theme: 'colored',
                });
                setTimeout(() => {
                    onClose();
                    setMergeId('')
                }, 1000);
            }
        } catch (error: any) {
            toast.error('Something went wrong', {
                position: 'top-center',
                theme: 'colored',
            });
        }
    };
    return (
        <Modal show={showModal} onHide={onClose} contentClassName='modal-w-xxl'>
            <Modal.Header>
                <Modal.Title>Merge Consignment</Modal.Title>
            </Modal.Header>
            <Modal.Body className='modal-body'>
                <div className='table-parent'>
                    <DataTable
                        columnDefinitions={columnDefinitions.Consignment}
                        paginatedItems={props.pagination}
                        isLoading={showModal}
                        handleMerging={handleMergeConsignments}
                        tableFor='Consignment'
                        trackerColumn='sStatus'
                        hideActions={true}
                        mergeId={mergeId}
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
};
