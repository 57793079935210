/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, ChangeEvent, useEffect } from "react";
import "./EditReport.css";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-regular-svg-icons";


interface ReportDetails {
  sTitle: string;
  sMessage: string;
  sUserName: string;
  bIsSolved: boolean;
  tReply: IReply[];
}
interface IReply {
  sReply?: string;
  tReplyBy?: string;
  replyDateTime?: Date;
}

export const EditReport = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState(true);
  const [userId, setUserId] = useState("");

  const [details, setDetails] = useState<ReportDetails>({
    sTitle: "",
    sMessage: "",
    sUserName: "",
    bIsSolved: false,
    tReply: []
  });
  const apiUrl = process.env.REACT_APP_API_URL as string;
  const [isFormEdited, setIsFormEdited] = useState(false);

  const [replyMessage, setReplyMessage] = useState("");

  const { id: ReportId } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const fetchReportDetails = (id: string) => {
    axios
      .get(apiUrl + `/admin/report/${id}`)
      .then((res) => {
        setDetails(res.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setUserId(JSON.parse(localStorage.getItem("user") || "{}")._id);

    if (ReportId) {
      fetchReportDetails(ReportId);
      const pollInterval = setInterval(() => {
        fetchReportDetails(ReportId);
      }, 5000);

      return () => {
        clearInterval(pollInterval);
      };
    } else {
      setIsLoading(false);
    }
  }, [ReportId]);

  const inputhandler = async (
    e:
      | ChangeEvent<HTMLInputElement>
      | ChangeEvent<HTMLSelectElement>
      | ChangeEvent<HTMLTextAreaElement>
  ) => {
    setReplyMessage(e.target.value);
    setIsFormEdited(true);
  };

  const handlesubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    sendMessage();
  };

  const handleSolve = () => {
    if (!details.bIsSolved) {
      
      const updatedDetails = { ...details, bIsSolved: true };
      setDetails(updatedDetails);
  
     
      if (ReportId) {
        axios
          .patch(apiUrl + `/admin/report/${ReportId}`, {
            data: { bIsSolved: true } 
          })
          .then((res) => {
            toast.success("Problem Solved Successfully", {
              position: "top-center",
              theme: "colored"
            });
            setTimeout(() => {
              navigate("/reports");
            }, 1000);
          })
          .catch((err) => {
           
            toast.error(err.response.data.message, {
              position: "top-center",
              theme: "colored"
            });
          });
      }
    }
  };
  
  

  const sendMessage = () => {
    if (replyMessage.trim() !== "") {
      var user = JSON.parse(localStorage.getItem("user") as any);
      details.tReply.push({
        sReply: replyMessage,
        replyDateTime: new Date(),
        tReplyBy: user._id
      });
      const ReportData = {
        data: {
          sTitle: details.sTitle,
          sMessage: details.sMessage,
          sUserName: details.sUserName,
          bIsSolved: details.bIsSolved,
          tReply: details.tReply
        }
      };

      if (ReportId) {
        axios
          .patch(apiUrl + `/admin/report/${ReportId}`, ReportData)
          .then((res) => {
            setReplyMessage("");
          })
          .catch((err) => {
            toast.error(err.response.data.message, {
              position: "top-center",
              theme: "colored"
            });
          });
      }
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      sendMessage();
    }
  };

  return (
    <>
      {isLoading ? (
        <div className="bg-loader">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : null}
      <div className="mx-2 ">
        <div className="user-content-header py-4">
          <ToastContainer />
          <Row className="mb-2">
            <Col sm="6">
              <h1 className="m-0 text-dark">Reports</h1>
            </Col>

            <Col sm="6">
              <ol className="breadcrumb float-sm-right justify-content-end">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/reports">Reports</Link>
                </li>
                <li className="breadcrumb-item active">Edit</li>
              </ol>
            </Col>
          </Row>
        </div>
        <Container fluid className="main-container bg-white">
          <Row className="title-row solve-report-row">
            <Col sm={6}>
              <p>Solve Report</p>
            </Col>
            <Col sm={6}>
              <Button
                variant={details.bIsSolved ? "success" : "info"}
                className="user float-end add_role text-white"
                onClick={handleSolve}
                disabled={details.bIsSolved}
              >
                {details.bIsSolved ? "Solved" : "Solve Problem"}
              </Button>
            </Col>
          </Row>

          <Row className="form-group">
            <Col sm={6} className="form-fields">
              <div className="report-problem">
                <div className="problem-text">Problem: </div>
                <div className="report-title">{details.sTitle}</div>
              </div>
            </Col>
            <Col sm={6} className="form-fields ">
              <div className="report-problem">
                <div className="problem-text">Message: </div>
                <div className="report-title">{details.sMessage}</div>
              </div>
            </Col>

            <Col sm={12} className="form-fields report-tab">
              <div className="replies-container">
                <div className="chat-container">
                  {details.tReply.map((reply, index) => (
                    <div
                      key={index}
                      className={
                        userId === reply?.tReplyBy
                          ? "reply-item my-text"
                          : "reply-item"
                      }
                    >
                      <div>
                        <div className="message-text">{reply?.sReply}</div>

                        <div className="message-time">
                          {reply?.replyDateTime &&
                            new Date(reply?.replyDateTime).toLocaleString()}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="chat-reply">
                  <input
                    name="tReply"
                    onChange={(e) => inputhandler(e)}
                    onKeyPress={handleKeyPress}
                    value={replyMessage}
                    placeholder="Type a message..."
                    className="chat-input"
                  />
                  <button
                    className="btn ripple btn-primary DataSave chat-send-button"
                    name="btnDataSave"
                    onClick={handlesubmit}
                    disabled={replyMessage.trim() === ""}
                  >
                    <FontAwesomeIcon icon={faPaperPlane} />
                  </button>
                </div>
              </div>
              <div className="reply-message">
                <Col sm={6} className="form-fields report mb-4"></Col>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
