/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, ChangeEvent, useEffect } from 'react';
import './AddGroup.css';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { IManageAccess } from 'logistic-packages';
import { addGroup, getAllAccess, updateGroup } from '../../../../services/group/groupService';

interface GroupDetails {
    sName: string;
    tAccess: string;
    sTag: string; 
}

export const AddGroup: React.FC = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [details, setDetails] = useState<GroupDetails>({
        sName: '',
        tAccess: '',
        sTag: '',
      
    });
    const [manageAccessList, setManageAccessList] = useState<IManageAccess[]>([]);

    // const apiUrl = process.env.REACT_APP_LOCAL_API_URL as string;

    const apiUrl = process.env.REACT_APP_API_URL as string;
    const [isFormEdited, setIsFormEdited] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        loadInitData();
        if (groupId) {
            fetchGroupData(groupId);
        } else {
            setIsLoading(false);
        }
    }, []);

    async function loadInitData() {
        try {
          await getAllAccess().then((access) =>
            setManageAccessList(access as IManageAccess[])
          );
          
          
        } catch (err: any) {
          console.error(err);
        } finally {
          setIsLoading(false);
        }
      }

    const navigate = useNavigate();
    const { search } = useLocation();
    const { id: groupId } = useParams<{ id: string }>();

    const inputhandler = async (
        e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement> | ChangeEvent<HTMLTextAreaElement>
    ) => {
        setDetails({ ...details, [e.target.name]: e.target.value });
        setIsFormEdited(true);
    };

    function isDataNotFilled() {
        return (
            !isFormEdited ||
            !(
                details.sName &&
                details.tAccess &&
                details.sTag 
            )
        );
    }

    const fetchGroupData = (id: string) => {
        axios
            .get(apiUrl + `/admin/organization/user-group/${id}`)
            .then((res) => {
                setDetails(res.data.data);
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
            });
    };

    const handleSubmit = async (e: { preventDefault: () => void }) => {
        e.preventDefault();
        setIsSubmitting(true);
        const groupData = {
            data: {
               
                sName: details.sName,
                tAccess: details.tAccess,
                sTag: details.sTag,
               
            },
        };

        if (groupId) {
            updateGroup(groupId,groupData)
                .then((res) => {
                    navigate(`/settings?activeTab=group${search}`);
                })
                .catch((err) => {
                    toast.error(err.response.data.message, {
                        position: 'top-center',
                        theme: 'colored',
                    });
                });
        } else {
            await addGroup(groupData)
                .then((res) => {
                    toast.success('Group Added Successfully', {
                        position: 'top-center',
                        theme: 'colored',
                    });
                    setTimeout(() => {
                        navigate(`/settings?activeTab=group${search}`);
                    }, 1000);
                })
                .catch((err) => {
                    console.error(err);
                    toast.error(err.response.data.message, {
                        position: 'top-center',
                        theme: 'colored',
                    });
                    setIsSubmitting(false);
                });
        }
    };

    return (
        <>
            {isLoading ? (
                <div className='bg-loader'>
                    <Spinner animation='border' variant='primary' />
                </div>
            ) : null}
            <div className='mx-2 '>
                <div className='user-content-header py-4'>
                    <ToastContainer />
                    <Row className='mb-2'>
                        <Col sm='6'>
                            <h1 className='m-0 text-dark'>Groups</h1>
                        </Col>

                        <Col sm='6'>
                            <ol className='breadcrumb float-sm-right justify-content-end'>
                                <li className='breadcrumb-item'>
                                    <Link to='/dashboard'>Home</Link>
                                </li>
                                <li className='breadcrumb-item'>
                                <Link to={{ pathname: '/settings', search: '?activeTab=group' }}>Groups</Link>

                                </li>
                                <li className='breadcrumb-item active'>{groupId ? 'Edit' : 'Create'}</li>
                            </ol>
                        </Col>
                    </Row>
                </div>
                <Container fluid className='main-container bg-white'>
                    <Row className='title-row'>{groupId ? <p>Edit Group</p> : <p>Add Group</p>}</Row>

                    <Row className='form-group'>
                       
                        <Col sm={12} md={12} className='form-fields op'>
                        <p>Group Name</p>
                            <input
                                name='sName'
                                onChange={(e) => inputhandler(e)}
                                placeholder='Enter Name'
                                className='vehical-input-field'
                                type='text'
                                value={details.sName}
                                disabled={!!groupId}
                                
                            />
                        </Col>
                    </Row>
                    <Row className='form-group'>
                        <Col sm={12} md={6} className='form-fields op mb-4'>
                        <p>Access Type</p>
                        <select
                name="tAccess"
                required
                value={details.tAccess ? details.tAccess : ""}
                onChange={(e) => inputhandler(e)}
                className="consignment-input-field"
              >
                <option value={""} disabled>
                  Select any option
                </option>
                {manageAccessList.map((value, index) => (
                  <option key={index} value={value._id}>
                    {value.sName}
                  </option>
                ))}
              </select>
                        </Col>

                        <Col sm={12} md={6} className='form-fields op mb-4'>
                            <p>Tag</p>
                            <input
                                name='sTag'
                                onChange={(e) => inputhandler(e)}
                                placeholder='Enter Tag'
                                className='vehical-input-field'
                                type='text'
                                value={details.sTag}
                            />
                        </Col>
                    </Row>
                    
                   
                </Container>
                <div className='card-footer text-center mb-3'>
                    <button
                        className='btn ripple btn-primary btnDataSave'
                        name='btnDataSave'
                        disabled={isDataNotFilled() || isSubmitting}
                        onClick={handleSubmit}
                    >
                        {' '}
                        {groupId ? 'Update Group' : 'Add  Group'}{' '}
                    </button>
                </div>
            </div>
        </>
    );
};
