import { useEffect, useState } from "react";
import Map from "../../components/gMap/Map";
import { useNavigate } from "react-router-dom";
import "./liveTracking.css";
import { getUserLatestLocation } from "../../api/tracking-external/userLatestLatLong";

const Tracking = () => {
  const navigate = useNavigate();
  const [currentTrackableObj, setCurrentTrackableObj] = useState<any>([]);
  const [trackingDetails, setTrackingDetails] = useState<any>([]);
  const [driverData, setDriverData] = useState<any>([]);
  const [VahicalInfo, setVahicalInfo] = useState<any>({});

  // const tracking = useSelector((state: RootState) => state.tracking);
  useEffect(() => {
    let query = window.location.search;
    if (!query) navigate("/dashboard");
    else getCurrentLatLng();
    // setTrackingDetails(tracking.data);
  }, []);
  const getCurrentLatLng = async () => {
    let regNo = window.location.search;
    let userLocations = await getUserLatestLocation(
      regNo.replace(/_/g, " ").replace(/^\?/, "")
    );
    setTrackingDetails(userLocations);
  };
  const getDriverData = (data: any) => {
    setDriverData(data);
  };

  return (
    <div style={{ display: "flex" }}>
      <Map setVahicalInfo={setVahicalInfo} bringDriverData={getDriverData} />
      <div className="main-container-track">
        <div className=" inside-container">
          <h5 className="tracking-text">Tracking Details</h5>
          {driverData ? (
            <>
              <p>
                Vehicle Reg. No:{" "}
                <span className="track-data">{VahicalInfo.sRegNo}</span>
              </p>
              {VahicalInfo.sTemperature ? (
                <p>
                  Temperature :{" "}
                  <span className="track-data">
                    {VahicalInfo.sTemperature}°C
                  </span>
                </p>
              ) : null}

              {VahicalInfo.sHalt ? (
                <p>
                  Halted Since:{" "}
                  <span className="track-data">{VahicalInfo.sHalt}</span>
                </p>
              ) : null}
              {VahicalInfo.sLocation ? (
                <p>
                  Location:{" "}
                  <span className="track-data">{VahicalInfo.sLocation}</span>
                </p>
              ) : null}

              <p>
                Driver Name:{" "}
                <span className="track-data">{driverData?.tAssignDriver?.sName}</span>
              </p>
              <p>
                Driver Contact :{" "}
                <span className="track-data">
                  {driverData?.tAssignDriver?.aPhoneNo}
                </span>
              </p>

              <p>
                Driver Address :{" "}
                <span className="track-data">
                  {driverData?.tAssignDriver?.sPresentAddress}
                </span>
              </p>
            </>
          ) : (
            "Fetching data.."
          )}
        </div>
      </div>
    </div>
  );
};

export default Tracking;
