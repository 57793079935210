import { IVehicle } from "logistic-packages";
import { api, baseUrl } from "../../api";

export const getAllVehicleApi = async () => {
  const result = await api.get(`${baseUrl}/admin/driver/vehicle`);
  return result.data;
};
export const getAllUnAssignedVehicleApi = async () => {
  const result = await api.get(
    `${baseUrl}/admin/driver/vehicle?assignable=true`
  );
  return result.data;
};
export const addVehicleApi = async (reqBody: IVehicle) => {
  const result = await api.post(`${baseUrl}/admin/driver/vehicle`, reqBody);
  return result.data;
};
export const updateVehicleApi = async (id: string, vehicleData: any) => {
  const result = await api.patch(
    `${baseUrl}/admin/driver/vehicle/${id}`,
    vehicleData
  );
  return result.data;
};

export const deleteVehicleApi = async (id: string) => {
  const result = await api.delete(`${baseUrl}/admin/driver/vehicle/${id}`);
  return result.data;
};
export const syncVehicleApi = async () => {
  const result = await api.get(`${baseUrl}/admin/vehicle-api/sync`);
  return result.data;
};
