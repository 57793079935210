import { IDriver } from 'logistic-packages';
import { deleteDriverApi, driverApproveStatusApi, driverStatusApi, getAllDriverApi,addDriver } from '../../api/driver/driverApi';

export const getAllDriver = () => {
    return new Promise((resolve, reject) => {
        getAllDriverApi()
            .then((data) => {
                resolve(data.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
export const createDriver = (reqBody:any) => {
    return new Promise((resolve, reject) => {
        addDriver(reqBody)
            .then((data) => {
                resolve(data.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
export const deleteADriver = (id: string) => {
    return new Promise((resolve, reject) => {
        deleteDriverApi(id)
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const driverStatus = (id: string, isActive: boolean) => {
    return new Promise((resolve, reject) => {
        driverStatusApi(id, isActive)
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
export const driverApproveStatus = (id: string, isApproved: boolean) => {
    return new Promise((resolve, reject) => {
        driverApproveStatusApi(id, isApproved)
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
