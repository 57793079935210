/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import ReactDOM from "react-dom";
import "./InvoiceModal.css";
import { Modal, Button } from "react-bootstrap";
interface InvoiceModalProps {
  title: string;
  orderInfo: { [x: string]: any };
  setInvoiceModal: React.Dispatch<React.SetStateAction<boolean>>;
  width?: string;
}

export const InvoiceModal: React.FC<InvoiceModalProps> = ({
  title,
  orderInfo,
  setInvoiceModal,
  width,
}) => {
  const [infoList, setInfoList] = useState<string[]>([]);
  useEffect(() => {}, [orderInfo]);

  const targetElement = document.querySelector(".myPortalModalDiv");
  if (!targetElement) return null;

  return ReactDOM.createPortal(
    <Modal
      show={true}
      onHide={() => setInvoiceModal(false)}
      size="lg"
      centered
      className="modal"
      contentClassName={width === "60%" ? "modal-w-xl" : "modal-w-sm"}
    >
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {orderInfo && (
          <div>
            <div className="row justify-content-center">
              <div className="col-md-6">
                <label> Customer No.</label> <span>{orderInfo["Bill_to_Customer_No"]}</span>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <label> Billing Address</label>
                <p>{orderInfo["BillAddress"]}</p>
              </div>

              <div className="col-md-6">
                <label> GST No</label>
                <p>{orderInfo["GSTNo"]}</p>
              </div>
            </div>
            <div className="row"></div>
            <div className="row justify-content-center">
              <div className="col-md-5">
                <p>
                  <b> Amount: </b>
                  {orderInfo["Amount"]}
                </p>
                <p>
                  <b> Total Tax: </b>
                  {orderInfo["TotalTax"]}
                </p>
              </div>
              {/* <div className="col-md-2 verticle-line" style={{ marginLeft: '2rem' }}>
                            </div> */}
              <div className="col-md-5">
                <p>
                  <b> Posting Date: </b>
                  {orderInfo["PostingDate"]}
                </p>
                <p>
                  <b> TCS Amount: </b>
                  {orderInfo["TCSAmount"]}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="item-table">
                <table className="invoice-table">
                  <thead>
                    <tr>
                      <th>Item No</th>
                      <th>Quantity</th>
                      <th>Per Unit Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orderInfo["Items"]
                      ? orderInfo["Items"].map((item: any, index: number) => {
                          return (
                            <tr key={index}>
                              <td>{item.No ?? ""}</td>
                              <td>{item.Quantity ?? ""}</td>
                              <td>{item.Unit_Price ?? ""}</td>
                            </tr>
                          );
                        })
                      : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={() => setInvoiceModal(false)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>,
    targetElement
  );
};
