import React, { useEffect, useState } from 'react';
import { DeleteModal } from '../../components/modal/deletemodal/DeleteModal';
import './Consignment.css';

import { Button, Col, Container, Row, Spinner } from 'react-bootstrap';

import { IConsignment } from 'logistic-packages';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DetailsModal } from '../../components/modal/showDetails/DetailsModal';
import { Status } from '../../interface/base.interface';
import {
    deleteConsignment,
    deleteGroupConsignment,
    getAllConsignments,
    getTripHistoryData,
} from '../../services/consignment/consignmentService';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useNavigate } from 'react-router-dom';
import { DataTable } from '../../components/table/Table';
import { MergeConsignmentModal } from '../../components/modal/mergeConsignmentModal/MergeConsignmentModal';
import { UploadPodModal } from '../../components/modal/UploadPodModal/UploadPodModal';
import { TripHistoryModal } from '../../components/modal/tripHistoryModal/TripHistoryModal';
const columnDefinitions = {
    Consignment: [
        { label: 'ID', key: '_id' },
        { label: 'Consignment Group No', key: 'grpConsignmentID' },

        // { label: 'Document No.', key: 'docNo' },
        { label: 'Vehicle Reg. No.', key: 'vRegNo' },
        { label: 'Assigned Drivers', key: 'assignedDriver' },
        // { label: 'Total Item', key: 'tConsignments.length' },
        { label: 'Action', key: 'sStatus' },
    ],
};

export const Consignment: React.FC = () => {
    const [search, setSearch] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [showMergePopup, setShowMergePopup] = useState(false);
    const [tripHistoryPopup, setTripHistoryPopup] = useState(false);
    const [showPodUploadPopup, setShowPodUploadPopup] = useState(false);
    const [pODUploadConsignmentId, setPODUploadConsignmentId] = useState('');
    const [popupType, setPopupType] = useState('');
    const [podImages, setPodImages] = useState([]);
    const [mergeConsignmentId, setMergeConsignmentId] = useState('');
    const [consignmentData, setConsignmentData] = useState<IConsignment[]>([]);
    const [tripHistoryData, setTripHistoryData] = useState<any>([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [recordSize, setRecordSize] = useState(10);
    const [delConsignmentId, setDelConsignmentId] = useState(null);

    let deliveryStatus = 'new';
    const startIndex = currentPage * recordSize;
    const endIndex = startIndex + recordSize;

    const [showModal, setShowModal] = useState<string | false>(false);

    const [consignmentDetails, setConsignmentDetails] = useState<{
        [keys: string]: any;
    }>({});
    const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false);

    useEffect(() => {
        getConsignments();
        if (window.location.search == '?NA') {
            toast.error('Delivery address not found', {
                position: 'top-center',
                theme: 'colored',
            });
        }
    }, []);

    const getTripHistory = async (id: string) => {
        try {
            const response = await getTripHistoryData(id);
            if (response) {
                setTripHistoryData((response as any).data);
                setTripHistoryPopup(true);
            }
        } catch (error: any) {
            toast.error('Something went wrong', {
                position: 'top-center',
                theme: 'colored',
            });
        }
    };

    // Receiving Lists of all consignments
    const getConsignments = async () => {
        try {
            let consignments = (await getAllConsignments()) as IConsignment[];

            if (consignments) {
                consignments = consignments.map((item: any) =>
                    Object({
                        ...item,
                        dDeliveryDate: item.dDeliveryDate && item.dDeliveryDate.split('T')[0],
                        vRegNo: item.tVehicle && item.tVehicle.sRegNo ? item.tVehicle.sRegNo : '',
                        docNo:
                            item.tItems && item.tItems[0] && item.tItems[0].sDocumentNo
                                ? Array.from(new Set(item.tItems.map((doc: any) => doc.sDocumentNo))).join(', ')
                                : '',
                        assignedDriver: item?.tAssignDriver?.sName,
                    })
                );
                consignments = consignments.map((item: any) =>
                    Object({
                        ...item,
                        tWarehouseName: item.tWarehouse && item.tWarehouse.sName,
                    })
                );
                consignments = consignments.map((item: any) =>
                    Object({
                        ...item,
                        tDriverName: item.tDriver && item.tDriver.sName ? item.tDriver.sName : '----',
                    })
                );
                consignments = consignments.map((item: any) =>
                    Object({
                        ...item,
                        sStatus: item.eStatus !== undefined ? Status[item.eStatus] : null,
                    })
                );
                if (deliveryStatus == 'new') {
                    consignments = consignments.map((item: any) =>
                        Object({
                            ...item,
                            tConsignments: item.tConsignments.filter(
                                (obj: any) => obj.eStatus == '0' || obj.eStatus == '1' || obj.eStatus == '3'
                            ),
                        })
                    );
                }
                if (deliveryStatus == 'delivered') {
                    consignments = consignments.map((item: any) =>
                        Object({
                            ...item,
                            tConsignments: item.tConsignments.filter(
                                (obj: any) => obj.eStatus == '2' || obj.eStatus == '4'
                            ),
                        })
                    );
                }

                consignments = consignments.filter((item: any) => item.tConsignments.length > 0);
                setConsignmentData(consignments.reverse());
            }
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    };

    const navigate = useNavigate();
    const trackConsignment = (item: any) => {
        if (item.tVehicle) {
            sessionStorage.setItem(
                'vahicleApi',
                item.tVehicle && item.tVehicle.tVehicleApi ? JSON.stringify(item.tVehicle.tVehicleApi) : ''
            );
        }
        if (item.tAssignDriver) {
            sessionStorage.setItem('assignDriver', JSON.stringify(item.tAssignDriver));
        }
        sessionStorage.setItem('id', item._id);
        navigate(`/tracking?${item?.tVehicle?.sRegNo.replace(/\s/g, '_')}`);
    };
    const handleMergeConsignments = (item: any) => {
        setShowMergePopup(!showMergePopup);
        setMergeConsignmentId(item._id);
    };
    const handleTripHistory = (item: any) => {
        getTripHistory(item.grpConsignmentID);
    };
    const handlePodUpload = (item: any, type: string) => {
        setPodImages(item.sProveOfDeliveryURLs);
        setShowPodUploadPopup(true);

        setPopupType(type);
        setPODUploadConsignmentId(item._id);
    };
    const setShowPopupHandler = (val: boolean) => {
        setShowPodUploadPopup(false);
    };
    const setShowTripHistoryPopupHandler = (val: boolean) => {
        setTripHistoryPopup(false);
    };
    const handleShowDetails = (orderInfo: any) => {
        orderInfo.tItems = orderInfo.tItems.filter((item: any) => item.sItemNo !== '40020485');
        setConsignmentDetails(orderInfo);
        setShowDetailsModal(true);
    };

    const paginateArray = (pageNumber: number) => {
        if (pageNumber >= 0 && pageNumber < totalPages) {
            setCurrentPage(pageNumber);
        }
    };

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value);
        setCurrentPage(0); // Reset to the first page when searching
    };

    const handleRecordSizeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setRecordSize(Number(e.target.value));
        setCurrentPage(0); // Reset to the first page when changing record size
    };

    const filteredItems = consignmentData.filter((item) => {
        const searchTerm = search.trim().toLowerCase(); // Convert search term to lowercase

        // Convert item name to lowercase (or uppercase) before comparison
        const itemName = item.tWarehouse && item.tWarehouse.sName ? item.tWarehouse.sName.toLowerCase() : '';

        return searchTerm === '' ? item : itemName.includes(searchTerm);
    });

    const totalFilteredItems = filteredItems.length;
    const totalPages = Math.ceil(filteredItems.length / recordSize);

    const paginatedItems = filteredItems.slice(currentPage * recordSize, (currentPage + 1) * recordSize);

    useEffect(() => {
        if (currentPage > 0 && paginatedItems.length === 0) {
            setCurrentPage((prevPage) => prevPage - 1);
        }
    }, [paginatedItems, currentPage]);

    const deleteStatus = async (id: string) => {
        try {
            await deleteConsignment(id).then(() => getConsignments());
        } catch (err) {
            toast.error('Cannot delete, please try again !', {
                position: 'top-center',
                theme: 'colored',
            });
        } finally {
            toast.success('Item Deleted Successfully !', {
                position: 'top-center',
                theme: 'colored',
            });
        }
    };
    // const calculateID = (index: number) => {
    //     return index + 1 + currentPage * recordSize;
    // };
    /**
     * Tabs setting Code
     */
    const [key, setKey] = useState('new');
    const handleTabClick = (status: string) => {
        deliveryStatus = status;
        setKey(status);
        getConsignments();
    };

    async function deleteGroup(items: any) {
        setDelConsignmentId(items.grpConsignmentID);
        try {
            await deleteGroupConsignment(items.grpConsignmentID).then(() => getConsignments());
        } catch (err) {
            toast.error('Cannot delete, please try again !', {
                position: 'top-center',
                theme: 'colored',
            });
        } finally {
            toast.success('Item Deleted Successfully !', {
                position: 'top-center',
                theme: 'colored',
            });
            setDelConsignmentId(null);
        }
    }
    return (
        <>
            {showModal && <DeleteModal id={showModal} setShowModal={setShowModal} action={deleteStatus} />}
            {showMergePopup && (
                <MergeConsignmentModal
                    consignmentID={mergeConsignmentId}
                    pagination={consignmentData.filter((obj) => obj._id !== mergeConsignmentId)}
                    handleShowDetails={handleShowDetails}
                    reFetchConsignmentData={getConsignments}
                />
            )}
            {/* {tripHistoryPopup && ( */}
            <TripHistoryModal
                consignmentID={mergeConsignmentId}
                pagination={tripHistoryData}
                handleShowDetails={handleShowDetails}
                reFetchConsignmentData={getConsignments}
                setShowTripHistoryPopupHandler={setShowTripHistoryPopupHandler}
                showModal={tripHistoryPopup}
                setShowModal={setTripHistoryPopup}
            />
            {/* )} */}
            {/* {showPodUploadPopup && ( */}
            <UploadPodModal
                podImages={podImages}
                title={popupType === 'view' ? 'View Proof of delivery' : 'Upload Proof of delivery'}
                type={popupType === 'view' ? 'view' : 'upload'}
                pODUploadConsignmentId={pODUploadConsignmentId}
                reFetchConsignmentData={getConsignments}
                showModal={showPodUploadPopup}
                showPopupHandler={setShowPopupHandler}
            />
            {/* )} */}

            {showDetailsModal && (
                <DetailsModal
                    title='Consignment Details'
                    orderInfo={consignmentDetails}
                    setShowDetailsModal={setShowDetailsModal}
                    isConsignment={true}
                />
            )}

            <Container fluid style={{ opacity: showModal ? '0.4' : '1' }}>
                <div className='user-content-header py-4'>
                    <ToastContainer />
                    <Row className='mb-2'>
                        <Col sm='6'>
                            <h1 className='m-0 text-dark'>Consignments</h1>
                        </Col>
                        <Col sm='6'>
                            <ol className='breadcrumb float-sm-right justify-content-end'>
                                <li className='breadcrumb-item'>
                                    <a href='/dashboard'>Home</a>
                                </li>
                                <li className='breadcrumb-item active'>Consignments</li>
                            </ol>
                        </Col>
                    </Row>
                </div>

                <div className='content'>
                    <Tabs
                        id='controlled-tab-example'
                        activeKey={key}
                        onSelect={(k) => handleTabClick(k as any)}
                        className='mb-3'
                    >
                        <Tab eventKey='new' title='Ordered'></Tab>
                        <Tab eventKey='delivered' title='Delivered'></Tab>
                    </Tabs>
                    <Row>
                        <Col lg='12'>
                            <div className='card' bg-color='white'>
                                {/* <div className='card-header bg-white'>
                                    <h3 className='card-title'>Consignments</h3>
                                    <Link to='/consignments/create'>
                                        <Button variant='info' className='user float-right add_role text-white'>
                                            Add Consignments
                                        </Button>
                                    </Link>
                                </div> */}

                                <div className='card-body'>
                                    <div id='DataTables_Table_0_wrapper' className='dataTables_wrapper no-footer'>
                                        <div className='card-head'>
                                            <div className='dataTables_length' id='DataTables_Table_0_length'>
                                                <label>
                                                    Show{' '}
                                                    <select
                                                        name='DataTables_Table_0_length'
                                                        aria-controls='DataTables_Table_0'
                                                        className='table-input'
                                                        value={recordSize}
                                                        onChange={handleRecordSizeChange}
                                                    >
                                                        <option value='10'>10</option>
                                                        <option value='15'>15</option>
                                                        <option value='20'>20</option>
                                                        <option value='100'>100</option>
                                                    </select>{' '}
                                                    entries
                                                </label>
                                            </div>
                                            <div id='DataTables_Table_0_filter' className='dataTables_filter '>
                                                <label
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    Search:
                                                    <input
                                                        type='search'
                                                        className='table-input'
                                                        placeholder='Search by Warehouse...'
                                                        aria-controls='DataTables_Table_0'
                                                        value={search}
                                                        onChange={handleSearch}
                                                        style={{ height: '2rem' }}
                                                    />
                                                </label>
                                            </div>
                                        </div>

                                        <div style={{ overflow: 'auto' }}>
                                            <DataTable
                                                isDelLoading={delConsignmentId}
                                                columnDefinitions={columnDefinitions.Consignment}
                                                paginatedItems={paginatedItems}
                                                handleShowDetails={handleShowDetails}
                                                handleTracking={trackConsignment}
                                                handleMerging={handleMergeConsignments}
                                                handleTripHistory={handleTripHistory}
                                                handlePodUpload={handlePodUpload}
                                                deleteGroupFunction={deleteGroup}
                                                isLoading={isLoading}
                                                tableFor='Consignment'
                                                trackerColumn='sStatus'
                                            />
                                        </div>

                                        {isLoading ? (
                                            <div
                                                className='d-flex justify-content-center align-items-center'
                                                style={{ height: '20vh' }}
                                            >
                                                <Spinner animation='border' variant='primary' />
                                            </div>
                                        ) : null}
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <div
                                                className='dataTables_info'
                                                id='DataTables_Table_0_info '
                                                role='status'
                                                aria-live='polite'
                                            >
                                                {totalFilteredItems > 0
                                                    ? `Showing ${
                                                          endIndex < totalFilteredItems ? endIndex : totalFilteredItems
                                                      } of ${totalFilteredItems} entries`
                                                    : ''}
                                            </div>
                                            <div
                                                className='dataTables_paginate paging_simple_numbers'
                                                id='DataTables_Table_0_paginate'
                                            >
                                                <Button
                                                    className='paginate_button previous me-2'
                                                    aria-controls='DataTables_Table_0'
                                                    data-dt-idx={0}
                                                    tabIndex={-1}
                                                    id='DataTables_Table_0_previous'
                                                    onClick={() => {
                                                        paginateArray(currentPage - 1);
                                                    }}
                                                    disabled={currentPage === 0}
                                                >
                                                    Previous
                                                </Button>
                                                <span>
                                                    <Button
                                                        className='paginate_button-current'
                                                        aria-controls='DataTables_Table_0'
                                                        data-dt-idx={1}
                                                        tabIndex={0}
                                                    >
                                                        {currentPage + 1}
                                                    </Button>
                                                </span>
                                                <Button
                                                    className='paginate_button next ms-2'
                                                    aria-controls='DataTables_Table_0'
                                                    data-dt-idx='2'
                                                    tabIndex={-1}
                                                    id='DataTables_Table_0_next'
                                                    onClick={() => {
                                                        paginateArray(currentPage + 1);
                                                    }}
                                                    disabled={
                                                        currentPage === totalPages - 1 || totalFilteredItems === 0
                                                    }
                                                >
                                                    Next
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </>
    );
};
