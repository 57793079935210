import { initializeApp } from "firebase/app";
import { getMessaging } from "@firebase/messaging";
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDade9SptYYAwV_8EfyWGgJk_BhaKIx9x4",
  authDomain: "shine-track.firebaseapp.com",
  projectId: "shine-track",
  storageBucket: "shine-track.appspot.com",
  messagingSenderId: "111602303714",
  appId: "1:111602303714:web:5616ae03132d35dd2914bb",
  measurementId: "G-NNNB06MQQT"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);
