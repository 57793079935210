import React, { useEffect, useState } from "react";
import "./Settings.css";
import { Col, Container, Row } from "react-bootstrap";
import { Group } from "./group/Group";
import { Role } from "./role/Role";
import { Account } from "./api/Api";
import { Thresold } from "./thresold/Thresold";
import { useLocation } from "react-router-dom";
import { VehicleApi } from "./vehicle-api/VehicleApi";
import { getAllAvailableVendor } from "../../services/account/availableVendorService";

const Settings: React.FC = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const activeTab = queryParams.get("activeTab");
  const [activeTable, setActiveTable] = useState(activeTab || "api");

  useEffect(() => {
    if (activeTab) {
      setActiveTable(activeTab);
    }
  }, [activeTab]);
  // const [activeTable, setActiveTable] = useState("api");

  const renderTable = () => {
    switch (activeTable) {
      case "group":
        return <Group />;
      case "role":
        return <Role />;
      case "thresold":
        return <Thresold />;
      case "api":
        return <Account />;
      case "vehicle-api":
        return <VehicleApi />;
    }
  };

  return (
    <Container fluid>
      <div className="user-content-header py-4">
        <Row className="mb-2">
          <Col sm="6">
            <h1 className="m-0 text-dark">Settings</h1>
          </Col>

          <Col sm="6">
            <ol className="breadcrumb float-sm-right justify-content-end">
              <li className="breadcrumb-item">
                <a href="/dashboard">Home</a>
              </li>
              <li className="breadcrumb-item active">Settings</li>
            </ol>
          </Col>
          <Col sm="12" className="settings-button d-flex ">
            <div className="my-4">
              <button
                onClick={() => setActiveTable("api")}
                type="button"
                className={`settings-btn ${
                  activeTable === "api" ? "active" : ""
                }`}
              >
                ERP Api
              </button>
              <button
                onClick={() => setActiveTable("group")}
                type="button"
                className={`settings-btn ms-2 ${
                  activeTable === "group" ? "active" : ""
                }`}
              >
                Group
              </button>
              <button
                onClick={() => setActiveTable("role")}
                type="button"
                className={`settings-btn ms-2 ${
                  activeTable === "role" ? "active" : ""
                }`}
              >
                Role
              </button>
              <button
                onClick={() => setActiveTable("thresold")}
                type="button"
                className={`settings-btn ms-2 ${
                  activeTable === "thresold" ? "active" : ""
                }`}
              >
                Threshold
              </button>
              <button
                onClick={() => setActiveTable("vehicle-api")}
                type="button"
                className={`settings-btn ms-2 ${
                  activeTable === "vehicle-api" ? "active" : ""
                }`}
              >
                Vehicle Api
              </button>
            </div>
          </Col>
        </Row>
        {renderTable()}
      </div>
    </Container>
  );
};

export default Settings;
