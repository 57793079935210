export interface Base {
  _id?: string;
  created_at?: Date;
  updated_at?: Date;
}

export enum Gender {
  MALE = 0,
  FEMALE = 1,
  OTHERS = 2,
}
export enum Status {
  "ORDERED" = 0,
  "INPROGRESS" = 1,
  "DELIVERED" = 2,
  "OUT OF DELIVERY" = 3,
  "FORCED DELIVERY" = 4,
}
export enum addressType {
  BILLINGADDRESS = 0,
  NONDAIRYDELIVERYADDRESS = 1,
  DRYDELIVERYADDRESS = 2,
}
