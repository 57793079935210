import { IOrderItem } from 'logistic-packages';
import { api, baseUrl } from '../../api';

export const getAllInvoiceItemApi = async (sLocationCode: string, date: string) => {
    const result = await api.get(
        `${baseUrl}/admin/external-api/invoiceDetails?Posting_Date=${date}&Location_Code='${sLocationCode}'`
    );
    return result;
};
export const getAllShipmentTransfersApi = async (sLocationCode: string, date: string) => {
    const result = await api.get(
        `${baseUrl}/admin/external-api/transferShipment?Transfer_from_Code='${sLocationCode}'&Shipment_Date=${date}`
    );
    return result;
};
export const getAllOrderItemApi = async (sLocationCode: string, date: Date) => {
    const result = await api.get(
        `${baseUrl}/admin/warehouse/order-item?sLocationCode=${sLocationCode}&dPostingDate=${date}`
    );
    return result;
};

export const addOrderApi = async (reqbody: IOrderItem) => {
    const result = await api.post(`${baseUrl}/admin/warehouse/order-item`, reqbody);
    return result.data;
};

export const deleteOrderItemApi = async (id: string) => {
    const result = await api.delete(`${baseUrl}/admin/warehouse/order-item/${id}`);
    return result.data;
};

export const editOrderItemApi = async (id: string, tripdata: any) => {
    const result = await api.patch(`${baseUrl}/admin/warehouse/order-item/${id}`, tripdata);
    return result.data;
};
