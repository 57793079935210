import React from 'react';
import './DeleteModal.css';

interface DeleteModalProps {
    id: string | false;
    setShowModal: React.Dispatch<React.SetStateAction<string | false>>;
    action: (id: string) => void;
}

export const DeleteModal: React.FC<DeleteModalProps> = ({ id, setShowModal, action }) => {
    const closeModal = () => {
        setShowModal('');
    };

    const confirmModal = () => {
        action(id as string);
        setShowModal('');
    };

    return (
        <>
            <div className='modal-content' style={{maxWidth:'25%'}}>
                <h2 className='modal-title'>Delete Confirmation</h2>
                <p className='modal-body'>Are you sure you want to delete this item?</p>
                <div className="d-flex justify-content-evenly">
                <button className='cancelBtn me-5 ms-10' onClick={closeModal}>
                    Cancel
                </button>
                <button className='deleteBtn' onClick={confirmModal}>
                    Delete
                </button>
                </div>
            </div>
        </>
    );
};
