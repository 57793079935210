import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface OrderState {
    location: null | any[];
    warehouse_id: string;
    locCode: string;
    isWarehouseTransfer: boolean;
}

const initialState: OrderState = {
    location: [],
    warehouse_id: '',
    locCode: '',
    isWarehouseTransfer: false,
};

export const orderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        setOrders: (state, action: PayloadAction<any>) => {
            state.location = action.payload;
        },
        removeOrder: (state) => {
            state.location = null;
        },
        setWarehouse: (state, action: PayloadAction<any>) => {
            state.warehouse_id = action.payload;
        },
        removeWarehouse: (state) => {
            state.warehouse_id = '';
        },
        setLocCode: (state, action: PayloadAction<any>) => {
            state.locCode = action.payload;
        },
        setIsWarehouse: (state, action: PayloadAction<any>) => {
            state.isWarehouseTransfer = action.payload;
        },
    },
});

export const { setOrders, removeOrder, setWarehouse, removeWarehouse, setLocCode, setIsWarehouse } = orderSlice.actions;

export default orderSlice.reducer;
