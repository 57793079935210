import React, { useState, useEffect } from "react";
import "./Customer.css";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import io from "socket.io-client";
import { useNavigate } from "react-router-dom";
import { Socket } from "socket.io-client";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DeleteModal } from "../../../components/modal/deletemodal/DeleteModal";
import { DetailsModal } from "../../../components/modal/showDetails/DetailsModal";
import {
  customerApproveStatus,
  customerStatus,
  deleteACustomer,
  getAllCustomers,
  getAllSyncCustomers,
} from "../../../services/customer/customerService";
import { ICustomer } from "logistic-packages";
import { DataTable } from "../../../components/table/Table";
import { socketUrl } from "../../../api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotate } from "@fortawesome/free-solid-svg-icons";

const columnDefinitions = [
  { label: "ID", key: "_id" },
  { label: "Name", key: "sName" },
  { label: "Email", key: "sEmail" },
  { label: "Contact No", key: "aPhoneNo" },
  { label: "Billing Address", key: "tAddress" },
];

export const Customer: React.FC = () => {
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isSync, setIsSync] = useState(false);
  const [btnActiveId, setbtnActiveId] = useState<string | null>(null);
  const [btnApprovedId, setbtnApprovedId] = useState<string | null>(null);
  const [CustomerData, setCustomerData] = useState<ICustomer[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [recordSize, setRecordSize] = useState(10);
  const [socket, setSocket] = useState<Socket | null>(null);
  const navigate = useNavigate();
  // const [syncing, setSyncing] = useState(false);

  const startIndex = currentPage * recordSize;
  const endIndex = startIndex + recordSize;

  const [showModal, setShowModal] = useState<string | false>(false);

  const [customerDetails, setCustomerDetails] = useState<{
    [keys: string]: any;
  }>({});
  const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false);

  const paginateArray = (pageNumber: number) => {
    if (pageNumber >= 0 && pageNumber < totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    setCurrentPage(0); // Reset to the first page when searching
  };

  const handleRecordSizeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setRecordSize(Number(e.target.value));
    setCurrentPage(0); // Reset to the first page when changing record size
  };

  const filteredItems = CustomerData.filter((item) => {
    const searchTerm = search.trim().toLowerCase(); // Convert search term to lowercase

    // Convert item name to lowercase (or uppercase) before comparison
    const itemName = item.sName ? item.sName.toLowerCase() : "";

    return searchTerm === "" ? item : itemName.includes(searchTerm);
  });

  const totalFilteredItems = filteredItems.length;
  const totalPages = Math.ceil(filteredItems.length / recordSize);

  const paginatedItems = filteredItems.slice(
    currentPage * recordSize,
    (currentPage + 1) * recordSize
  );

  useEffect(() => {
    loadData();
    const newSocket = io(socketUrl + "");
    setSocket(newSocket);
    return () => {
      newSocket.disconnect();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on("customer:approved", (message: string) => {
        loadData();
      });
    }
    if (socket) {
      socket.on("customer:active", (message: string) => {
        loadData();
      });
    }
    // eslint-disable-next-line
  }, [socket]);

  useEffect(() => {
    if (currentPage > 0 && paginatedItems.length === 0) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  }, [paginatedItems, currentPage]);

  async function loadData() {
    try {
      let customers = await getAllCustomers();
      if (customers) {
        customers = (customers as ICustomer[]).map((item: any) =>
          Object({
            ...item,
            tAddress:
              // item.tAddress[0].sValue}));
              item?.tAddress &&
              item?.tAddress[0]&&
              item?.tAddress[0]?.sKey == 0
                ? item.tAddress[0].sValue
                : "----",
          })
        );
        setCustomerData(customers as ICustomer[]);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  }

  const updateStatus = async (item: any, bIsActive: boolean) => {
    setbtnActiveId(item._id);
    try {
      await customerStatus(item._id, bIsActive).then(async () => {
        await loadData();
      });
    } catch (err) {
      console.error(err);
    } finally {
      setbtnActiveId(null);
    }
  };

  const approvedStatus = async (element: any, bIsApproved: boolean) => {
    setbtnApprovedId(element._id);
    try {
      await customerApproveStatus(element._id, bIsApproved).then(async () => {
        await loadData();
        // Emit socket event
        socket?.emit("customer:approved", {
          bIsApproved: !bIsApproved,
          aPhoneNo: element.aPhoneNo,
        });
      });
    } catch (err) {
      console.error(err);
    } finally {
      setbtnApprovedId(null);
    }
  };

  const deleteCustomer = async (id: string) => {
    await deleteACustomer(id).then(() => {
      loadData();
      toast.success("Item Deleted Successfully !", {
        position: "top-center",
        theme: "colored",
      });
    });
  };
  const handleSync = async () => {
    setIsSync(true);
    try {
      let customers = await getAllSyncCustomers();
      if (customers) {
        customers = (customers as ICustomer[]).map((item: any) =>
          Object({
            ...item,
            tAddress:
              // item.tAddress[0].sValue}));
              item?.tAddress &&
              item?.tAddress?.length! > 0 &&
              item?.tAddress[0]?.sKey == 0
                ? item.tAddress[0].sValue
                : "----",
          })
        );
        setCustomerData(customers as ICustomer[]);
      }

      setIsSync(false);

      toast.success("Customer Data Synchronized!", {
        position: "top-center",
        theme: "colored",
      });
    } catch (error) {
      setIsSync(false);

      toast.error("Synchronization Failed!", {
        position: "top-center",
        theme: "colored",
      });
    }
  };

  const handleShowDetails = (orderInfo: any) => {
    setCustomerDetails({
      Name: orderInfo.sName,
      Email: orderInfo.sEmail ? orderInfo.sEmail : "----",
      "Contact No": orderInfo.aPhoneNo,
      Address:
        orderInfo.tAddress.length > 0 && orderInfo.tAddress[0].sKey === 0
          ? orderInfo.tAddress[0].sValue
          : "----",
    });
    setShowDetailsModal(true);
  };

  const editCustomer = async (id: string) => {
    navigate(`/users/customers/edit/${id}`);
  };

  return (
    <>
      {showModal && (
        <DeleteModal
          id={showModal}
          setShowModal={setShowModal}
          action={deleteCustomer}
        />
      )}

      {showDetailsModal && (
        <DetailsModal
          title="Customer Details"
          orderInfo={customerDetails}
          setShowDetailsModal={setShowDetailsModal} // Close the modal callback
          isConsignment={false}
        />
      )}

      <Container fluid style={{ opacity: showModal ? "0.4" : 1 }}>
        <div className="user-content-header py-4">
          <ToastContainer />
          <Row className="mb-2">
            <Col sm="6">
              <h1 className="m-0 text-dark">Customers</h1>
            </Col>
            <Col sm="6">
              <ol className="breadcrumb float-sm-right justify-content-end">
                <li className="breadcrumb-item">
                  <a href="/dashboard">Home</a>
                </li>
                <li className="breadcrumb-item active">Customers</li>
              </ol>
            </Col>
          </Row>
        </div>

        <div className="content">
          <Row>
            <Col lg="12">
              <div className="card" bg-color="white">
                <div className="card-body">
                  <div className="card-header bg-white">
                    <h3 className="card-title mt-0">&nbsp;</h3>

                    <Button
                      variant="info"
                      className="user float-right add_role text-white ml-2"
                      onClick={handleSync}
                    >
                      <span className="sync-button">Sync Customer </span>
                      {isSync ? (
                        <Spinner
                          animation="border"
                          size="sm"
                          className="me-1"
                        />
                      ) : (
                        <>
                          <FontAwesomeIcon icon={faRotate} />
                        </>
                      )}
                    </Button>
                  </div>
                  <div
                    id="DataTables_Table_0_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <div className="card-head mt-2">
                      <div
                        className="dataTables_length"
                        id="DataTables_Table_0_length"
                      >
                        <label>
                          Show{" "}
                          <select
                            name="DataTables_Table_0_length"
                            aria-controls="DataTables_Table_0"
                            className="table-input"
                            value={recordSize}
                            onChange={handleRecordSizeChange}
                          >
                            <option value="10">10</option>
                            <option value="15">15</option>
                            <option value="20">20</option>
                            <option value="100">100</option>
                          </select>{" "}
                          entries
                        </label>
                      </div>
                      <div
                        id="DataTables_Table_0_filter"
                        className="dataTables_filter"
                      >
                        <label
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          Search:
                          <input
                            type="search"
                            className="table-input"
                            placeholder="Search by Name..."
                            aria-controls="DataTables_Table_0"
                            value={search}
                            onChange={handleSearch}
                            style={{ height: "2rem" }}
                          />
                        </label>
                      </div>
                    </div>

                    <div className="of-auto">
                      <DataTable
                        columnDefinitions={columnDefinitions}
                        paginatedItems={paginatedItems}
                        approvedStatus={approvedStatus}
                        handleShowDetails={handleShowDetails}
                        btnApprovedId={btnApprovedId}
                        updateStatus={updateStatus}
                        btnActiveId={btnActiveId}
                        editFunction={editCustomer}
                        deleteFunction={deleteCustomer}
                        setShowModal={setShowModal}
                        isLoading={isLoading}
                        tableFor="Customer"
                      />
                      {isLoading ? (
                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{ height: "20vh" }}
                        >
                          <Spinner animation="border" variant="primary" />
                        </div>
                      ) : null}
                      <div className="d-flex justify-content-between align-items-center">
                        <div
                          className="dataTables_info"
                          id="DataTables_Table_0_info "
                          role="status"
                          aria-live="polite"
                        >
                          {totalFilteredItems > 0
                            ? `Showing ${
                                endIndex < totalFilteredItems
                                  ? endIndex
                                  : totalFilteredItems
                              } of ${totalFilteredItems} entries`
                            : ""}
                        </div>
                        <div
                          className="dataTables_paginate paging_simple_numbers"
                          id="DataTables_Table_0_paginate"
                        >
                          <Button
                            className="paginate_button previous me-2"
                            aria-controls="DataTables_Table_0"
                            data-dt-idx={0}
                            tabIndex={-1}
                            id="DataTables_Table_0_previous"
                            onClick={() => {
                              paginateArray(currentPage - 1);
                            }}
                            disabled={currentPage === 0}
                          >
                            Previous
                          </Button>
                          <span>
                            <Button
                              className="paginate_button-current"
                              aria-controls="DataTables_Table_0"
                              data-dt-idx={1}
                              tabIndex={0}
                            >
                              {currentPage + 1}
                            </Button>
                          </span>
                          <Button
                            className="paginate_button next ms-2"
                            aria-controls="DataTables_Table_0"
                            data-dt-idx="2"
                            tabIndex={-1}
                            id="DataTables_Table_0_next"
                            onClick={() => {
                              paginateArray(currentPage + 1);
                            }}
                            disabled={
                              currentPage === totalPages - 1 ||
                              filteredItems.length < 10
                            }
                          >
                            Next
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};
