import { api, baseUrl } from '../../api';

export const addVehicleVendorApi = async (reqBody: any) => {
    const result = await api.post(`${baseUrl}/admin/vehicle-api`, {data:reqBody});
    return result.data;
};

export const getAllVehicleVendorApi = async () => {
    const result = await api.get(`${baseUrl}/admin/vehicle-api`);
    return result.data;
};
export const getVehicleVendorByIdApi = async (id: string) => {
    const result = await api.get(`${baseUrl}/admin/vehicle-api/${id}`);
    return result.data;
};
export const editVehicleVendorApi = async (id: string,update:any) => {
    const result = await api.patch(`${baseUrl}/admin/vehicle-api/${id}`,{data:update});
    return result.data;
};