import { allAvailableVendorApi } from "../../api/account/availableVendorApi";

export const getAllAvailableVendor = (): Promise<any> => {
    return new Promise((resolve, reject) => {
        allAvailableVendorApi()
            .then((data) => {
                resolve(data.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}