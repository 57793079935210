import React, { useEffect, useState } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./AddConsignment.css";
// import { getAllCustomers } from "../../../services/customer/customerService";
import axios from "axios";
import { IDriver, IWareHouse } from "logistic-packages";
import { baseUrl } from "../../../api";
import {
  addConsignment,
  getAllConsignmentsTop100,
} from "../../../services/consignment/consignmentService";
import { getAllDriver } from "../../../services/driver/driverService";
import {
  getAllWarehouses
} from "../../../services/warehouse/warehouseService";
// import { getAllOrderItem } from "../../../services/orderTracker/orderTrackerService";
import { useSelector } from "react-redux";
import Select from "react-select";
import { RootState } from "../../../redux/store";

// interface ConsignmentDetails {
//   sItemNo: string;

//   tDriver: string;

//   tWarehouse: string;

// }
export const AddConsignment: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [details, setDetails] = useState<any>({
    tDriver: "",
    tItems: [],
  });

  const [warehouseList, setWarehouseList] = useState<IWareHouse[]>([]);
  const [assignedDriverList, setAssignedDriverList] = useState<IDriver[]>([]);
  const [orderItemList, setOrderItemList] = useState<any[]>([]);
  const [similarDocOrder, setSimilarDocOrder] = useState<any[]>([]);
  const [selectedOrderItems, setSelectedOrderItems] = useState<string[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const [isFormEdited, setIsFormEdited] = useState(false);
  const { id: consignmentId } = useParams<{ id: string }>();
  const order = useSelector((state: RootState) => state.order);
  const [transferLocation, setTransferLocation] = useState<any>("");
  const [shipmentDate, setShipmentDate] = useState<any>("");
  const [sFreight, setSFreight] = useState<number | null>(null);
  useEffect(() => {
    loadInitData();
    if (consignmentId) {
      fetchConsignmentDetails(consignmentId);
    } else {
      setIsLoading(false);
    }

    // eslint-disable-next-line
  }, [order]);

  async function loadInitData() {
    try {
      await getAllDriver().then((drivers: any) => {
        setAssignedDriverList(drivers as IDriver[]);
      });

      await getAllWarehouses().then((warehouses) =>
        setWarehouseList(warehouses as IWareHouse[])
      );

      getAllConsignmentsTop100().then((consignments: any) => {
        let consignmentsList: any[] = [];
        consignments.forEach((consignment: any) => {
          consignmentsList = [
            ...consignmentsList,
            ...(consignment.tItems ?? []),
          ];
        });
        let allDocIds = [
          ...(new Set(consignmentsList.map((x) => x.sDocumentNo)) as any),
        ];
        if (order && order.location && order.location.length > 0) {
          let consignmentsList: any[] = order.location
            ? order.location.filter((x) => !allDocIds.includes(x.Document_No))
            : [];
          if (consignmentsList.length == 0) {
            toast.error("No unassigned item is found", {
              position: "top-center",
              theme: "colored",
            });
          }
          handleSelectDocument(consignmentsList);
          setOrderItemList(consignmentsList);
        } else {
          order.isWarehouseTransfer
            ? navigate("/transfer-warehouse")
            : navigate("/order");
        }
      });
    } catch (err: any) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  }

  function fetchConsignmentDetails(id: string) {
    axios

      .get(baseUrl + `/admin/warehouse/consignment/${consignmentId}`)
      .then((res) => {
        setDetails(res.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
  }

  const inputhandler = async (e: any) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
    setIsFormEdited(true);
  };
  const handleSelectDocument = (selectedList: any[]) => {
    setSelectedOrderItems(selectedList);
    setShipmentDate(selectedList[0]?.Items[0]?.Shipment_Date);
    let orderCollection: any[] = [];
    selectedList.forEach((element: any) => {
      orderCollection = [...orderCollection, ...(element.Items ?? [])];
    });

    orderCollection = orderCollection.map((item) => {
      return {
        sItemNo: item.No,
        sBilltoName: item.BilltoName,
        sBilltoAddress: item.BilltoAddress,
        sDescription: item.Description,
        sBilltocity: item.Billtocity,
        sCustomerNo: item.Bill_to_Customer_No,
        sGSTNo: item.GSTNo,
        aUnitPrice: item.Unit_Price,
        aTotalTax: item.TotalTax,
        aTCSAmount: item.TCS_Amount,
        aTotalInvAmount: item.TotalInvAmount,
        aContactNo: item.ContactNo,
        sDocumentNo: item.Document_No,
        aLineNo: item.Line_No,
        sLocationCode: order.isWarehouseTransfer
          ? order.locCode
          : item.Location_Code,
        dPostingDate: order.isWarehouseTransfer
          ? selectedList[0]?.Items[0]?.Shipment_Date
          : item.Posting_Date,
        aQuantity: item.Quantity,
        aAmount: item.amt ? item.amt : item.Amount,
        sTransferFromCode: order.isWarehouseTransfer ? order.locCode : "",
        sTransferToCode: order.isWarehouseTransfer ? item.Transfer_to_Code : "",
      };
    });

    setSimilarDocOrder(orderCollection);
  };
  function isDataNotFilled() {
    return !isFormEdited || !(selectedOrderItems.length > 0 && details.tDriver);
  }

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setIsSubmitting(true);

    const consignmentData = {
      data: {
        tDriver: details.tDriver,
        tItems: similarDocOrder,
        tWarehouse: order.warehouse_id,
        eStatus: "0",
        aFreightAmount: sFreight,
      },
    };

    if (consignmentId) {
      axios
        .patch(
          baseUrl + `/admin/warehouse/consignment/${consignmentId}`,
          consignmentData
        )
        .then((res) => {
          navigate("/consignments");
        })
        .catch((err) => {
          console.error(err);
          toast.error(err.response.data.message, {
            position: "top-center",
            theme: "colored",
          });
        });
    } else {
      await addConsignment(consignmentData)
        .then((res) => {
          toast.success("Consignment Added Successfully", {
            position: "top-center",
            theme: "colored",
          });
          setTimeout(() => {
            navigate("/consignments");
          }, 1000);
        })
        .catch((err) => {
          toast.error(err.response.data.message, {
            position: "top-center",
            theme: "colored",
          });
          setIsSubmitting(false);
        });
    }
  };

  // const handleLocationChange = (value: any) => {
  //     setTransferLocation(value);

  //     let orderCollection: any[] = [];
  //     selectedOrderItems.forEach((element: any) => {
  //         orderCollection = [...orderCollection, ...(element.Items ?? [])];
  //     });

  //     orderCollection = orderCollection.map((item) => {
  //         return {
  //             sItemNo: item.No,
  //             sBilltoName: item.BilltoName,
  //             sBilltoAddress: item.BilltoAddress,
  //             sBilltocity: item.Billtocity,
  //             sGSTNo: item.GSTNo,
  //             aUnitPrice: item.Unit_Price,
  //             aTotalTax: item.TotalTax,
  //             aTCSAmount: item.TCS_Amount,
  //             aTotalInvAmount: item.TotalInvAmount,
  //             aContactNo: item.ContactNo,
  //             sDocumentNo: item.Document_No,
  //             aLineNo: item.Line_No,
  //             sLocationCode: order.isWarehouseTransfer ? order.locCode : item.Location_Code,
  //             dPostingDate: order.isWarehouseTransfer ? shipmentDate : item.Posting_Date,
  //             aQuantity: item.Quantity,
  //             aAmount: item.amt,
  //             sTransferFromCode: order.isWarehouseTransfer ? order.locCode : '',
  //             sTransferToCode: order.isWarehouseTransfer ? value : '',
  //         };
  //     });
  //     setSimilarDocOrder(orderCollection);
  // };

  return (
    <>
      {isLoading ? (
        <div className="bg-loader">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : null}
      <div className="mx-2 ">
        <div className="user-content-header py-4">
          <ToastContainer />
          <Row className="mb-2">
            <Col sm="6">
              <h1 className="m-0 text-dark">Consignment</h1>
            </Col>

            <Col sm="6">
              <ol className="breadcrumb float-sm-right justify-content-end">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/consignments">Consignment</Link>
                </li>
                <li className="breadcrumb-item active">
                  {consignmentId ? "Edit" : "Create"}
                </li>
              </ol>
            </Col>
          </Row>
        </div>
        <Container
          fluid
          className="main-container bg-white pb-4"
          style={{ minHeight: "15rem" }}
        >
          <Row className="title-row">
            {consignmentId ? (
              <p>Edit Consignment</p>
            ) : (
              <p>Create Consignment</p>
            )}
          </Row>

          <Row className="form-group justify-content-center">
            <Col sm={6} md={6} className="form-fields op">
              <p>freight(in ₹)</p>
              <input
                type="number"
                className="form-control"
                placeholder="Enter freight(in ₹)"
                value={sFreight !== null ? sFreight : ""}
                onChange={(e) => setSFreight(parseFloat(e.target.value))}
              />
            </Col>
            <Col sm={6} md={6} className="form-fields op">
              <p>Assigned Driver Name</p>
              <Select
                defaultValue={details.tDriver ? details.tDriver : ""}
                onChange={(e) => {
                  setDetails({ tDriver: e.value });
                  setIsFormEdited(true);
                }}
                options={assignedDriverList.map((value, index) =>
                  Object({ value: value._id, label: value.sName })
                )}
              />
            </Col>
          </Row>
        </Container>

        <div className="card-footer text-center mb-3">
          <button
            className="btn ripple btn-primary btnDataSave"
            name="btnDataSave"
            disabled={isDataNotFilled() || isSubmitting}
            onClick={handleSubmit}
          >
            {" "}
            {consignmentId ? "Update Consignment" : "Create Consignment"}{" "}
          </button>
        </div>
      </div>
    </>
  );
};
