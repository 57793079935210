import {
  addEmployeesApi,
  deleteEmployeesApi,
  employeeStatusApi,
  getAllEmployeesApi,
  getAllEmployeesByRoleApi,
  getSetEmployeePasswordApi,
} from "../../api/employee/employeeApi";

export const getAllEmployees = () => {
  return new Promise((resolve, reject) => {
    getAllEmployeesApi()
      .then((data) => {
        resolve(data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const addEmployees = (reqbody: any) => {
  return new Promise((resolve, reject) => {
    addEmployeesApi(reqbody)
      .then((data) => {
        resolve(data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const deleteEmployees = (id: string) => {
  return new Promise((resolve, reject) => {
    deleteEmployeesApi(id)
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const employeeStatus = (id: string, isActive: boolean) => {
  return new Promise((resolve, reject) => {
    employeeStatusApi(id, isActive)
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getAllEmployeesByRole = (id: string) => {
  return new Promise((resolve, reject) => {
    getAllEmployeesByRoleApi(id)
      .then((data) => {
        resolve(data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const getSetEmployeePassword = (id: string,data:string) => {
  return new Promise((resolve, reject) => {
    let newData = {
      data:{
        sNewPassword:data
      }
    }
    getSetEmployeePasswordApi(id,newData)
      .then((data) => {
        resolve(data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
