import { toast } from 'react-toastify';
import {
    deleteOrderItemApi,
    getAllOrderItemApi,
    editOrderItemApi,
    addOrderApi,
    getAllInvoiceItemApi,
    getAllShipmentTransfersApi,
} from '../../api/orderTracker/orderTrackerApi';
import { IOrderItem } from 'logistic-packages';

export const getAllOrderItem = (sLocationCode: string, date: string) => {
    return new Promise<IOrderItem[]>((resolve, reject) => {
        getAllInvoiceItemApi(sLocationCode, date)
            .then((res) => {
                resolve(res.data.data.value);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
export const getAllShipmentTransfers = (sLocationCode: string, date: string) => {
    return new Promise<IOrderItem[]>((resolve, reject) => {
        getAllShipmentTransfersApi(sLocationCode, date)
            .then((res) => {
                resolve(res.data.data.value);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const addOrder = (reqbody: any) => {
    return new Promise((resolve, reject) => {
        addOrderApi(reqbody)
            .then((data) => {
                resolve(data.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const deleteOrderItem = async (id: string) => {
    try {
        await deleteOrderItemApi(id);
        toast.success('Item Deleted Successfully !', {
            position: 'top-center',
            theme: 'colored',
        });
    } catch (error) {
        toast.error('An error occurred while deleting the item.', {
            position: 'top-center',
            theme: 'colored',
        });
    }
};

export const updateOrderItem = async (id: string, tripdata: any) => {
    try {
        await editOrderItemApi(id, tripdata);
        toast.success('Item Update Successfully !', {
            position: 'top-center',
            theme: 'colored',
        });
    } catch (error) {
        toast.error('An error occurred while Update the item.', {
            position: 'top-center',
            theme: 'colored',
        });
    }
};
