export enum Gender {
    MALE = 0,
    FEMALE = 1,
    OTHERS = 2
}
export enum Status {
    ORDERED = 0,
    INPROGRESS = 1,
    PICKEDUP = 2,
    DELIVERED = 3,
}
export enum addressType{
    BILLINGADDRESS=0,
    NONDAIRYDELIVERYADDRESS=1,
    DRYDELIVERYADDRESS=2
}