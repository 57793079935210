import { api, baseUrl } from '../../api';

export const addSettingApi = async (reqbody: any) => {
    const result = await api.post(`${baseUrl}/admin/external-api`, {data:reqbody});
    return result.data;
};

export const getSettingApi = async (reqbody: any) => {
    const result = await api.get(`${baseUrl}/admin/external-api`, {data:reqbody});
    return result.data;
};