/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import "./VehicleApi.css";
import EyeIcon from "@material-ui/icons/RemoveRedEye";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import {
  addSetting,
  getAllSetting,
} from "../../../services/account/accountService";
import { AccountDetails, AuthType } from "../../../interface/AccountInterface";
import SettingModal from "../../../components/modal/settingmodal/SettingModal";
import AddVehicleApi from "../../../components/modal/addVehicleApi/AddVehicleApi";
import {
  editVehicleVendor,
  getAllVehicleVendor,
} from "../../../services/account/vehicleVendorService";
import { getAllAvailableVendor } from "../../../services/account/availableVendorService";

export const VehicleApi: React.FC = (props: any) => {
  const apiUrl = process.env.REACT_APP_API_URL as string;
  const [formData, setFormData] = useState<AccountDetails>({
    _id: "",
    sName: "",
    sUrl: "",
    sAuthType: AuthType.BASIC,
    sUserName: "",
    sPassword: "",
    sTag: "",
  });
  const [vendorList, setVendorList] = useState([]);
  const [settingData, setSettingData] = useState<AccountDetails[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [userData, setUserData] = useState();

  useEffect(() => {}, [userData]);

  useEffect(() => {
    // Fetch API data and populate the form fields
    loadInitData();
  }, []);

  async function loadInitData() {
    getAllVehicleVendor()
      .then((apiData: any) => {
        setSettingData(apiData);
        if (apiData.length > 0) {
          setFormData(apiData[apiData.length - 1]); // Set the latest API data
        }
      })
      .catch((error) => {
        console.error("Error fetching API data:", error);
      });
    const vendors = await getAllAvailableVendor();
    if (vendors) setVendorList(vendors);
  }
  const resetFormData = () => {
    setFormData({
      _id: "",
      sName: "",
      sUrl: "",
      sAuthType: AuthType.BASIC,
      sUserName: "",
      sPassword: "",
      sTag: "",
    });
  };

  const handleSelectChange = (event: React.ChangeEvent<any>) => {
    const { name, value } = event.target;
    if (event.target.name === "sName") {
      const selectedApiData = settingData.find((api) => api.sName === value);
      if (selectedApiData) {
        setFormData(selectedApiData);
      } else {
        setFormData({
          sName: "",
          sUrl: "",
          sAuthType: 0,
          sUserName: "",
          sPassword: "",
          sTag: "",
        });
      }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  function handleSelectTagChange(event: React.ChangeEvent<any>) {
    setFormData((prevData) => ({
      ...prevData,
      sTag:
        (vendorList as any).find(
          (vender: any) => event.target.value == vender.sAPIUrl
        )?.sTag ?? "",
    }));
  }

  const toggleModal = () => {
    setShowModal(!showModal);
    if (showModal) {
      loadInitData();
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevData: AccountDetails) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleAddApi = async (newApiData: AccountDetails) => {
    await addSetting(newApiData)
      .then((res) => {
        toast.success("Api Added successfully!", {
          position: "top-center",
          theme: "colored",
        });
        resetFormData();
        toggleModal();
        setTimeout(() => {}, 1000);
      })
      .catch((err) => {
        console.error(err);
        toast.error(err.response, {
          position: "top-center",
          theme: "colored",
        });
      });
  };

  // Function to handle form submission
  const handleSubmit = async () => {
    editVehicleVendor(formData._id || "", {
      sName: formData.sName,
      sUrl: formData.sUrl,
      sAuthType: formData.sAuthType,
      sUserName: formData.sUserName,
      sPassword: formData.sPassword,
      sTag: formData.sTag,
      sToken: formData.sToken,
    })
      .then((res) => {
        toast.success("Updated Successfully!", {
          position: "top-center",
          theme: "colored",
        });
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          position: "top-center",
          theme: "colored",
        });
      });
  };

  return (
    <>
      <AddVehicleApi show={showModal} onClose={toggleModal} />
      <ToastContainer />
      <div className="content mb-2">
        <Row>
          <Col lg="12">
            <div className="card" bg-color="white">
              <div className="card-header d-flex justify-content-end">
                <Button
                  size="sm"
                  variant="primary"
                  onClick={() => toggleModal()}
                >
                  Add Api
                </Button>
              </div>
              <div className="card-body">
                <div className="form-container">
                  <div className="form-group">
                    <label htmlFor="apiName">Transporter Name:</label>
                    <select
                      id="apiName"
                      name="sName"
                      value={formData.sName}
                      onChange={handleSelectChange}
                      required
                      className="settings-input-field"
                    >
                      {settingData.map((value: AccountDetails, index: any) => (
                        <option key={index} value={value.sName}>
                          {value.sName}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="apiUrl">Vendor Api:</label>
                    <select
                      id="apiUrl"
                      name="sName"
                      value={formData.sUrl}
                      onChange={(e) => {
                        handleSelectChange(e);
                        handleSelectTagChange(e);
                      }}
                      required
                      disabled
                      className="settings-input-field"
                    >
                      <option value=""></option>
                      {vendorList.map((value: any, index: any) => (
                        <option key={index} value={value.sAPIUrl}>
                          {value.sName}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="authType">Authentication Type:</label>

                    <select
                      id="authType"
                      name="sAuthType"
                      disabled
                      value={formData.sAuthType}
                      onChange={handleSelectChange}
                      required
                      className="settings-input-field"
                    >
                      <option value="0">BASIC</option>
                      <option value="1">NTLM</option>
                      <option value="2">NO</option>
                      <option value="3">Bearer</option>
                    </select>
                  </div>
                  { 
                  formData.sAuthType!=AuthType.BEARER
                  ?
                  <>
                  <div className="form-group">
                      <label htmlFor="username">Username:</label>
                      <input
                        type="text"
                        id="username"
                        name="sUserName"
                        value={formData.sUserName}
                        onChange={handleSelectChange}
                        placeholder="Enter Username"
                        required
                        className="setting-input-field"
                      />
                    </div>
                    <div className="form-group ">
                      <label htmlFor="password">Password:</label>
                      <div className="eye-password">
                        <input
                          type={passwordVisibility ? "text" : "password"}
                          id="password"
                          name="sPassword"
                          value={formData.sPassword}
                          onChange={handleSelectChange}
                          placeholder="Enter Password"
                          required
                          className="setting-input-field"
                        />
                        <i
                          className="input-icon eye-position"
                          onClick={() => {
                            setPasswordVisibility(!passwordVisibility);
                          }}
                        >
                          {" "}
                          {passwordVisibility ? <VisibilityOff /> : <EyeIcon />}
                        </i>
                      </div>
                    </div>
                    </>
                    :
                    <div className="form-group ">
                      <label htmlFor="token">Token:</label>
                        <input
                          type="text"
                          id="token"
                          name="sToken"
                          value={formData.sToken}
                          onChange={handleSelectChange}
                          placeholder="Enter Token"
                          required
                          className="setting-input-field"
                        />
                    </div>
                    
              }
                  <div className="form-group text-center">
                    <button
                      type="button"
                      onClick={handleSubmit}
                      className="save"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};
