import { addGroupApi, deleteGroupApi, getAllAccessApi, getAllGroupApi, updateGroupApi } from "../../api/group/groupApi";




export const getAllgroups = () => {
    return new Promise((resolve, reject) => {
        getAllGroupApi()
            .then((data) => {
                resolve(data.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const getAllAccess = () => {
    return new Promise((resolve, reject) => {
        getAllAccessApi()
            .then((data) => {
                resolve(data.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const addGroup = (reqbody: any) => {
    return new Promise((resolve, reject) => {
        addGroupApi(reqbody)
            .then((data) => {
                resolve(data.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const updateGroup = (id: string, groupData: any) => {
    return new Promise((resolve, reject) => {
        updateGroupApi(id, groupData)
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const deleteAGroup = (id: string) => {
    return new Promise((resolve, reject) => {
        deleteGroupApi(id)
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
