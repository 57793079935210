import React, { useEffect, useState } from 'react';
import './Order.css';
import { DeleteModal } from '../../components/modal/deletemodal/DeleteModal';
import { Button, Col, Container, Row, Spinner } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { deleteOrderItem, getAllOrderItem } from '../../services/orderTracker/orderTrackerService';
import { IConsignment, IOrderItem } from 'logistic-packages';
import { InvoiceModal } from '../../components/modal/invoiceModal/InvoiceModal';
import { useDispatch } from 'react-redux';
import { setLocCode, setIsWarehouse, setOrders, setWarehouse } from '../../redux/order/orderSlice';
import { getAllWarehouses } from '../../services/warehouse/warehouseService';
import { DataTable } from '../../components/table/Table';
import { getAllConsignments } from '../../services/consignment/consignmentService';

const columnDefinitions = [
    { label: 'Checkbox', key: '_checkBox' },
    { label: 'Document No', key: 'Document_No' },
    { label: 'Bill Name', key: 'BilltoName' },
    { label: 'Bill City', key: 'Billtocity' },
    { label: 'Total Quantity', key: 'Quantity' },
    { label: 'Amount', key: 'amt' },
];

export const Trip: React.FC = () => {
    const [search, setSearch] = useState('');
    const [searchEnable, setSearchEnable] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [tripData, setTripData] = useState<any[]>([]);
    const [tripDataBackup, setTripDataBackup] = useState<any[]>([]);
    const [tripLocations, setTripLocations] = useState<any[]>([]);
    const [selectedLocation, setSelectedLocation] = useState<any>();
    const [selectedDate, setSelectedDate] = useState<string>('');
    const [currentPage, setCurrentPage] = useState(0);
    const [recordSize, setRecordSize] = useState(10);
    const nevigate = useNavigate();
    const [showModal, setShowModal] = useState<string | false>(false);
    const [selectedDocument, setSelectedDocument] = useState<any[]>([]);
    const [selectedItem, setSelectedItem] = useState<{
        [x: string]: string | [];
    } | null>(null);
    const [invoiceModal, setInvoiceModal] = useState<boolean>(false);
    const dispatch = useDispatch();
    const monthArray = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];

    useEffect(() => {
        loadWarehouseDetails();
        dispatch(setOrders([]));
        // eslint-disable-next-line
    }, []);

    async function loadData(warehouse: string, date: string) {
        try {
            let consignments = (await getAllConsignments()) as IConsignment[];
            let docNOs: any[] = [].concat(...consignments.map((doc:any) => doc.tConsignments)) ;
             docNOs = docNOs.map((doc) => doc.tItems);
            docNOs = Array.from(new Set([].concat(...docNOs).map((docNO: any) => docNO.sDocumentNo)));

            getAllOrderItem(warehouse, date).then((orderItem: any) => {
                if (orderItem) {
                    const filteredOrderItem = orderItem.filter((item: any) => item.No !== '40020485');
                    let orderDocNo = [...(new Set(orderItem.map((order: any) => order.Document_No)) as any)];

                    let orderByDocNo = [];
                    let tempOrder: any = {};
                    for (let index = 0; index < orderDocNo.length; index++) {
                        tempOrder.Items = filteredOrderItem.filter(
                            (order: any) => order.Document_No === orderDocNo[index]
                        );
                        tempOrder.Document_No = orderDocNo[index];
                        tempOrder.amt = tempOrder.Items[0].amt;
                        tempOrder.Posting_Date = tempOrder.Items[0].Posting_Date;
                        tempOrder.Location_Code = tempOrder.Items[0].Location_Code;
                        tempOrder.GSTNo = tempOrder.Items[0].GSTNo;
                        tempOrder.ContactNo = tempOrder.Items[0].ContactNo;
                        tempOrder.Billtocity = tempOrder.Items[0].Billtocity;
                        tempOrder.BilltoName = tempOrder.Items[0].BilltoName;
                        tempOrder.BilltoAddress = tempOrder.Items[0].BilltoAddress;
                        tempOrder.Bill_to_Customer_No = tempOrder.Items[0].Bill_to_Customer_No;
                        tempOrder.TotalTax = tempOrder.Items[0].TotalTax;
                        tempOrder.TotalInvAmount = tempOrder.Items[0].TotalInvAmount;
                        tempOrder.TCS_Amount = tempOrder.Items[0].TCS_Amount;
                        tempOrder.Quantity = tempOrder.Items.reduce(
                            (acc: number, item: any) => acc + (item.Quantity || 0),
                            0
                        );
                        tempOrder.isOrdered = docNOs.includes(orderDocNo[index]);
                        orderByDocNo.push({ ...tempOrder });
                    }
                    setTripData(orderByDocNo);
                    setTripDataBackup(orderByDocNo as IOrderItem[]);
                }
            });
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }

    const paginateArray = (pageNumber: number) => {
        if (pageNumber >= 0 && pageNumber < totalPages) {
            setCurrentPage(pageNumber);
        }
    };

    const startIndex = currentPage * recordSize;
    const endIndex = startIndex + recordSize;

    const handleRecordSizeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setRecordSize(Number(e.target.value));
        setCurrentPage(0); // Reset to the first page when changing record size
    };
    const filteredItems = tripData.filter((item) => {
        const searchTerm = search.trim().toLowerCase(); // Convert search term to lowercase
        // Convert item name to lowercase (or uppercase) before comparison
        const itemName = item.sItemNo ? item.sItemNo.toLowerCase() : '';
        return searchTerm === '' ? item : itemName.includes(searchTerm);
    });
    // dispatch(setOrders(filteredItems));

    const totalPages = Math.ceil(filteredItems.length / recordSize);
    const totalFilteredItems = filteredItems.length;
    const paginatedItems = filteredItems.slice(currentPage * recordSize, (currentPage + 1) * recordSize);
    useEffect(() => {
        if (currentPage > 0 && paginatedItems.length === 0) {
            setCurrentPage((prevPage) => prevPage - 1);
        }
    }, [paginatedItems, currentPage]);

    const handleInvoiceDetails = (orderInfo: { [x: string]: string }) => {
        setSelectedItem({
            Bill_to_Customer_No:orderInfo.Bill_to_Customer_No ? orderInfo.Bill_to_Customer_No : '----',
            BillName: orderInfo.BilltoName ? orderInfo.BilltoName : '----',
            Billcity: orderInfo.Billtocity ? orderInfo.Billtocity : '----',
            BillAddress: orderInfo.BilltoAddress ? orderInfo.BilltoAddress : '----',
            GSTNo: orderInfo.GSTNo ? orderInfo.GSTNo : '----',
            Amount: orderInfo.amt ? orderInfo.amt : '----',
            TotalTax: orderInfo.TotalTax ? orderInfo.TotalTax : '----',
            TCSAmount: orderInfo.TCS_Amount ? orderInfo.TCS_Amount : '0',
            PostingDate: orderInfo.Posting_Date ? orderInfo.Posting_Date : '----',
            Items: orderInfo.Items ? orderInfo.Items : [],
        });
        setInvoiceModal(true);
    };

    const loadWarehouseDetails = async () => {
        setIsLoading(true);
        try {
            getAllWarehouses().then((warehouse: any) => {
                setTripLocations(warehouse);
                if (warehouse) {
                    setSelectedLocation(
                        warehouse.length > 0 && warehouse[0].sLocationCode ? warehouse[0].sLocationCode : ''
                    );
                    if (warehouse[0]) {
                        let date = new Date();
                        let currentDate = `${date.getFullYear()}-${+date.getMonth() + 1}-${date.getDate()}`;
                        loadData(warehouse[0].sLocationCode, currentDate);
                        let result =`${date.getFullYear()}-${(date.getMonth()>9?date.getMonth()+1:'0'+(date.getMonth()+1))}-${(date.getDate()>9?date.getDate():'0'+(date.getDate()))}`
                        setSelectedDate(result);

                        dispatch(setWarehouse(warehouse[0]._id));
                        dispatch(setLocCode(warehouse[0].sLocationCode));
                        dispatch(setIsWarehouse(false));
                    }
                    setIsLoading(false);
                }
            });
        } catch (err) {
            console.error('Error fetching warehouse details:', err);
            setIsLoading(false);
        }
    };

    const onSearch = async () => {
        setSearchEnable(false);
        setIsLoading(true);
        try {
            let loc = tripLocations.find((data) => data.sLocationCode === selectedLocation) || [];

            dispatch(setWarehouse(loc._id));
            dispatch(setLocCode(loc.sLocationCode));
            dispatch(setIsWarehouse(false));

            let date = new Date(selectedDate);
            let currentDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
            await loadData(selectedLocation, currentDate);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };
    const handleShowDate = () => {
        if (selectedDate) {
            let formatedDate = selectedDate.split('-');
            let fmMonth = formatedDate[1];
            if (fmMonth.length < 2) formatedDate[1] = '0' + (+fmMonth ).toString();
            return formatedDate.join('-');
        } else {
            return '0000-00-00';
        }
    };
    const addConsignment = () => {
        dispatch(setOrders(selectedDocument));
    };
    return (
        <>
            {showModal && <DeleteModal id={showModal} setShowModal={setShowModal} action={deleteOrderItem} />}

            {invoiceModal && selectedItem && (
                <InvoiceModal
                    title={'Invoice Details'}
                    orderInfo={selectedItem}
                    setInvoiceModal={setInvoiceModal}
                    width='60%'
                />
            )}

            <Container fluid style={{ opacity: showModal ? '0.4' : '1' }}>
                <div className='user-content-header py-4'>
                    <ToastContainer />
                    <Row className='mb-2'>
                        <Col sm='6'>
                            <h1 className='m-0 text-dark'>Order</h1>
                        </Col>
                        <Col sm='6'>
                            <ol className='breadcrumb float-sm-right justify-content-end'>
                                <li className='breadcrumb-item'>
                                    <a href='/dashboard'>Home</a>
                                </li>
                                <li className='breadcrumb-item active'>Order</li>
                            </ol>
                        </Col>
                    </Row>
                </div>

                <div className='content'>
                    <Row>
                        <Col lg='12'>
                            <div className='card' bg-color='white'>
                                <div className='card-header bg-white'>
                                    <h3 className='card-title'>Order</h3>
                                    <Link to='/consignments/create'>
                                        <Button
                                            variant='info'
                                            disabled={!selectedDocument.length}
                                            className='user float-right add_role text-white'
                                            onClick={addConsignment}
                                        >
                                            Add Consignments ({selectedDocument.length})
                                        </Button>
                                    </Link>
                                </div>

                                <div className='card-body'>
                                    <div id='DataTables_Table_0_wrapper' className='dataTables_wrapper no-footer'>
                                        <div className='card-head'>
                                            <div className='dataTables_length' id='DataTables_Table_0_length'>
                                                <label>
                                                    Show{' '}
                                                    <select
                                                        name='DataTables_Table_0_length'
                                                        aria-controls='DataTables_Table_0'
                                                        className='table-input'
                                                        value={recordSize}
                                                        onChange={handleRecordSizeChange}
                                                    >
                                                        <option value='10'>10</option>
                                                        <option value='15'>15</option>
                                                        <option value='20'>20</option>
                                                        <option value='100'>100</option>
                                                    </select>{' '}
                                                    entries
                                                </label>
                                            </div>
                                            <div id='DataTables_Table_0_filter' className='dataTables_filter'>
                                                <select
                                                    name='eStatus'
                                                    required
                                                    value={selectedLocation}
                                                    onChange={(e) => {
                                                        setSelectedLocation(e.target.value);
                                                        setSearchEnable(true);
                                                    }}
                                                    className='location-filter'
                                                >
                                                    <option value={''} disabled>
                                                        Select any location
                                                    </option>

                                                    {tripLocations.map((trip, index) => (
                                                        <option key={index} value={trip.sLocationCode}>
                                                            {trip.sLocationCode}
                                                        </option>
                                                    ))}
                                                </select>{' '}
                                                <input
                                                    type='date'
                                                    className='location-filter'
                                                    value={handleShowDate()}
                                                    onChange={(e) => {
                                                        setSelectedDate(e.target.value);
                                                        setSearchEnable(true);
                                                    }}
                                                />
                                                <label
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Button
                                                        variant='info'
                                                        className='user float-right add_role text-white'
                                                        disabled={!searchEnable}
                                                        onClick={(e) => {
                                                            onSearch();
                                                        }}
                                                    >
                                                        Search
                                                    </Button>
                                                </label>
                                            </div>
                                        </div>

                                        <div style={{ overflow: 'auto' }}>
                                            <DataTable
                                                columnDefinitions={columnDefinitions}
                                                paginatedItems={paginatedItems}
                                                handleInvoiceDetails={handleInvoiceDetails}
                                                setShowModal={setShowModal}
                                                isLoading={isLoading}
                                                tableFor='Order'
                                                setSelectedDocument={setSelectedDocument}
                                                selectedDocument={selectedDocument}
                                            />
                                        </div>
                                        {isLoading ? (
                                            <div
                                                className='d-flex justify-content-center align-items-center'
                                                style={{ height: '20vh' }}
                                            >
                                                <Spinner animation='border' variant='primary' />
                                            </div>
                                        ) : null}
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <div
                                                className='dataTables_info'
                                                id='DataTables_Table_0_info '
                                                role='status'
                                                aria-live='polite'
                                            >
                                                {totalFilteredItems > 0
                                                    ? `Showing ${startIndex + 1} to ${
                                                          endIndex < totalFilteredItems ? endIndex : totalFilteredItems
                                                      } of ${totalFilteredItems} entries`
                                                    : ''}
                                            </div>
                                            <div
                                                className='dataTables_paginate paging_simple_numbers'
                                                id='DataTables_Table_0_paginate'
                                            >
                                                <Button
                                                    className='paginate_button previous me-2'
                                                    aria-controls='DataTables_Table_0'
                                                    data-dt-idx={0}
                                                    tabIndex={-1}
                                                    id='DataTables_Table_0_previous'
                                                    onClick={() => {
                                                        paginateArray(currentPage - 1);
                                                    }}
                                                    disabled={currentPage === 0}
                                                >
                                                    Previous
                                                </Button>
                                                <span>
                                                    <Button
                                                        className='paginate_button-current'
                                                        aria-controls='DataTables_Table_0'
                                                        data-dt-idx={1}
                                                        tabIndex={0}
                                                    >
                                                        {currentPage + 1}
                                                    </Button>
                                                </span>
                                                <Button
                                                    className='paginate_button next ms-2'
                                                    aria-controls='DataTables_Table_0'
                                                    data-dt-idx='2'
                                                    tabIndex={-1}
                                                    id='DataTables_Table_0_next'
                                                    onClick={() => {
                                                        paginateArray(currentPage + 1);
                                                    }}
                                                    disabled={
                                                        currentPage === totalPages - 1 || filteredItems.length < 10
                                                    }
                                                >
                                                    Next
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </>
    );
};
