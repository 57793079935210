import { IUserRole } from 'logistic-packages';
import { api, baseUrl } from '../../api';

export const getAllRoleApi = async () => {
    const result = await api.get(`${baseUrl}/admin/organization/user-role`);
    return result.data;
};

export const addRoleApi = async (reqbody: IUserRole) =>{
    const result = await api.post(`${baseUrl}/admin/organization/user-role`,reqbody);
    return result.data;
}
export const updateRoleApi = async (id: string, roleData: any) => {
    const result = await api.patch(`${baseUrl}/admin/organization/user-role/${id}`, roleData);
    return result.data;
};

export const deleteRoleApi = async (id: string) => {
    const result = await api.delete(`${baseUrl}/admin/organization/user-role/${id}`);
    return result.data;
};