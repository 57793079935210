import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { DataTable } from '../../table/Table';
import './UploadPodModal.css';
import FileDropZone from './FileUploader';
export const UploadPodModal: React.FC<any> = ({
    title,
    pODUploadConsignmentId,
    type,
    podImages,
    reFetchConsignmentData,
    showModal,
    showPopupHandler = (item: boolean) => {
        return item;
    },
}) => {
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        setIsLoading(showModal);
    }, [showModal]);
    const onClose = () => {
        setIsLoading(false);
        showPopupHandler(isLoading);
    };

    return (
        <Modal show={isLoading} onHide={onClose} contentClassName='modal-w-xxl'>
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body className='modal-body'>
                <div className='table-parent'>
                    {type === 'view' ? (
                        <div className='pod-img-main-cont'>
                            {podImages?.length
                                ? podImages?.map((item: string, index: any) => {
                                      return (
                                          <div className='pod-img-parent'>
                                              <img src={item} className='pod-img' />
                                          </div>
                                      );
                                  })
                                : 'No POD found'}
                        </div>
                    ) : (
                        <FileDropZone
                            reFetchConsignmentData={reFetchConsignmentData}
                            id={pODUploadConsignmentId}
                            hideModal={onClose}
                        />
                    )}
                </div>
            </Modal.Body>
        </Modal>
    );
};
