import { IDriver, IVehicle, IWareHouse } from "logistic-packages";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Modal, Row, Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import io, { Socket } from "socket.io-client";
import { socketUrl } from "../../../api";
import { DeleteModal } from "../../../components/modal/deletemodal/DeleteModal";
import { DetailsModal } from "../../../components/modal/showDetails/DetailsModal";
import { DataTable } from "../../../components/table/Table";
import {
  deleteADriver,
  driverApproveStatus,
  driverStatus,
  getAllDriver,
} from "../../../services/driver/driverService";
import { getAllUnAssignedVehicle } from "../../../services/vehicle/vehicleService";
import { getAllWarehouses } from "../../../services/warehouse/warehouseService";
import "./Driver.css";

const columnDefinitions = [
  { label: "ID", key: "_id" },
  { label: "Name", key: "sName" },
  { label: "Contact No", key: "aPhoneNo" },
  { label: "Billing Address", key: "sPresentAddress" },
];

export const Driver: React.FC = () => {
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isFullScreenLoading, setIsFullScreenLoading] = useState(false);
  const [isLoadingAssign, setIsLoadingAssign] = useState(false);
  const [driverData, setDriverData] = useState<IDriver[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [recordSize, setRecordSize] = useState(10);
  const [socket, setSocket] = useState<Socket | null>(null);
  const [btnApproved, setbtnApproved] = useState<string | null>(null);
  const [btnActive, setbtnActive] = useState<string | null>(null);

  const startIndex = currentPage * recordSize;
  const endIndex = startIndex + recordSize;
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState<string | false>(false);
  const [warehouseList, setWarehouseList] = useState<IWareHouse[]>([]);
  const [unAssignedVehicles, setUnAssignedVehicles] = useState<IVehicle[]>([]);

  const [driverDetails, setDriverDetails] = useState<{ [keys: string]: any }>(
    {}
  );
  const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false);
  const [modalShow, setModalShow] = React.useState<boolean>(false);
  const [driverObj, setDriverObj] = React.useState<any>();

  const [assignedVehicle, setAssignedVehicle] = React.useState<string>("");
  const [assignedWarehouse, setAssignedWarehouse] = React.useState<string>("");

  useEffect(() => {
    loadData();
    // eslint-disable-next-line

    const newSocket = io(socketUrl + "");
    setSocket(newSocket);

    // Clean up the socket connection on component unmount
    return () => {
      newSocket.disconnect();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (socket) {
      // Listen for 'chat message' events from the server
      socket.on("driver:approved", (message: string) => {
        loadData();
      });
    }
    // eslint-disable-next-line
  }, [socket]);

  useEffect(() => {
    if (socket) {
      // Listen for 'chat message' events from the server
      socket.on("driver:active", (message: string) => {
        loadData();
      });
    }
    // eslint-disable-next-line
  }, [socket]);

  async function loadData() {
    try {
      await getAllDriver().then((drivers) =>
        setDriverData(drivers as IDriver[])
      );
      await getAllWarehouses().then((warehouses) =>
        setWarehouseList(warehouses as IWareHouse[])
      );
      await getAllUnAssignedVehicle().then((vehicles) =>
        setUnAssignedVehicles(vehicles as IVehicle[])
      );
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  }

  const updateStatus = async (element: any, bIsActive: boolean) => {
    setbtnActive(element._id);
    try {
      await driverStatus(element._id, bIsActive).then(async () => {
        await loadData();
        socket?.emit("driver:active", {
          bIsActive: !bIsActive,
          aPhoneNo: element.aPhoneNo,
        });
      });
    } catch (err) {
      console.error(err);
    } finally {
      setbtnActive(null);
    }
  };

  const deleteUser = async (id: string) => {
    const driverToDelete = driverData.find((driver) => driver._id === id)
    if (driverToDelete && !driverToDelete.bIsApproved) {
      await deleteADriver(id).then(async () => {
        toast.success("Item Deleted Successfully !", {
          position: "top-center",
          theme: "colored",
        });
        await loadData();
      });
    } else if (driverToDelete && driverToDelete.bIsApproved) {
      toast.error("Cannot delete an approved driver. Not Approved the driver first.", {
        position: "top-center",
        theme: "colored",
      });
    } else {
      toast.error("Driver Not Found", {
        position: "top-center",
        theme: "colored",
      });
    }
  };

  const approvedStatus = async (element: any, bIsApproved: boolean) => {
    setbtnApproved(element._id);
    setDriverObj(element as any);
    try {
      if (!element.bIsApproved) setModalShow(true);
      else {
        await driverApproveStatus(element._id, bIsApproved).then(async () => {
          await loadData();
          socket?.emit("driver:approved", {
            bIsApproved: !bIsApproved,
            aPhoneNo: element.aPhoneNo,
          });
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
      setbtnApproved(null);
    }
  };
  const handleSubmitAssignVehicle = async () => {
    setIsLoadingAssign(true);
    let lCode = warehouseList.find((s) => s._id == assignedWarehouse);
    await driverApproveStatus(driverObj._id, driverObj.bIsApproved).then(
      async () => {
        await loadData();
        socket?.emit("driver:approved", {
          bIsApproved: !driverObj.bIsApproved,
          aPhoneNo: driverObj.aPhoneNo,
          tVehicle: assignedVehicle,
          sLocationCode:
            lCode && lCode.sLocationCode ? lCode.sLocationCode : "",
        });
        setIsLoadingAssign(false);
        setModalShow(false);
        setAssignedVehicle("");
        setAssignedWarehouse("");
      }
    );
  };
  const paginateArray = (pageNumber: number) => {
    if (pageNumber >= 0 && pageNumber < totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    setCurrentPage(0); // Reset to the first page when searching
  };

  const handleRecordSizeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setRecordSize(Number(e.target.value));
    setCurrentPage(0); // Reset to the first page when changing record size
  };

  const handleShowDetails = (orderInfo: any) => {
    setDriverDetails({
      Name: orderInfo.sName,
      // Email: orderInfo.sEmail ? orderInfo.sEmail : '----',
      "Contact No": orderInfo.aPhoneNo,
      Address: orderInfo.sPresentAddress,
    });
    setShowDetailsModal(true);
  };

  const filteredItems = driverData.filter((item) => {
    const searchTerm = search.trim().toLowerCase(); // Convert search term to lowercase

    // Convert item name to lowercase (or uppercase) before comparison
    const itemName = item.sName ? item.sName.toLowerCase() : "";

    return searchTerm === "" ? item : itemName.includes(searchTerm);
  });

  const totalFilteredItems = filteredItems.length;
  const totalPages = Math.ceil(filteredItems.length / recordSize);

  const paginatedItems = filteredItems.slice(
    currentPage * recordSize,
    (currentPage + 1) * recordSize
  );

  useEffect(() => {
    if (currentPage > 0 && paginatedItems.length === 0) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  }, [paginatedItems, currentPage]);

  const editDriver = async (id: string) => {
    navigate(`/users/drivers/edit/${id}`);
    // await loadData();
  };

  const handleAssign = async (e: any, isVehicle: boolean) => {
    // setIsFullScreenLoading(true);
    e.preventDefault();
    if (isVehicle) setAssignedVehicle(e.target.value);
    else {
      setAssignedWarehouse(e.target.value);
      setIsLoading(false);
    }
  };

  return (
    <>
      {isFullScreenLoading ? (
        <div className="bg-loader">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : null}
      {showModal && (
        <DeleteModal
          id={showModal}
          setShowModal={setShowModal}
          action={deleteUser}
        />
      )}

      {showDetailsModal && (
        <DetailsModal
          title="Driver Details"
          orderInfo={driverDetails}
          setShowDetailsModal={setShowDetailsModal} // Close the modal callback
          isConsignment={false}
        />
      )}

      <Container fluid style={{ opacity: showModal ? "0.4" : "1" }}>
        <div className="user-content-header py-4">
          <ToastContainer />
          <Row className="mb-2">
            <Col sm="6">
              <h1 className="m-0 text-dark">Drivers</h1>
            </Col>
            <Col sm="6">
              <ol className="breadcrumb float-sm-right justify-content-end">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item active">Drivers</li>
              </ol>
            </Col>
          </Row>
        </div>

        <div className="content">
          <Row>
            <Col lg="12">
              <div className="card" bg-color="white">
                <div className="card-header bg-white">
                  <h3 className="card-title">&nbsp;</h3>
                  <Link to="/users/drivers/create">
                    <Button
                      variant="info"
                      className="user float-right add_role text-white"
                    >
                      Add Driver
                    </Button>
                  </Link>
                </div>

                <div className="card-body">
                  <div
                    id="DataTables_Table_0_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <div className="card-head">
                      <div
                        className="dataTables_length"
                        id="DataTables_Table_0_length"
                      >
                        <label>
                          Show{" "}
                          <select
                            name="DataTables_Table_0_length"
                            aria-controls="DataTables_Table_0"
                            className="table-input"
                            value={recordSize}
                            onChange={handleRecordSizeChange}
                          >
                            <option value="10">10</option>
                            <option value="15">15</option>
                            <option value="20">20</option>
                            <option value="100">100</option>
                          </select>{" "}
                          entries
                        </label>
                      </div>
                      <div
                        id="DataTables_Table_0_filter"
                        className="dataTables_filter"
                      >
                        <label
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          Search:
                          <input
                            type="search"
                            className="table-input"
                            placeholder="Search by Name..."
                            aria-controls="DataTables_Table_0"
                            value={search}
                            onChange={handleSearch}
                            style={{ height: "2rem" }}
                          />
                        </label>
                      </div>
                    </div>

                    <div style={{ overflow: "auto" }}>
                      <DataTable
                        columnDefinitions={columnDefinitions}
                        paginatedItems={paginatedItems}
                        approvedStatus={approvedStatus}
                        handleShowDetails={handleShowDetails}
                        btnApprovedId={btnApproved}
                        updateStatus={updateStatus}
                        btnActiveId={btnActive}
                        editFunction={editDriver}
                        deleteFunction={deleteUser}
                        setShowModal={setShowModal}
                        isLoading={isLoading}
                        tableFor="Driver"
                        downloadActive={true}
                      />

                      {isLoading ? (
                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{ height: "20vh" }}
                        >
                          <Spinner animation="border" variant="primary" />
                        </div>
                      ) : null}
                      <div className="d-flex justify-content-between align-items-center">
                        <div
                          className="dataTables_info"
                          id="DataTables_Table_0_info "
                          role="status"
                          aria-live="polite"
                        >
                          {totalFilteredItems > 0
                            ? `Showing ${endIndex < totalFilteredItems
                              ? endIndex
                              : totalFilteredItems
                            } of ${totalFilteredItems} entries`
                            : ""}
                        </div>
                        <div
                          className="dataTables_paginate paging_simple_numbers"
                          id="DataTables_Table_0_paginate"
                        >
                          <Button
                            className="paginate_button previous me-2"
                            aria-controls="DataTables_Table_0"
                            data-dt-idx={0}
                            tabIndex={-1}
                            id="DataTables_Table_0_previous"
                            onClick={() => {
                              paginateArray(currentPage - 1);
                            }}
                            disabled={currentPage === 0}
                          >
                            Previous
                          </Button>
                          <span>
                            <Button
                              className="paginate_button-current"
                              aria-controls="DataTables_Table_0"
                              data-dt-idx={1}
                              tabIndex={0}
                            >
                              {currentPage + 1}
                            </Button>
                          </span>
                          <Button
                            className="paginate_button next ms-2"
                            aria-controls="DataTables_Table_0"
                            data-dt-idx="2"
                            tabIndex={-1}
                            id="DataTables_Table_0_next"
                            onClick={() => {
                              paginateArray(currentPage + 1);
                            }}
                            disabled={
                              currentPage === totalPages - 1 ||
                              filteredItems.length < 10
                            }
                          >
                            Next
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        aria-labelledby="contained-modal-title-vleft"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Assign a vehicle to {driverObj?.sName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="assign">
            <div className="assign-item_blocks">
              <p className="assign-label">Choose a warehouse</p>
              <select
                className="assign-vehicle"
                value={assignedWarehouse}
                onChange={(e) => handleAssign(e, false)}
              >
                <option value="10">Select a warehouse</option>
                {warehouseList.map((warehouse, index) => (
                  <option value={warehouse._id} key={index}>
                    {warehouse.sName}
                  </option>
                ))}
              </select>
            </div>
            <div className="assign-item_blocks">
              <p className="assign-label">Choose a vehicle</p>
              <Select
                defaultValue={assignedVehicle}
                isDisabled={
                  assignedVehicle.length == 0 && assignedWarehouse.length == 0
                    ? true
                    : false
                }
                onChange={(e) => {
                  debugger;
                  setAssignedVehicle((e as any).value)
                }}
                options={unAssignedVehicles.map((value, index) =>
                  Object({
                    value: value._id, label: (value.sModel ?? "") +
                      (value.sModel ? " - " : "") +
                      value.sRegNo
                  })
                )}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={handleSubmitAssignVehicle}
            disabled={assignedWarehouse.length ? false : true}
          >
            {isLoadingAssign ? (
              <div className="">
                <Spinner animation="border" variant="secondary" />
              </div>
            ) : (
              "Submit"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
